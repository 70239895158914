import { Component, EventEmitter, Output } from '@angular/core';
import { searchInputs } from './config/searchForm';
searchInputs
@Component({
  selector: 'app-simple-search',
  templateUrl: './simple-search.component.html'
})
export class SimpleSearchComponent {
  @Output() result: EventEmitter<any> = new EventEmitter<any>();
  searchInputs = searchInputs;

  search(event: any) {
    this.result.emit(event['search']);
  }

}
