import { ulid } from 'ulid';
import { NODES_TYPES_ALLOWEDS } from '../config/constants';

const allowedProperties: { [key: string]: string[] } = NODES_TYPES_ALLOWEDS;

const cleanAttributes = (attributes: any) => {
  const cleanAttributes = { ...attributes };
  for (let attr in cleanAttributes) {
    if (
      cleanAttributes[attr] === '' ||
      cleanAttributes[attr] === null ||
      cleanAttributes[attr] === undefined
    ) {
      delete cleanAttributes[attr];
    }
  }
  return cleanAttributes;
};

type EventParameters = {
  name: string;
  [key: string]: any;
};

const verifyAndCleanParameters = (parameters: EventParameters) => {
  // Crear un nuevo objeto conteniendo solo las propiedades permitidas
  const cleanParameters: EventParameters = {} as EventParameters;

  // Obtener las propiedades permitidas para este tipo de evento
  const propertiesForType = allowedProperties[parameters.name];

  if (propertiesForType) {
    for (const property of propertiesForType) {
      if (parameters[property] !== undefined) {
        cleanParameters[property] = parameters[property];
      }
    }
  }

  return cleanParameters;
};

const mapFilterCopies = (type: string): string => {
  const map: { [key: string]: string } = {
    sendCertifiedSMS: 'certified_sms',
    sendCertifiedEmail: 'certified_email',
    sendSMS: 'sms',
    sendEmail: 'email',
    //
    superSendSMS: 'sms',
    superSendEmail: 'email',
    superSendCertifiedSMS: 'certified_sms',
    superSendCertifiedEmail: 'certified_email',
  };

  return map[type];
};

interface Node {
  nodeId: string;
  type: string;
  parameters: any;
  diagram:any;
  childrenIds?: string[];
}
// Almacenar las correspondencias entre las IDs originales y las nuevas IDs.
let idMap: { [id: string]: string } = {};

function extractNodes(cells: any[]): { [id: string]: Node } {
  const nodes: { [id: string]: Node } = {};

  cells.forEach((cell: any) => {
    if (cell.type !== 'standard.Link') {
      let parameters: any = cell.data || {};
      if (!idMap[cell.id]) {
        if (cell.data?.original) {
          idMap[cell.id] = cell.data.original;
        } else {
          idMap[cell.id] = ulid();
        }
      }

      const newNode: Node = {
        nodeId: idMap[cell.id],
        type: cell.nodeType,
        parameters: cleanAttributes(parameters),
        diagram: cell.diagram || null,
        childrenIds: [],
      };

      nodes[cell.id] = newNode;
    }
  });

  return nodes;
}

function extractLinks(cells: any[]): any[] {
  return cells.filter((cell) => cell.type === 'standard.Link');
}

function linkNodes(nodes: { [id: string]: Node }, links: any[]): Node[] {
  const linkedNodes: { [id: string]: Node } = {};

  links.forEach((link: any) => {
    if (!link.source || !link.target) {
      throw new Error('Invalid link data format.');
    }

    const sourceNode = nodes[link.source.id];
    const targetNode = nodes[link.target.id];


    if (sourceNode && targetNode) {
      sourceNode.childrenIds!.push(idMap[link.target.id]);
      linkedNodes[sourceNode.nodeId] = sourceNode;
      linkedNodes[targetNode.nodeId] = targetNode;
    }
  });

  // Encontrar el nodo "end" y moverlo al final del objeto linkedNodes
  const endNode = linkedNodes['end'];
  delete linkedNodes['end'];

  if (endNode) {
    linkedNodes[endNode.nodeId] = endNode;
  }

  return Object.values(linkedNodes);
}

function transformData(data: any): Node[] {
  if (!data || !Array.isArray(data.cells)) {
    throw new Error('Invalid data format.');
  }

  const nodes = extractNodes(data.cells);
  const links = extractLinks(data.cells);

  return linkNodes(nodes, links);
}

const lastDay = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();




export {
  cleanAttributes,
  verifyAndCleanParameters,
  mapFilterCopies,
  transformData,
  lastDay
}