import { IWorkflowValidator } from '@modules/campaigns/Domain/Interfaces/IWorkflowValidator';
import { ValidationError } from '@modules/campaigns/Domain/Enums/ValidationErrors';
import { NodeType } from '@modules/campaigns/Domain/Enums/NodeType';
import { IWorkflow } from '@modules/campaigns/Domain/Interfaces/IWorkflow';
import { WorkflowType } from '@modules/campaigns/Domain/Enums/WorkflowType';

export class MinThreeNodesValidator implements IWorkflowValidator {
  validate(workflow: IWorkflow): string[] {
    const errors: string[] = [];

    if (workflow.nodes.length < 3) {
      errors.push(ValidationError.INVALID_LENGTH_OF_NODES);
    }

    return errors;
  }

  shouldValidate(workflow: IWorkflow): boolean {
    return workflow.type === WorkflowType.DRIP || workflow.type === WorkflowType.PILL;
  }
}
