import { Component, OnInit } from '@angular/core';
import { CustomerModel } from '@core/models/customer.model';
import { FormInputBase } from '@shared/components/dynamic-form/models/form-input-base';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { propostalForm } from './config/sendProposalForm';
import { PaymentAgreementService } from '@modules/payment-agreement/services/payment-agreement.service';
import { CustomerService } from '@modules/customers/customer.service';
import { ToastService } from '@services/ui';
import { CustomerCaseWorkflowI } from '@shared/components/send-propose-payment-agreement/domain/customerCaseWorkflowInstance';

@Component({
  selector: 'app-send-propose-payment-agreement-modal',
  templateUrl: './send-propose-payment-agreement-modal.component.html',
})
export class SendProposePaymentAgreementModalComponent implements OnInit {
  propostalForm: FormInputBase<string | boolean>[] = propostalForm;
  customer: CustomerModel = {} as CustomerModel;
  customerCase: CustomerCaseWorkflowI = {} as CustomerCaseWorkflowI;
  sms: string = '';
  email: string = '';
  nMonths = 1;
  totalAmount = 0;
  installments: any = [];
  methods = [
    { value: 'sms', label: 'SMS' },
    { value: 'email', label: 'Email' },
  ];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private customerService: CustomerService,
    private paymentAgreementService: PaymentAgreementService,
    private toast: ToastService,
  ) {}

  ngOnInit(): void {
    this.initVariables();
    this.initInstalments();
  }

  initVariables() {
    this.customerCase = this.config.data;

    this.customerService.getCustomer(this.customerCase.customerId).then((res: any) => {
      this.customer = res;
      this.initForm();
    });
  }

  initInstalments() {
    this.paymentAgreementService
      .calculatePaymentAgreementInstalments(
        this.customerCase.customerCaseId || this.customerCase.id,
        1,
      )
      .subscribe((response) => {
        if (response.errors) {
          this.toast.showError('Error', response.errors[0].message);
          return;
        }
        this.installments = response;
      });
  }

  initForm() {
    this.propostalForm?.find((i) => i.key === 'channel')?.setOptions(this.setpropostalForms());
  }

  setpropostalForms() {
    const conditions: Record<string, (customer: CustomerModel) => boolean> = {
      sms: (customer) => (customer && customer.phones && customer.phones.length > 0) || false,
      email: (customer) =>
        (customer && customer.emailAddresses && customer.emailAddresses.length > 0) || false,
    };

    return this.methods.filter((method) => conditions[method.value]?.(this.customer));
  }

  onChanges(data: any) {
    this.sms = '';
    this.email = '';

    const { channel, instalments } = data;
    this.nMonths = instalments;

    this.handleChannel(channel);

    if (instalments) {
      this.paymentAgreementService
        .calculatePaymentAgreementInstalments(
          this.customerCase.customerCaseId || this.customerCase.id,
          instalments,
        )
        .subscribe((response) => {
          if (response.errors) {
            this.toast.showError('Error', response.errors[0].message);
            return;
          }
          this.installments = response;
        });
    }
  }

  private handleChannel(channelName: string): void {
    switch (channelName) {
      case 'sms':
        this.setFirstPhoneIfAvailable();
        break;
      case 'email':
        this.setFirstEmailIfAvailable();
        break;
    }
  }

  private setFirstPhoneIfAvailable(): void {
    if (this.customer && Array.isArray(this.customer.phones) && this.customer.phones.length > 0) {
      this.sms = this.customer.phones[0];
    }
  }

  private setFirstEmailIfAvailable(): void {
    if (
      this.customer &&
      Array.isArray(this.customer.emailAddresses) &&
      this.customer.emailAddresses.length > 0
    ) {
      this.email = this.customer.emailAddresses[0];
    }
  }

  checkDisabled() {
    return (!this.sms && !this.email) || !this.nMonths || !this.customerCase;
  }

  send() {
    this.ref.close({
      channel: this.sms ? 'sms' : 'email',
      customerCase: this.customerCase,
      nMonths: this.nMonths,
    });
  }
}
