import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResetService {
  private reset$ = new Subject<void>();

  get resetObservable() {
    return this.reset$.asObservable();
  }

  reset() {
    this.reset$.next();
  }
}
