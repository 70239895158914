<div class="flex flex-wrap gap-1 m-1">
  <p-tag
    *ngFor="let placeholder of placeholders"
    class="custom-hover"
    styleClass="cursor-pointer"
    [style]="{ background: '#f3f3f3' }"
    [severity]="'secondary'"
    [value]="placeholder.label"
    (click)="emit(placeholder.value)"></p-tag>
</div>
