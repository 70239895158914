import { gql } from 'apollo-angular';

export const GET_PAYMENT_AGREEMENTS = gql`
  query ($criteria: SearchInPaymentAgreementListCriteria!) {
    searchInPaymentAgreementList(criteria: $criteria) {
      totalResults
      results {
        id
        collector {
          id
          name
        }
        status
        customer {
          id
          externalId
          longName
        }
        operations {
          id
          externalId
          title
          status
          dueDate
          amount {
            amount
            currency
          }
        }
        startDate
        instalments {
          paid
          total
        }
        amount {
          amount
          currency
        }
        paidAmount {
          amount
          currency
        }
      }
    }
  }
`;

export const EXPORT_PAYMENT_AGREEMENTS_LIST = gql`
  query ($fileId: ID!, $criteria: ExportPaymentAgreementListCriteria!) {
    exportPaymentAgreementList(fileId: $fileId, criteria: $criteria) {
      url
    }
  }
`;

export const VALIDATE_DEPENDENCIES = gql`
  query ($customerCaseId: ID!) {
    validatePaymentAgreementsDependencies(customerCaseId: $customerCaseId)
  }
`;

export const CALCULATE_INSTALMENTS = gql`
  query ($customerCaseId: ID!, $numberOfInstalments: Int!) {
    calculatePaymentAgreementInstalments(
      customerCaseId: $customerCaseId
      numberOfInstalments: $numberOfInstalments
    ) {
      amount {
        amount
        currency
      }
    }
  }
`;
