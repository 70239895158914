import { Injectable } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';
import { PageLoaderService } from './pageLoader.service';

@Injectable()
export class RouteLoaderService {

  constructor(private router: Router, private pageLoaderService: PageLoaderService) { 
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.pageLoaderService.showLoader();
      } else if (event instanceof NavigationEnd || event instanceof NavigationError || event instanceof NavigationCancel) {
        this.pageLoaderService.hideLoader();
      }
    });
  }
}
