import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormInputBase } from '../models/form-input-base';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dynamic-form-input',
  templateUrl: './dynamic-form-input.component.html',
  styleUrls: ['./dynamic-form-input.component.scss'],
})
export class DynamicFormInputComponent implements OnInit {
  @ViewChild('textareaRef') textareaRef!: ElementRef<HTMLTextAreaElement>;
  @ViewChild('chipRef') chipRef?: FormInputBase<any>;
  @Input() field!: FormInputBase<any>;
  @Input() form!: FormGroup;
  @Input() isEnter!: boolean;
  @Input() isSupr!: boolean;
  @Output() emitEnter: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitSupr: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendPlaceholders: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendChip: EventEmitter<any> = new EventEmitter<{
    event: any;
    field: FormInputBase<any>;
  }>();
  defaultDatesInit: Date = new Date(new Date().setHours(0, 0, 0, 0));

  private valueChangesSub: Subscription | undefined;
  chipCount: number = 0;
  safeMaxNumber = Number.MAX_SAFE_INTEGER;
  get control() {
    return this.form.get(this.field.key)!;
  }

  ngOnInit(): void {
    if (this.field.type === 'chipsbox' && this.field.showChipCount === true) {
      this.updateChipCount();
      this.valueChangesSub = this.control.valueChanges.subscribe(() => {
        this.updateChipCount();
      });
    }
  }

  updateChipCount(): void {
    const currentValue = this.control.value;

    if (Array.isArray(currentValue)) {
      this.chipCount = currentValue.length;
    } else if (typeof currentValue === 'string') {
      this.chipCount = currentValue ? currentValue.split(',').length : 0;
    } else {
      this.chipCount = 0;
    }
  }

  ShowErrors() {
    return this.hasFieldError() ? this.control?.errors : 'Error en el campo';
  }

  resetInput() {
    this.control?.reset();
  }

  hasFieldError(): boolean {
    return this.control?.invalid && (this.control?.dirty || this.control?.touched);
  }

  sendValueToParent() {
    this.sendValue.emit(true);
  }

  addPlaceholder(partial: string) {
    const textareaElement: HTMLTextAreaElement = this.textareaRef.nativeElement;
    const cursorPosition = textareaElement.selectionStart;
    this.sendPlaceholders.emit({ partial, field: this.field, cursorPosition });
  }

  editTag(event: any) {
    this.sendChip.emit({ event, field: this.field });
  }

  getFormControlClasses(): string {
    let classes = '';

    if (this.field.disabled) {
      classes += ' p-disabled ';
    }

    if (this.hasFieldError()) {
      classes += ' ng-invalid ng-dirty ';
    }

    return classes;
  }

  updateFormFieldValue(newFieldValue: any) {
    const updatedValues = {
      [this.field.key]: newFieldValue,
    };

    this.form.patchValue(updatedValues);
  }

  sendEnter(event: Event) {
    if (!this.isEnter) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    this.emitEnter.emit(true);
  }

  sendSupr(event: Event) {
    if (!this.isSupr) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    this.emitSupr.emit(true);
  }

  protected readonly Number = Number;
}
