
import { FormTextbox } from '@shared/components/dynamic-form/models/form-textbox';

export const searchInputs = [
  new FormTextbox({
    key: 'search',
    type: 'textbox',
    required: false,
    label: 'BÚSQUEDA',
    value: '',
    placeholder: '',
    readonly: false,
  }),
];
