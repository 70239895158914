import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CollectorModel } from '@admin/modules/collectors/model/collectors.model';
import {
  ADD_COLLECTOR,
  UPDATE_COLLECTOR,
  GET_FEEDBACK_CATEGORIES,
  GET_SERVICES_CATEGORIES,
  GET_COLLECTORS,
} from './collector.GraphQL';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CollectorService {
  collectorsId = [];
  constructor(private apollo: Apollo, private route: ActivatedRoute) {}

  getCollectorlist(): Promise<any> {
    // TODO: change to Promise<Array<CollectorModel>>
    return new Promise<any>((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: GET_COLLECTORS,
          variables: {
            tenantId: this.route.snapshot.queryParamMap.get('tenant'),
          },
        })
        .subscribe((res: any) => {
          if (res.errors?.length) {
            reject(res);
          } else {
            resolve(res.data);
          }
        });
    });
  }

  getCollectorlistByTenant(tenantId: string): Promise<any> {
    // TODO: change to Promise<Array<CollectorModel>>
    return new Promise<any>((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: GET_COLLECTORS,
          variables: {
            tenantId:
              this.route.snapshot.queryParamMap.get('tenant') ?? tenantId,
          },
        })
        .subscribe((res: any) => {
          if (res.errors?.length) {
            reject(res);
          } else {
            resolve(res.data);
          }
        });
    });
  }

  getCollectorlistById(tenantId: string): Promise<any> {
    // TODO: change to Promise<Array<CollectorModel>>
    return new Promise<any>((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: GET_COLLECTORS,
          variables: {
            tenantId,
          },
        })
        .subscribe((res: any) => {
          if (res.errors?.length) {
            reject(res);
          } else {
            resolve(
              res
            );
          }
        });
    });
  }
  getActualTenantCollectors(tenantId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: GET_COLLECTORS,
          variables: {
            tenantId,
          },
        })
        .subscribe((res: any) => {
          if (res.errors?.length) {
            reject(res);
          } else {
            resolve(res );
          }
        });
    });
  }

  addCollector(collector: any): Promise<CollectorModel> {
    return new Promise<any>((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: ADD_COLLECTOR,
          variables: {
            input: collector,
          },
        })
        .subscribe((res: any) => {
          if (res.errors?.length) {
            reject(res);
          } else {
            resolve(res.data);
          }
        });
    });
  }

  modifyCollector(collector: any, id: string): Promise<CollectorModel> {
    return new Promise<any>((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: UPDATE_COLLECTOR,
          variables: {
            id: id,
            input: collector,
          },
        })
        .subscribe((res: any) => {
          if (res.errors?.length) {
            reject(res);
          } else {
            resolve(res.data);
          }
        });
    });
  }
}
