import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StandarDatePaymefyPipe } from '@shared/pipes/standar-paymefy-pipe.pipe';
import { hascopyIds } from '@modules/campaigns/Domain/Factories/HasNodeConfig';
import { NodeClass } from '@modules/campaigns/Domain/NodeClass';
import { GetCopyService } from '@shared/components/joint-js/services/get-copies';
import { forkJoin, map, Observable, of } from 'rxjs';
import { ToastService } from '@services/ui';
import { catchError } from 'rxjs/operators';
import { translateDay } from '@shared/components/joint-js/config/constants';
import { NodeType } from '@modules/campaigns/Domain/Enums/NodeType';
import { TransformDateTimezonePipe } from '@shared/pipes/transformDateTimezone.pipe';

@Injectable({
  providedIn: 'root',
})
export class MonitoringTooltip {
  constructor(
    private standarDate: StandarDatePaymefyPipe,
    private timeZoneDate: TransformDateTimezonePipe,
    private translate: TranslateService,
    private getConfigService: GetCopyService,
    private toast: ToastService,
  ) {}

  createMonitoringTemplate(node: NodeClass, extraParameters: any | undefined): Observable<string> {
    if (hascopyIds(node.config)) {
      const copyObservables = node.config['copyIds'].map((copyId) =>
        this.getConfigService.getCopy(copyId).pipe(
          map((data: any) => {
            if (data && data.errors) {
              this.toast.showError('Error', `${data.errors[0].message}`);
              return null;
            }
            return data.data.copy.name;
          }),
          catchError((error) => {
            throw Error(error.message);
          }),
        ),
      );

      return forkJoin(copyObservables).pipe(
        map((responses: (string | null)[]) => {
          const copies = responses.filter((name) => name !== null);
          return `[${copies.join(', ')}]`;
        }),
      );
    } else if (
      node.type !== NodeType.Conditional &&
      node.type !== NodeType.ConditionalSegments &&
      node.type !== NodeType.OfferPaymentAgreement &&
      node.type !== NodeType.OfferPartialPayment &&
      node.type !== NodeType.MakeOutBoundCall
    ) {
      const result = this.buildTemplateString(
        this.getPartDay(node),
        !this.isStartOrEnd(node.type)
          ? this.translate.instant(
              'DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.PARAMETERS.NAME.' + node.type,
            ) + this.getWaitDays(node)
          : '',
        this.createScheduleString(extraParameters.firstSchedule, extraParameters.lastSchedule),
        this.createCustomerCasesString(extraParameters.customerCasesCount),
      );
      return of(result);
    }

    return of('');
  }

  private buildTemplateString(...parts: string[]): string {
    return parts.filter((part) => part && part.trim() !== '').join('\n');
  }

  private getWaitDays(node: NodeClass): string {
    if (node.config['partDay'] === null || node.type === 'start' || node.type === 'end') {
      return '';
    }
    if (node.config['partDay'] != null && node.type === 'waitDays') {
      return node.config['waitDays'].toString();
    }

    if (node.config['partDay'] != null && node.type === 'waitDayOfWeek') {
      const day = translateDay(node.config['dayOfWeek']);
      return this.translate.instant(day);
    }

    if (node.config['partDay'] != null && node.type === 'waitDayOfMonth') {
      return node.config['dayOfMonth'].toString();
    }

    return '';
  }

  private isStartOrEnd(name: string): boolean {
    return name === 'start' || name === 'end' || name === 'assignCampaign';
  }

  private getPartDay(node: NodeClass): string {
    let partDay = '';

    if (node.config['partDay'] !== null && !this.isStartOrEnd(node.type)) {
      partDay = this.translate.instant(
        'DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.PARAMETERS.PART_DAY.' + node.config['partDay'],
      );

      return `${this.translate.instant(
        'DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.PARAMETERS.PART_DAY.OF',
      )} ${partDay}`;
    }
    return '';
  }

  private createCustomerCasesString(count: number): string {
    return count > 0
      ? ` ${this.translate.instant('DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.ACTIVE_CASES.N_CASES', {
          count: count,
        })}`
      : `${this.translate.instant('DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.ACTIVE_CASES.NO_CASES')}`;
  }

  private createScheduleString(first: Date, last: Date): string {
    if (first === null && last === null) {
      return '';
    }

    if (first === last) {
      return `Programado para el ${this.timeZoneDate.transform(last, true)}`;
    }

    if (first !== null && last !== null) {
      return `Programado entre el ${this.timeZoneDate.transform(
        first,
        true,
      )} y el ${this.timeZoneDate.transform(last, true)}`;
    }

    return '';
  }
}
