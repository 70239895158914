import { gql } from 'apollo-angular';

export const DASHBOARD_URLS_COLLECTOR = gql`
  query (
    $tenantId: ID!
    $collectorId: ID
    $types: [DashboardUrlType]!
    $expiresAt: DateTime!
  ) {
    dashboardUrls(
      tenantId: $tenantId
      collectorId: $collectorId
      types: $types
      expiresAt: $expiresAt
    ) {
      tenantId
      collectorId
      type
      url
      ignoreOperationStatus
      expiresAt
    }
  }
`;

export const DASHBOARD_URLS_NO_COLLECTOR = gql`
  query ($tenantId: ID!, $types: [DashboardUrlType]!, $expiresAt: DateTime!) {
    dashboardUrls(tenantId: $tenantId, types: $types, expiresAt: $expiresAt) {
      tenantId
      type
      url
      expiresAt
    }
  }
`;
export const DASHBOARD_V2 = gql`
  query ($criteria: DashboardUrlsV2Criteria!) {
    dashboardUrlsV2(criteria: $criteria) {
      tenantId
      type
      url
      expiresAt
    }
  }
`;
