import { Pipe, PipeTransform } from '@angular/core';
import { TimezoneService } from '@shared/services/timezone.service';

@Pipe({
  name: 'timezoneDate',
})
export class TransformDateTimezonePipe implements PipeTransform {
  constructor(private timezoneService: TimezoneService) {}

  transform(value: Date | string | null | undefined, includeTime: boolean = false): string | null {
    const format = includeTime ? 'DD/MM/YYYY HH:mm:ss' : 'DD/MM/YYYY';
    return this.timezoneService.convertDateToTimezone(value, format);
  }
}
