import { Component, ViewChild } from '@angular/core';
import { LayoutService } from '../service/app.layout.service';
import { Router } from '@angular/router';
import {
  activityFilters,
  AllowedActivities,
} from '@core/models/customers/customerJourney.model';
import { TranslateService } from '@ngx-translate/core';
import { ActivitiesComponent } from '@shared/components/activities/activities.component';

@Component({
  selector: 'app-activity-sidebar',
  templateUrl: './activity-sidebar.component.html',
})
export class ActivitySidebarComponent {
  @ViewChild('activity') activity!: ActivitiesComponent;
    filterOptions: { label: string; value: AllowedActivities }[] = [];
  filterSelected: AllowedActivities = activityFilters.PAYLINK_NOTIFICATION_SENT;

  constructor(
    public layoutService: LayoutService,
    private router: Router,
    private translate: TranslateService
  ) {
    this.filterOptions = [
      {
        label: this.translate.instant(
          'DATA.MODULES.LAYOUT.ACTIVITY.OPTIONS.PAYLINK_NOTIFICATION_SENT'
        ),
        value: activityFilters.PAYLINK_NOTIFICATION_SENT,
      },
      {
        label: this.translate.instant(
          'DATA.MODULES.LAYOUT.ACTIVITY.OPTIONS.PAYMENT_ORDER_CAPTURED'
        ),
        value: activityFilters.PAYMENT_ORDER_CAPTURED,
      },
      {
        label: this.translate.instant(
          'DATA.MODULES.LAYOUT.ACTIVITY.OPTIONS.FEEDBACK_SENT'
        ),
        value: activityFilters.FEEDBACK_SENT,
      },
    ];
  }

  get visible(): boolean {
    return this.layoutService.state.activitySidebarVisible;
  }

  set visible(_val: boolean) {
    this.layoutService.state.activitySidebarVisible = _val;
  }
  
  navigate(path: string) {
    this.router.navigate([path], {
      preserveFragment: true,
      queryParamsHandling: 'preserve',
    });
    this.layoutService.state.activitySidebarVisible = false;
  }

  onHide()  { 
    this.activity.counter = 1;
    this.activity.filter = activityFilters.PAYLINK_NOTIFICATION_SENT;
    this.filterSelected = activityFilters.PAYLINK_NOTIFICATION_SENT;
    this.activity.searchWithDebounce()
  }
}
