<!-- Errors coming from required validation -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['required']">
   {{ 'Este campo es obligatorio' | translate }}
</div>
<!-- Errors coming from email validation -->
<div class="mt-1 text-red-700" *ngIf="errors && (errors['email'] || errors['invalidEmails'])">
   {{ 'Formato no válido, introduce un email' | translate }}
</div>
<!-- Errors coming from min length validation -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['minlength']">
   {{ 'Formato no válido, necesario mínimo' | translate }}
   {{ errors['minlength'].requiredLength }} {{ 'carácteres' | translate }}
</div>

<!-- Errors coming from min length validation -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['max']">
   {{ 'Formato no válido, catidad máximo excedido' | translate }}
   {{ errors['max'].requiredLength }}
</div>

<!-- Errors coming from min length validation -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['min']">
   {{ 'Formato no válido, cantidad mínima necesaria' | translate }}
   {{ errors['min'].requiredLength }}
</div>

<!-- Errors coming from max length validation -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['maxlength']">
   {{ 'Formato no válido, excedido en' | translate }}
   {{ errors['maxlength'].requiredLength }} {{ 'carácteres' | translate }}
</div>
<!-- Errors coming from pattern validation -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['pattern']">
   {{ 'Formato no válido' | translate }}
</div>
<!-- Errors coming from min validation -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['min']">
   {{ 'Formato no válido, mínimo' | translate }} {{ errors['min'].min }}
</div>

<!-- Custom errors coming from API -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['custom']">
   {{ errors['custom'] | translate }}
</div>

<!-- Error coming from empty values -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['emptyValue']">
   {{ 'Este campo no puede estar vacío' | translate }}
</div>

<!-- Error coming from wring DNI or NIF format -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['invalidDniNif']">
   {{ 'Formato DNI o NIF no válido' | translate }}
</div>

<!-- Error coming from wring DNI or NIF format -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['invalidCp']">
   {{ 'Formato Código Postal no válido' | translate }}
</div>

<!-- Error coming from exceeded max number on integer -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['maxSafeInteger']">
   {{ 'Número máximo aceptado superado' | translate }}
</div>

<!-- Error format Alias -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['invalidAlphaDigit']">
   {{ 'Formato del campo Alias incorrecto' | translate }}
</div>

<!-- Error format IBAN -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['iban']">
   {{ 'Formato del campo IBAN incorrecto' | translate }}
</div>

<!-- Error format BIC -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['bic']">
   {{ 'Formato del campo BIC incorrecto' | translate }}
</div>

<!-- Error chracter length -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['maxChar']">
   {{ 'El campo contiene demasiados carácteres' | translate }}
</div>

<!-- Error format futureDate -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['futureDate']">
   {{ 'La fecha debe ser futura a día de hoy' | translate }}
</div>

<!-- Error amount is higher than total -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['maxImportOnTotalAmount']">
   {{ 'El importe no puede ser mayor que su total' | translate }}
</div>

<!-- Error length array is not enough -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['minElementsInArray']">
   {{ 'Debe haber al menos ' }} {{ errors['minElementsInArray'].amount }} {{ ' elementos seleccionados.' }}
</div>

<!-- Error if don't start with char  -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['startsWithAndEndWith']">
   {{ 'El campo debe empezar y terminar con ' }} {{ errors['startsWithAndEndWith'].char }}
</div>

<!-- Error if range not valid  -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['rangeInvalid']">
   {{ 'El campo final no puede ser mayor al campo inicio  ' }}
</div>

<div class="mt-1 text-red-700" *ngIf="errors && errors['rangeNotCompleted']">
   {{ 'Los campos Inicio y Fin deben estar ambos rellenos o ambos vacíos.' }}
</div>

<!-- Error if every option in dropdown is selected  -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['allOptionsSelected']">
    {{ 'No se pueden seleccionar todas las opciones' }}
</div>
