import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-characters-counter',
  templateUrl: './characters-counter.component.html',
})
export class CharactersCounterComponent implements OnChanges, OnInit {
  @Input() maxChar?: number;
  @Input() hasSegments?: boolean = false;
  @Input() currentChar?: number;
  @Output() errors = new EventEmitter<string>();

  segments: number = 1;

  ngOnInit() {
    if (this.hasSegments && this.currentChar && this.maxChar) {
      this.segments = Math.floor(this.currentChar / this.maxChar) + 1;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['currentChar'].previousValue) {
      this.handleCharacterCount(changes['currentChar'].currentValue);
    }
  }

  private handleCharacterCount(charCount: any) {
    if (this.hasSegments && this.maxChar) {
      this.segments = Math.floor(charCount / this.maxChar) + 1;
    }
  }

  get array(): number[] {
    return Array(this.segments)
      .fill(0)
      .map((x, i) => i);
  }

  get totalPartial(): number {
    return this.currentChar! - this.maxChar! * (this.array.length - 1);
  }
}
