import { Injectable } from '@angular/core';
import { BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PageLoaderService {
  private jobs: number[] = [];
  private loading$ = new BehaviorSubject(false);

  get isLoading() {
    return this.loading$.asObservable();
  }

  showLoader() {
    this.jobs.push(0);
    this.loading$.next(true);
  }

  hideLoader() {
    this.jobs.pop();
    if (this.jobsRatio()) {
      this.loading$.next(false);
    }
  }

  jobsRatio() {
    return this.jobs.length === 0;
  }
}
