import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'standarDate',
})
export class StandarDatePaymefyPipe implements PipeTransform {
  transform(value: Date | string | null | undefined, includeTime?: boolean): string | null {
    const format = includeTime ? 'dd/MM/yyyy HH:mm:ss' : 'dd/MM/yyyy';
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(value, format);
  }
}
