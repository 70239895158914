import { INode } from '@modules/campaigns/Domain/Interfaces/INode';
import { NodeType } from '@modules/campaigns/Domain/Enums/NodeType';
import { NodeConfig } from '@modules/campaigns/Domain/Interfaces/INodeConfig';
import { NodeValidationError } from '@modules/campaigns/Domain/Interfaces/NodeValidationError';
import { NodeModel } from '@shared/components/joint-js/model/node.model';

export abstract class NodeClass implements INode {
  id: string;
  type: NodeType;
  parentNodes: string[] = [];
  childrenNodes: string[] = [];
  config: NodeConfig;
  position: { x: number; y: number };

  constructor(id: string, type: NodeType, position: { x: number; y: number }, config: NodeConfig) {
    this.id = id;
    this.type = type;
    this.position = position;
    this.config = config;
  }

  updatePosition(x: number, y: number): void {
    this.position = { x, y };
  }

  updateNode(node: INode): void {
    this.config = node.config;
    this.type = node.type;
  }

  removeChild(child: NodeClass) {
    this.childrenNodes = this.childrenNodes.filter((id) => id !== child.id);
  }

  removeParent(parent: NodeClass) {
    this.parentNodes = this.parentNodes.filter((id) => id !== parent.id);
  }
  equals(node: INode): boolean {
    return this.id === node.id;
  }

  toNodeModel(): NodeModel {
    return {
      nodeId: this.id,
      type: 'event',
      parameters: {
        ...this.config,
        name: this.type,
      },
      childrenIds: this.childrenNodes,
      diagram: undefined,
    };
  }
  abstract isEmptyConfig(): boolean;
  abstract addParent(node: string): void;
  abstract addChild(node: string): void;
  abstract initNodeConfig(): void;
  abstract validate(): NodeValidationError[];
}
