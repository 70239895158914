import { Injectable } from '@angular/core';
import { FormCascade, FormInputBase } from '@shared/components/dynamic-form/models';

@Injectable({
  providedIn: 'root',
})
export class SetCampaignService {
  constructor() {}

  setCampaignsID(
    filterInputs: (FormInputBase<string | boolean> | FormCascade)[] | undefined,
    filterValues: any,
  ): void {
    if (filterValues.campaigns) {
      const validCampaigns = filterValues.campaigns.filter((campaign: any) => {
        return campaign.status !== 'draft';
      });

      const campaignPhases = validCampaigns.reduce((acc: any, campaign: any) => {
        const phase = campaign.type === 'drip' ? 'Contínua' : 'Píldora';

        if (!acc[phase]) {
          acc[phase] = {
            label: phase,
            children: [],
          };
        }

        const statusGroup = campaign.status === 'published' ? 'Activa' : 'Archivada';

        let statusSubGroup = acc[phase].children.find((child: any) => child.label === statusGroup);
        if (!statusSubGroup) {
          statusSubGroup = {
            label: statusGroup,
            children: [],
          };
          acc[phase].children.push(statusSubGroup);
        }

        statusSubGroup.children.push({
          label: campaign.name,
          value: campaign.id,
          data: campaign.id,
          success: '',
        });

        return acc;
      }, {});

      Object.values(campaignPhases).forEach((phase: any) => {
        phase.children.sort((a: any, b: any) => {
          if (a.label === 'Activa') return -1;
          if (b.label === 'Activa') return 1;
          return 0;
        });
      });

      let campaignOptions: any[];
      if (
        filterValues &&
        filterValues.reportType !== 'COLLECTION_BY_PERIOD' &&
        filterValues.reportType !== 'COLLECTION'
      ) {
        const sinWorkflowOption = {
          label: 'Recordatorio o link directo',
          value: 'no-workflow',
          data: 'no-workflow',
        };
        campaignOptions = [sinWorkflowOption, ...Object.values(campaignPhases)];
      } else {
        campaignOptions = [...Object.values(campaignPhases)];
      }

      if (filterInputs) {
        const campaignInput = filterInputs.find(
          (input) => input.controlType === 'cascade',
        ) as FormCascade;

        if (campaignInput) {
          campaignInput.setOptions(campaignOptions as { label: string; value: string }[]);
          if (filterValues && filterValues.reportType !== 'COLLECTION_BY_PERIOD') {
            campaignInput.value = {
              label: 'Recordatorio o link directo',
              value: 'no-workflow',
              data: 'no-workflow',
            };
          }
        }
      }
    }
  }
}
