import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-next-back',
    templateUrl: './next-back.component.html',
})
export class NextBackComponent {
    @Input() backLabel: string = 'Atrás';
    @Input() nextLabel: string = 'Siguiente';
    @Input() next2Label: string = 'Adiccional';
    @Input() backActive: boolean = false;
    @Input() nextActive: boolean = false;
    @Input() next2Active: boolean = false;
    @Input() backDisabled: boolean = false;
    @Input() nextDisabled: boolean = false;
    @Input() next2Disabled: boolean = false;
    @Output() onNext = new EventEmitter<string>();
    @Output() onNext2 = new EventEmitter<string>();
    @Output() onBack = new EventEmitter<string>();

    nextClicked(): void {
        this.onNext.emit('next');
    }

    nextClicked2(): void {
        this.onNext2.emit('next2');
    }
    backClicked(): void {
        this.onBack.emit('back');
    }
}
