import * as joint from 'jointjs';

export const ConditionalNode = joint.shapes.basic.Generic.extend({
  markup: '<g class="rotatable"><g class="scalable"><rect/></g><text/></g>',
  portMarkup: '<circle class="port-body"/>',
  defaults: joint.util.defaultsDeep(
    {
      type: 'basic.Generic',
      size: { width: 1, height: 1 },
      inPorts: ['top'],
      outPorts: ['bottom', 'right'],

      attrs: {
        '.': { magnet: false },
        rect: {
          stroke: '#ff851b',
          'stroke-width': 5,
          fill: '#F9F9F9',
          width: 100,
          height: 100,
          transform: 'rotate(45)',
        },
        '.port-body': {
          r: 10,
          magnet: true,
          stroke: 'none',
        },
        text: {
          'pointer-events': 'none',
          'ref-x': 0.5,
          'ref-y': 0.5,
          ref: 'rect',
          'y-alignment': 'middle',
          'x-alignment': 'middle',
        },
      },
    },
    joint.shapes.basic.Generic.prototype.defaults,
  ),
});

export const ConditionalSegmentNode = joint.shapes.basic.Generic.extend({
  markup: '<g class="rotatable"><g class="scalable"><rect/></g><text/></g>',
  portMarkup: '<circle class="port-body"/>',
  defaults: joint.util.defaultsDeep(
    {
      type: 'basic.Generic',
      size: { width: 1, height: 1 },
      inPorts: ['top'],
      outPorts: ['bottom', 'right'],

      attrs: {
        '.': { magnet: false },
        rect: {
          stroke: '#A67B5B',
          'stroke-width': 5,
          fill: '#F9F9F9',
          width: 100,
          height: 100,
          transform: 'rotate(45)',
        },
        '.port-body': {
          r: 10,
          magnet: true,
          stroke: 'none',
        },
        text: {
          'pointer-events': 'none',
          'ref-x': 0.5,
          'ref-y': 0.5,
          ref: 'rect',
          'y-alignment': 'middle',
          'x-alignment': 'middle',
        },
      },
    },
    joint.shapes.basic.Generic.prototype.defaults,
  ),
});

export const MultiportsNode = joint.shapes.basic.Generic.extend({
  markup: '<g class="rotatable"><g class="scalable"><rect/></g><text/></g>',
  portMarkup: '<circle class="port-body"/>',
  defaults: joint.util.defaultsDeep(
    {
      type: 'basic.Generic',
      size: { width: 1, height: 1 },
      inPorts: ['top'],
      outPorts: ['bottom', 'right', 'left', 'customOut1', 'customOut2'],

      attrs: {
        '.': { magnet: false },
        rect: {
          stroke: '#42ff00',
          'stroke-width': 5,
          fill: '#F9F9F9',
          width: 150,
          height: 100,
        },
        '.port-body': {
          r: 10,
          magnet: true,
          stroke: 'none',
        },
        text: {
          'pointer-events': 'none',
          'ref-x': 0.5,
          'ref-y': 0.5,
          ref: 'rect',
          'y-alignment': 'middle',
          'x-alignment': 'middle',
        },
      },
    },
    joint.shapes.basic.Generic.prototype.defaults,
  ),
});
