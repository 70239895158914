import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

export function maxImportOnTotalAmount(amount: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (value && value > amount) {
      return { maxImportOnTotalAmount: true };
    }
    return null;
  };
}
