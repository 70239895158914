import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import moment from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class TimezoneService {
  private timezoneSubject = new BehaviorSubject<string>(this.getInitialTimezone());
  selectedTimezone$ = this.timezoneSubject.asObservable();

  private getInitialTimezone(): string {
    const storedTimezone = localStorage.getItem('selectedTimezone');
    if (storedTimezone) {
      return storedTimezone;
    }

    return moment.tz.guess();
  }

  setTimezone(timezone: string) {
    localStorage.setItem('selectedTimezone', timezone);
    this.timezoneSubject.next(timezone);
  }

  public getTimezone(): string {
    return this.timezoneSubject.value;
  }

  convertDateToTimezone(
    date: Date | string | null | undefined,
    format: string = 'DD/MM/YYYY HH:mm:ss',
  ): string {
    if (!date) return '-';

    const momentDate = moment(date);
    if (!momentDate.isValid()) {
      console.error('Invalid date:', date);
      return '-';
    }

    let selectedTimezone = this.getTimezone();
    selectedTimezone = selectedTimezone.replace(/\s*\(GMT[+-]\d+\)$/, '');

    return momentDate.tz(selectedTimezone).format(format);
  }
}
