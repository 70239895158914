import { Injectable, inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CurrentUserService } from '../services/currentUser.service';

@Injectable()
export class TenantGuard  {
  private router = inject(Router);
  private currentUserService = inject(CurrentUserService);

  async canActivate(
    route: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    const tenantParam = route.queryParams?.['tenant'];
    await this.currentUserService.setCurrentUser(true, tenantParam);

    const userTenant = this.currentUserService.currentUser.tenantId;

    if (
      tenantParam !== userTenant &&
      this.currentUserService.currentUser?.permissions[0] !== 'full_access'
    ) {
      const urlTree = this.router.parseUrl(routerState.url);
      urlTree.queryParams['tenant'] = userTenant;
      return urlTree;
    }

    if (!tenantParam) {
      const urlTree = this.router.parseUrl(routerState.url);
      urlTree.queryParams['tenant'] = userTenant;
      return urlTree;
    }

    return true;
  }
}
