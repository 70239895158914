import { gql } from 'apollo-angular';

const typeDefs = gql`
  type SearchInOperationListResult {
    totalResults: Int!
    results: [OperationListRow]!
  }

  type OperationListRow {
    id: ID!
    externalId: ID!
    state: String!
    title: String!
    dueDate: DateTime!
    collector: OperationListCollector!
    customers: [OperationListCustomer]!
    import: Money!
    payments: [OperationListPayment]!
    createdAt: DateTime!
    updatedAt: DateTime!
  }

  extend type Query {
    searchInOperationList: SearchInOperationListResult
  }
`;

const resolvers = {
  Query: {
    searchInOperationList: () => ({
      totalResults: 99,
      results: [
        {
          id: '11asd2dasd11',
          externalId: 'asw22dasd',
          status: 'DONE',
          title: 'maybe',
          expirationDate: '2021-01-01T00:00:00+00:00',
          collector: {
            id: 'asd21dasd1d',
            name: 'MyCollector',
          },
          customers: [
            {
              id: 'asd1d123d1d',
              name: 'Customer 1',
            },
            {
              id: 'csad3d1d',
              name: 'Customer 2',
            },
          ],
          amount: {
            amount: 98.98,
            currency: 'EUR',
          },
          payments: [
            {
              amount: 35.05,
              dateTime: '2021-01-01T00:00:00+00:00',
              paymentMethod: 'Card',
            },
            {
              amount: 45.95,
              dateTime: '2021-01-01T00:00:00+00:00',
              paymentMethod: 'Card',
            },
          ],
          createdAt: '2021-01-01T00:00:00+00:00',
          updatedAt: '2021-01-01T00:00:00+00:00',
        },
        {
          id: '22asd2dasd11',
          externalId: '34sw22dasd',
          status: 'DONE',
          title: 'every',
          expirationDate: '2021-01-01T00:00:00+00:00',
          collector: {
            id: 'asd21dasd1d',
            name: 'MyCollector',
          },
          customers: [
            {
              id: 'asd1d123d1d',
              name: 'Customer 1',
            },
            {
              id: 'csad3d1d',
              name: 'Customer 2',
            },
          ],
          amount: {
            amount: 98.98,
            currency: 'EUR',
          },
          payments: [
            {
              amount: 35.05,
              dateTime: '2021-01-01T00:00:00+00:00',
              paymentMethod: 'Card',
            },
            {
              amount: 45.95,
              dateTime: '2021-01-01T00:00:00+00:00',
              paymentMethod: 'Card',
            },
          ],
          createdAt: '2021-01-01T00:00:00+00:00',
          updatedAt: '2021-01-01T00:00:00+00:00',
        },
        {
          id: '33asd2dasd11',
          externalId: 'b12sw22dasd',
          status: 'DONE',
          title: 'NevEr',
          expirationDate: '2021-01-01T00:00:00+00:00',
          collector: {
            id: 'asd21dasd1d',
            name: 'MyCollector',
          },
          customers: [
            {
              id: 'asd1d123d1d',
              name: 'Customer 1',
            },
            {
              id: 'csad3d1d',
              name: 'Customer 2',
            },
          ],
          amount: {
            amount: 98.98,
            currency: 'EUR',
          },
          payments: [
            {
              amount: 35.05,
              dateTime: '2021-01-01T00:00:00+00:00',
              paymentMethod: 'Card',
            },
            {
              amount: 45.95,
              dateTime: '2021-01-01T00:00:00+00:00',
              paymentMethod: 'Card',
            },
          ],
          createdAt: '2021-01-01T00:00:00+00:00',
          updatedAt: '2021-01-01T00:00:00+00:00',
        },
      ],
    }),
  },
};

export default { typeDefs, resolvers };
