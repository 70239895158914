import { gql } from 'apollo-angular';

const typeDefs = gql`
  extend type Tenant {
    description: String!
  }
`;

const resolvers = {
  Tenant: {
    description: () => 'Tenant mock description',
  },

  Query: {
    tenants: () => [
      {
        id: '01F64NAP1EZH6R0N4C5NDG3VGF',
        name: 'Adevinta',
        language: 'en',
        timezone: 'Europe/Madrid',
      },
    ],
  },
};

export default { typeDefs, resolvers };
