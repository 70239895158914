import { NodeClass } from '@modules/campaigns/Domain/NodeClass';
import {
  getValidationErrorMessage,
  ValidationError,
} from '@modules/campaigns/Domain/Enums/ValidationErrors';
import { NodeType } from '@modules/campaigns/Domain/Enums/NodeType';
import { WaitDaysConfig } from '@modules/campaigns/Domain/Interfaces/INodeConfig';
import { NodeValidationError } from '@modules/campaigns/Domain/Interfaces/NodeValidationError';

export class WaitDaysNode extends NodeClass {
  public maxDays = 60;
  constructor(id: string, position: { x: number; y: number }, config: WaitDaysConfig) {
    super(id, NodeType.WaitDays, position, config);
  }

  isEmptyConfig(): boolean {
    return this.config['partDay'] == null || '' || this.config['waitDays'] == null;
  }

  addChild(node: string): void {
    if (this.childrenNodes.length === 0) this.childrenNodes.push(node);
    else return;
  }

  addParent(node: string): void {
    if (this.parentNodes.length === 0) this.parentNodes.push(node);
    else return;
  }

  initNodeConfig() {
    (this.config as WaitDaysConfig).waitDays = 0;
    (this.config as WaitDaysConfig).partDay = null;
  }

  validate(): NodeValidationError[] {
    const errors: NodeValidationError[] = [];

    if (this.config['partDay'] == null) {
      const errorMessage = getValidationErrorMessage(ValidationError.NO_PART_DAY, {
        nodoType: 'Esperar días',
      });
      errors.push({ nodeId: this.id, errorMessage });
    }

    if (this.config['waitDays'] == null) {
      const errorMessage = getValidationErrorMessage(ValidationError.NO_WAIT_DAYS, {
        nodoType: 'Esperar días',
      });
      errors.push({ nodeId: this.id, errorMessage });
    }

    return errors;
  }
}
