<app-fieldset-p [icon]="'pi pi-wrench'" [header]="'Configuración'" class="d-flex flex-column" style="max-width: 300px">
   <div class="d-flex flex-column" style="max-width: 300px; min-width: 300px">
      <app-dynamic-form
         *ngIf="this.selectedNode"
         #nodeForm
         [formFields]="formType"
         [formCols]="12"
         (formValuesChanged)="updateNodeAttributes($event)"
         [reset]="false"
         [submit]="false"
      ></app-dynamic-form>

      <!-- CONDITIONAL SELECTOR -->
      <div
         *ngIf="this.selectedNode && this.selectedNode?.type === NodeType.Conditional"
         class="flex flex-column justify-content-start p-3 m-neg-top-3"
      >
         <p-toast></p-toast>
         <p class="m-neg-3">NODO OBJETIVO</p>
         <div class="flex flex-row">
            <div *ngIf="nameGoalNode === ''" class="rectangle">Selecciona un nodo objetivo</div>
            <div *ngIf="nameGoalNode !== ''">
               <input
                  pInputText
                  class="custom-input"
                  autocomplete="off"
                  [readonly]="true"
                  [disabled]="true"
                  [value]="nameGoalNode"
               />
            </div>
            <!--<p-speedDial
               class="ml-4"
               showIcon="pi pi-bars"
               [model]="items!"
               direction="down"
               [radius]="80"
            ></p-speedDial>-->
            <p-button [disabled]="false" (onClick)="addGoalNode()" icon="pi pi-plus" styleClass="h-3rem w-3rem ml-4" />
         </div>
      </div>

      <!-- SMS AND EMAILS -->
      <div
         class="m-3 -mt-2 panel-card"
         *ngIf="!loading && this.copiesMessage.length && this.selectedNode && hascopyIds(this.selectedNode?.config!)"
      >
         <ng-container *ngFor="let message of this.copiesMessage; let i = index" class="d-flex flex-column gap-2">
            <p-panel
               *ngIf="message && message.typeChanel === 'EMAIL'"
               class="custom-panel mb-2"
               [header]="truncateText(message.asunto)"
               [toggleable]="true"
               [collapsed]="i !== 0"
            >
               <p>
                  <b>Asunto</b> <br />
                  <i>{{ message.asunto }}</i>
               </p>
               <p-divider></p-divider>
               <p>
                  <b>Pre Header</b> <br />
                  <i>{{ message.preHeader }}</i>
               </p>
               <p-divider></p-divider>
               <p>
                  <b>Mensaje</b> <br />
                  <i>{{ message.text }}</i>
               </p>
               <p-divider></p-divider>
               <p-chip label="{{ message.buttonText }}"></p-chip>
               <ng-template pTemplate="icons">
                  <button
                     [pTooltip]="'Copiar mensaje'"
                     class="p-panel-header-icon p-link mr-2"
                     (click)="copyText(message.text)"
                  >
                     <span class="pi pi-copy"></span>
                  </button>
               </ng-template>
            </p-panel>

            <p-panel
               *ngIf="message && message.typeChanel === 'SMS'"
               class="custom-panel mb-2"
               [header]="truncateText(message.asunto)"
               [toggleable]="true"
               [collapsed]="i !== 0"
            >
               <p>
                  <b>Mensaje</b> <br />
                  <i>{{ message.text }}</i>
               </p>
               <ng-template pTemplate="icons">
                  <button
                     [pTooltip]="'Copiar mensaje'"
                     class="p-panel-header-icon p-link mr-2"
                     (click)="copyText(message.text)"
                  >
                     <span class="pi pi-copy"></span>
                  </button>
               </ng-template>
            </p-panel>
         </ng-container>
      </div>

      <!-- WAIT NODES -->
       <app-message-wait-nodes [selectedNode]="selectedNode"></app-message-wait-nodes>

      <div class="p-3 -mt-6" *ngIf="this.selectedNode?.type === NodeType.OfferPartialPayment">
         <p-messages class="" severity="info" [closable]="true">
            <ng-template pTemplate>
               <div class="p-message flex flex-row gap-2">
                  <span class="pi pi-info-circle p-1"></span>
                  <span>
                     Se enviará una propuesta de pago parcial por valor mínimo de
                     <b> {{ selectedNode?.config?.['minimumAmount'].amount ?? '0' }} EUR </b>. Caducará en
                     <b>{{ selectedNode?.config?.['expirationDays'] ?? 'N' }} días </b>
                     a partir del momento de envío.
                  </span>
               </div>
            </ng-template>
         </p-messages>
      </div>

      <div class="p-3 -mt-6" *ngIf="this.selectedNode?.type === NodeType.OfferPaymentAgreement">
         <p-messages class="" severity="info" [closable]="true">
            <ng-template pTemplate>
               <div class="p-message flex flex-row gap-2">
                  <span class="pi pi-info-circle p-1"></span>
                  <span>
                     Se enviará una propuesta de acuerdo de pago de
                     <b>{{ selectedNode?.config?.['numberOfInstalments'] ?? 'N' }} cuotas</b>. Caducará en
                     <b>
                        {{ selectedNode?.config?.['expirationDays'] ?? 'N' }}
                        {{ selectedNode?.config?.['expirationDays'] === 1 ? 'día' : 'días' }}
                     </b>
                     a partir del momento de envío. Se cargará la primera cuota en el momento del primer pago, las
                     cuotas restantes se cargaran automáticamente cada mes durante
                     <b>
                        {{ selectedNode?.config?.['numberOfInstalments'] ?? 'N' }}
                        {{ selectedNode?.config?.['numberOfInstalments'] === 1 ? 'mes' : 'meses' }}
                     </b>
                     . El cliente podrá ver las fechas exactas de cada cobro antes del primer pago.
                  </span>
               </div>
            </ng-template>
         </p-messages>
      </div>
   </div>
</app-fieldset-p>
