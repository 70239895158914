// custom-tag.component.ts
import { Component, Input } from '@angular/core';
import { STATUS_COLORS_TAG } from '@models/global-entities.model';

@Component({
  selector: 'app-custom-tag',
  templateUrl: './tag.component.html',
})
export class TagComponent {
  @Input() status: string = '';
  @Input() value: string = '';
  @Input() isDot: boolean = true;
  @Input() direction: 'up' | 'down' | null = null;

  get tagStyles() {
    const STATUS_COLORS: { [key: string]: string } = STATUS_COLORS_TAG;
    const severity = STATUS_COLORS[this.status] || 'primary';
    switch (severity) {
      case 'success':
        return {
          backgroundColor: '#F0FDF4',
          color: '#22C55E',
          borderWidth: '1px',
          borderColor: '#22C55E',
          borderStyle: 'solid',
          fontWeight: '600',
        };
      case 'warning':
        return {
          backgroundColor: '#FFF7ED',
          color: '#F97316',
          borderWidth: '1px',
          borderColor: '#F97316',
          borderStyle: 'solid',
          fontWeight: '600',
        };
      case 'danger':
        return {
          backgroundColor: '#FEF2F2',
          color: '#EF4444',
          borderWidth: '1px',
          borderColor: '#EF4444',
          borderStyle: 'solid',
          fontWeight: '600',
        };
      case 'info':
        return {
          backgroundColor: '#EEF2FF',
          color: '#6366F1',
          borderWidth: '1px',
          borderColor: '#6366F1',
          borderStyle: 'solid',
          fontWeight: '600',
        };
      case 'processed':
        return {
          backgroundColor: '#FEFBF3',
          color: '#EAB30A',
          borderWidth: '1px',
          borderColor: '#EAB30A',
          borderStyle: 'solid',
          fontWeight: '600',
        };
      case 'blacked':
        return {
          backgroundColor: '#ffffff',
          color: '#4f4f4f',
          borderWidth: '1px',
          borderColor: '#4f4f4f',
          borderStyle: 'solid',
          fontWeight: '600',
        };
      case 'info_default':
        return {
          backgroundColor: 'rgba(255,255,255,0)',
          color: '#6366F1',
          borderWidth: '1px',
          borderColor: '#6366F1',
          borderStyle: 'solid',
          fontWeight: '600',
          fontStyle: 'italic',
        };
      case 'primary':
      default:
        return {
          backgroundColor: '#EFF6FF',
          color: '#3B82F6',
          borderWidth: '1px',
          borderColor: '#3B82F6',
          borderStyle: 'solid',
          fontWeight: '600',
        };
    }
  }

  get icon() {
    if (this.direction === 'up') {
      return 'pi pi-arrow-up';
    } else if (this.direction === 'down') {
      return 'pi pi-arrow-down';
    } else if (this.isDot) {
      return 'pi pi-circle-fill';
    } else {
      return '';
    }
  }

  get iconStyle() {
    if (this.direction === 'up') {
      return { color: '#22C55E' };
    } else if (this.direction === 'down') {
      return { color: '#EF4444' };
    } else {
      return {};
    }
  }
}
