import { FormDropdown } from '@shared/components/dynamic-form/models';

export const inputTimezone = [
  new FormDropdown({
    key: 'timezone',
    type: 'textbox',
    required: true,
    label: 'ZONA HORARIA',
    value: '',
    options: [],
    multi: false,
    filter: true,
    placeholder: '',
    readonly: false,
  }),
];
