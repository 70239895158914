<nav class="layout-breadcrumb mb-3 flex align-items-center gap-3">
    <ol>
        <ng-template ngFor let-item let-last="last" let-first="first" [ngForOf]="breadcrumbs$ | async">
            <span
                [class]="
                    !last
                        ? 'cursor-pointer transition-colors transition-duration-300 text-gray-500 hover:text-gray-700 align-items-center flex'
                        : 'text-gray-700 align-items-center flex'
                "
            >
                <li style="font-size: 1.5rem" [routerLink]="!last ? item.url : null" [queryParamsHandling]="'preserve'">
                    <i *ngIf="first && getCount() > 1" style="font-size: 1.2rem" class="pi pi-arrow-left"></i>
                    {{ item.label === extra?.coincidence ? extra?.extra : (item.label | translate) }}
                </li>
            </span>
            <li style="font-size: 1.5rem" *ngIf="!last" class="layout-breadcrumb-chevron">/</li>
        </ng-template>
    </ol>
</nav>
