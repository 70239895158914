import { NodeClass } from '@modules/campaigns/Domain/NodeClass';
import { NodeType } from '@modules/campaigns/Domain/Enums/NodeType';
import { NodeValidationError } from '@modules/campaigns/Domain/Interfaces/NodeValidationError';

export class MultiConditional extends NodeClass {
  constructor(id: string, position: { x: number; y: number }, config: {}) {
    super(id, NodeType.Multi, position, config);
  }

  isEmptyConfig(): boolean {
    return false;
  }

  addChild(node: string): void {
    if (this.childrenNodes.length === 0) this.childrenNodes.push(node);
    else return;
  }

  addParent(node: string): void {
    if (this.parentNodes.length === 0) this.parentNodes.push(node);
    else return;
  }

  initNodeConfig() {}

  validate(): NodeValidationError[] {
    return [];
  }
}
