<div class="flex align-items-center justify-content-between flex-wrap gap-5">
    <span *ngIf="!backActive"></span>
    <p-button icon="pi pi-arrow-left" styleClass="p-button-link" *ngIf="backActive" [label]="backLabel" [disabled]="backDisabled" (onClick)="backClicked()" />
    <span *ngIf="!nextActive"></span>
    <div class="flex gap-3">
        <p-button icon="pi pi-arrow-right" iconPos="right" styleClass="p-button-link" *ngIf="nextActive" [label]="nextLabel" [disabled]="nextDisabled" (onClick)="nextClicked()" />

        <p-button *ngIf="next2Active" [label]="next2Label" [disabled]="next2Disabled" (onClick)="nextClicked2()" />
    </div>
</div>
