<b class="text-xl w-10 pl-3">Configuración de Pago Parcial</b>
<div class="flex flex-wrap gap-3">
   <div *ngIf="formGroup" class="flex align-items-center justify-content-start pr-3 flex-row-reverse w-full mt-4">
      <form [formGroup]="formGroup">
         <p-selectButton
            [options]="amountTypeOptions"
            formControlName="value"
            optionLabel="label"
            optionValue="value"
            (onChange)="toggleAmountFields($event.value)"
         ></p-selectButton>
      </form>
   </div>
</div>

<app-dynamic-form
   #form
   [formFields]="partialPaymentOfferForm"
   [formCols]="12"
   [submit]="true"
   [reset]="false"
   [btnLabel]="'Enviar pago parcial'"
   (formData)="confirmation($event)"
   (formValuesChanged)="updateNodeAttributes($event)"
></app-dynamic-form>

<div class="p-3 m-neg-3">
   <p-messages class="" severity="info" [closable]="true">
      <ng-template pTemplate>
         <div class="p-message flex flex-row gap-2">
            <span class="pi pi-info-circle p-1"></span>
            <span *ngIf="this.option === 'minimumAbsoluteAmount'">
               <b>{{ instance.customerName ? instance.customerName : this.customer.name }}</b> debe un total de
               <b
                  >{{ this.totalAmount }}
                  {{ instance.totalAmountCurrency ? instance.totalAmountCurrency : instance.totalAmount.currency }}</b
               >, le enviarás una propuesta de pago parcial por importe mínimo de
               <b
                  >{{ amountToSend }}
                  {{ instance.totalAmountCurrency ? instance.totalAmountCurrency : instance.totalAmount.currency }}</b
               >. Caducará en <b>72 horas</b> a partir del momento del envío.
            </span>
            <span *ngIf="this.option === 'minimumPercentageAmount'">
               <b>{{ instance.customerName ? instance.customerName : this.customer.name }}</b> debe un total de
               <b
                  >{{ this.totalAmount }}
                  {{ instance.totalAmountCurrency ? instance.totalAmountCurrency : instance.totalAmount.currency }}</b
               >, le enviarás una propuesta de pago parcial por importe mínimo de
               <ng-container *ngIf="amountToSendPercentage < 100">
                  <b
                     >{{ (this.totalAmount * amountToSendPercentage) / 100 | number: '1.2-2' }}
                     {{
                        instance.totalAmountCurrency ? instance.totalAmountCurrency : instance.totalAmount.currency
                     }}</b
                  >
               </ng-container>
               <ng-container *ngIf="amountToSendPercentage > 100 || amountToSendPercentage === undefined">
                  <b
                     >{{ this.totalAmount }}
                     {{
                        instance.totalAmountCurrency ? instance.totalAmountCurrency : instance.totalAmount.currency
                     }}</b
                  > </ng-container
               >. Caducará en <b>72 horas</b> a partir del momento del envío.
            </span>
         </div>
      </ng-template>
   </p-messages>
</div>
