import { NodeClass } from '@modules/campaigns/Domain/NodeClass';
import { NodeType } from '@modules/campaigns/Domain/Enums/NodeType';
import { SMSCertifiedConfig } from '@modules/campaigns/Domain/Interfaces/INodeConfig';
import { NodeFactory } from '@modules/campaigns/Domain/Factories/NodeFactory';
import {
  getValidationErrorMessage,
  ValidationError,
} from '@modules/campaigns/Domain/Enums/ValidationErrors';
import { NodeValidationError } from '@modules/campaigns/Domain/Interfaces/NodeValidationError';

export class SMSCertifiedNode extends NodeClass {
  constructor(id: string, position: { x: number; y: number }, config: SMSCertifiedConfig) {
    super(id, NodeType.SendCertifiedSMS, position, config);
  }

  isEmptyConfig(): boolean {
    return this.config['copyIds'].length == 0;
  }

  addChild(node: string): void {
    if (this.childrenNodes.length === 0) this.childrenNodes.push(node);
    else return;
  }

  addParent(node: string): void {
    if (this.parentNodes.length === 0) this.parentNodes.push(node);
    else return;
  }

  override initNodeConfig() {
    (this.config as SMSCertifiedConfig).copyIds = [];
    (this.config as SMSCertifiedConfig).certified = true;
    (this.config as SMSCertifiedConfig).super = false;
    (this.config as SMSCertifiedConfig).sendToAllContacts = false;
  }

  validate(): NodeValidationError[] {
    const errors: NodeValidationError[] = [];

    if (this.config['copyIds'] == null) {
      const errorMessage = getValidationErrorMessage(ValidationError.NO_COPY_IDS, {
        nodoType: 'SMS Certificado',
      });
      errors.push({ nodeId: this.id, errorMessage });
    }

    return errors;
  }
}
