import { gql } from 'apollo-angular';

const sharedFields = `
  id: String!
  name: String!
  description: String!
  type: String!
  status: String!
  collectors: [String]!
`;

const typeDefs = gql`
  type PaymentMethod {
    ${sharedFields}
  }

  type Redsys {
    ${sharedFields}
    merchantCode: String!
    merchantTerminal: String!
    secret: String!
  }

  extend type Query {
    paymentMethod: PaymentMethod
    paymentMethods: [PaymentMethod]
    createRedsysPaymentMethodInput: Redsys
    updateRedsysPaymentMethod: Redsys
    deleteRedsysPaymentMethod: Redsys
  }
`;

const resolvers = {
  RedsysPaymentGateway: {
    collectors: () => {
      return [
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGN', name: 'Fotocasa' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Coches.net' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGN', name: 'Fotocasa' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Test' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Test 2' },
      ];
    },
  },
  Query: {
    paymentMethod: () => ({
      id: 1,
      name: 'PM1',
      description: 'PM1 desc',
      type: 'redsys',
      status: '1',
      merchantCode: '1',
      merchantTerminal: '1',
      secret: '1',
      collectors: [
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGN', name: 'Fotocasa' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Coches.net' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGN', name: 'Fotocasa' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Test' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Test 2' },
      ],
    }),
    paymentMethods: () => [
      {
        id: 1,
        name: 'PM1',
        description: 'PM1 desc',
        type: 'redsys',
        status: '1',
        merchantCode: '1',
        merchantTerminal: '1',
        secret: '1',
        collectors: [
          { id: '01F8DB3ZVCN2FBTVC5Q25PCSGN', name: 'Fotocasa' },
          { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Coches.net' },
          { id: '01F8DB3ZVCN2FBTVC5Q25PCSGN', name: 'Fotocasa' },
          { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Test' },
          { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Test 2' },
        ],
      },
      {
        id: 2,
        name: 'PM2',
        description: 'PM2 desc',
        type: 'unnax',
        status: '1',
        merchantCode: '2',
        merchantTerminal: '2',
        secret: '2',
        collectors: [
          { id: '01F8DB3ZVCN2FBTVC5Q25PCSGN', name: 'Fotocasa' },
          { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Coches.net' },
          { id: '01F8DB3ZVCN2FBTVC5Q25PCSGN', name: 'Fotocasa' },
          { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Test' },
          { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Test 2' },
        ],
      },
    ],
  },
};

export default { typeDefs, resolvers };
