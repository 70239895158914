import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { TranslationService } from './core/services/i18n/translation.service';
import { primengTranslation } from './core/services/i18n/primeng/translation.components';
import { RouteLoaderService } from './core/services/ui/routerLoader.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  lang = 'es';

  constructor(
    private primengConfig: PrimeNGConfig,
    private translationService: TranslationService,
    private _: RouteLoaderService
  ) {}

  ngOnInit(): void {
    this.setConfig();
  }

  setConfig(): void {
    this.primengConfig.ripple = true;
    this.primengConfig.setTranslation(primengTranslation);
  } 
}
