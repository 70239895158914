import { NodeClass } from '@modules/campaigns/Domain/NodeClass';
import { NodeType } from '@modules/campaigns/Domain/Enums/NodeType';
import { MakeOutBoundCallConfig } from '@modules/campaigns/Domain/Interfaces/INodeConfig';
import { NodeValidationError } from '@modules/campaigns/Domain/Interfaces/NodeValidationError';
import {
  getValidationErrorMessage,
  ValidationError,
} from '@modules/campaigns/Domain/Enums/ValidationErrors';

export class MakeOutBoundCallNode extends NodeClass {
  constructor(id: string, position: { x: number; y: number }, config: MakeOutBoundCallConfig) {
    super(id, NodeType.MakeOutBoundCall, position, config);
  }

  isEmptyConfig(): boolean {
    return (
      this.config['voiceId'] == '' ||
      this.config['copyId'] == '' ||
      this.config['copyId'] == null ||
      this.config['voiceId'] == null
    );
  }

  addChild(node: string): void {
    if (this.childrenNodes.length === 0) this.childrenNodes.push(node);
  }

  addParent(node: string): void {
    if (this.parentNodes.length === 0) this.parentNodes.push(node);
    else return;
  }

  initNodeConfig() {
    (this.config as MakeOutBoundCallConfig).voiceId = '';
    (this.config as MakeOutBoundCallConfig).copyId = '';
  }

  validate(): NodeValidationError[] {
    const errors: NodeValidationError[] = [];

    if (this.config['voiceId'] == '') {
      const errorMessage = getValidationErrorMessage(ValidationError.NO_VOICE_ID, {
        nodoType: 'Llamada saliente',
      });
      errors.push({ nodeId: this.id, errorMessage });
    }

    if (this.config['copyIds'] == '') {
      const errorMessage = getValidationErrorMessage(ValidationError.NO_COPY_IDS, {
        nodoType: 'Llamada saliente',
      });
      errors.push({ nodeId: this.id, errorMessage });
    }
    return errors;
  }
}
