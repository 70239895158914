import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { CurrentUserService } from '@core/services/currentUser.service';
import { LayoutService } from '../service/app.layout.service';
import { UserModel } from '@admin/modules/users/models/user.model';
import { TimezoneService } from '@shared/services/timezone.service';
import { DialogService } from 'primeng/dynamicdialog';
import { SelectorTimezoneComponent } from '@shared/components/selector-timezone/selector-timezone.component';

@Component({
  selector: 'app-profilemenu',
  templateUrl: './app.profilesidebar.component.html',
})
export class AppProfileSidebarComponent implements OnInit {
  _user: UserModel = {} as UserModel;
  selectedTimezone: string = '';
  oldSelectedTimezone: string = '';

  constructor(
    public layoutService: LayoutService,
    private auth: AuthService,
    private router: Router,
    public user: CurrentUserService,
    public timezoneService: TimezoneService,
    private dModal: DialogService,
  ) {
    this._user = this.user.currentUser;
  }

  ngOnInit() {
    this.oldSelectedTimezone = this.timezoneService.getTimezone();
  }

  get visible(): boolean {
    return this.layoutService.state.profileSidebarVisible;
  }

  set visible(_val: boolean) {
    this.layoutService.state.profileSidebarVisible = _val;
  }

  signOut() {
    setTimeout(() => {
      this.auth.logout({
        logoutParams: { returnTo: window.location.origin },
      });
    }, 300);
  }

  navigate(path: string) {
    this.router.navigate([path], {
      preserveFragment: true,
      queryParamsHandling: 'preserve',
    });

    this.layoutService.state.profileSidebarVisible = false;
  }

  showDialogTimezone() {
    const ref = this.dModal.open(SelectorTimezoneComponent, {
      header: 'Seleccionar un huso horario',
      width: '60vw',
      height: 'auto',
      closeOnEscape: true,
    });
    ref.onClose.subscribe();
  }
}
