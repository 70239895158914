import { Injectable, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import { ulid } from 'ulid';
import {
  CREATE_SEGMENT,
  DELETE_SEGMENT,
  FIND_SEGMENT,
  GET_SEGMENTS_LIST,
  UPDATE_SEGMENT,
} from '../graphql/segments.graphql';
import { transformfiltersToBackend } from '../helpers/handleData.helper';
import { CurrentUserService } from '@core/services/currentUser.service';
import { PermissionType } from '@admin/modules/users/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class SegmentationService {
  isLoading$ = new BehaviorSubject<boolean>(false);
  private apollo = inject(Apollo);
  private route = inject(ActivatedRoute);
  private currentUser = inject(CurrentUserService);

  getSegment(id: string): Observable<any> {
    return this.apollo
      .query({
        query: FIND_SEGMENT,
        variables: {
          id,
        },
      })
      .pipe(map((res: any) => res.data.segment));
  }

  getSegments(): Observable<any> {
    return this.apollo
      .query({
        query: GET_SEGMENTS_LIST,
        variables: {
          tenantId: this.route.snapshot.queryParams['tenant'],
        },
      })
      .pipe(
        map((res: any) => {
          return res.data.segments;
        }),
      );
  }

  createSegment(segment: any): Observable<any> {
    const { name, filters } = segment;

    return this.apollo
      .query({
        query: CREATE_SEGMENT,
        variables: {
          input: {
            id: ulid(),
            tenantId: this.route.snapshot.queryParams['tenant'],
            name,
            filters: transformfiltersToBackend(filters),
          },
        },
      })
      .pipe(
        map((res: any) => res),
        catchError((error) => {
          console.error('Error al crear segmento', error);
          return throwError(() => new Error(error));
        }),
      );
  }

  updateSegment(segment: any): Observable<any> {
    const { name, filters, id, portfolioIds } = segment;
    return this.apollo
      .query({
        query: UPDATE_SEGMENT,
        variables: {
          input: {
            portfolioIds: portfolioIds || [],
            id,
            name,
            filters: transformfiltersToBackend(filters),
          },
        },
      })
      .pipe(map((res: any) => res));
  }

  deleteSegment(id: string): Observable<any> {
    this.isLoading$.next(true);
    return this.apollo
      .query({
        query: DELETE_SEGMENT,
        variables: {
          id,
        },
      })
      .pipe(map((res: any) => res));
  }

  canWrite() {
    return this.currentUser.allowAccessPermission([
      PermissionType.FULL_ACCESS,
      PermissionType.SEGMENT_WRITE,
    ]);
  }
}
