export class NodeNotFoundError extends Error {
  constructor(nodeId: string) {
    super(`Node with ID ${nodeId} not found`);
    this.name = 'NodeNotFoundError';
  }
}

export class InvalidNodeTypeError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'InvalidNodeTypeError';
  }
}
