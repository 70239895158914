import { NodeClass } from '@modules/campaigns/Domain/NodeClass';
import { NodeType } from '@modules/campaigns/Domain/Enums/NodeType';
import { NodeConfig } from '@modules/campaigns/Domain/Interfaces/INodeConfig';
import { NodeValidationError } from '@modules/campaigns/Domain/Interfaces/NodeValidationError';

export class StartNode extends NodeClass {
  constructor(id: string, position: { x: number; y: number }) {
    super(id, NodeType.Start, position, {} as NodeConfig);
  }

  isEmptyConfig(): boolean {
    return false;
  }

  addChild(node: string): void {
    if (this.childrenNodes.length === 0) this.childrenNodes.push(node);
  }

  addParent(node: string): void {
    return;
    //throw new Error('Start Node cannot have parent Node');
  }

  initNodeConfig() {
    return;
  }

  validate(): NodeValidationError[] {
    return [];
  }
}
