import { IWorkflowValidator } from '@modules/campaigns/Domain/Interfaces/IWorkflowValidator';
import { MinThreeNodesValidator } from '@modules/campaigns/Domain/Validators/MinThreeNodes';
import { DuplicateNodeIDsValidator } from '@modules/campaigns/Domain/Validators/DuplicateNodeIDsValidator';
import { NodesHaveChildValidator } from '@modules/campaigns/Domain/Validators/NodesHaveChildValidator';
import { NodesHaveParentValidator } from '@modules/campaigns/Domain/Validators/NodesHaveParentValidator';
import { StartAndEndNodesValidator } from '@modules/campaigns/Domain/Validators/StartAndEndNodesValidator';
import { AllNodesConnectedToEndValidator } from '@modules/campaigns/Domain/Validators/AllNodesConnectedToEndValidator';

export class ValidatorFactory {
  private static validators: IWorkflowValidator[] = [];

  static registerValidator(validator: IWorkflowValidator) {
    this.validators.push(validator);
  }

  static getValidators(): IWorkflowValidator[] {
    return this.validators;
  }
}

ValidatorFactory.registerValidator(new MinThreeNodesValidator());
ValidatorFactory.registerValidator(new AllNodesConnectedToEndValidator());
ValidatorFactory.registerValidator(new StartAndEndNodesValidator());
ValidatorFactory.registerValidator(new NodesHaveParentValidator());
ValidatorFactory.registerValidator(new NodesHaveChildValidator());
ValidatorFactory.registerValidator(new DuplicateNodeIDsValidator());
