import { gql } from 'apollo-angular';

const typeDefs = gql`
  type FeedbackOption {
    id: String!
    name: String!
    category: String!
    languages: String!
    text: String!
    status: String!
    collectors: [String]!
  }

  extend type Query {
    feedbackOption: FeedbackOption
    feedbackOptions: [FeedbackOption]
    updateFeedbackOption: FeedbackOption
    createFeedbackOption: FeedbackOption
    deleteFeedbackOption: FeedbackOption
  }
`;

const resolvers = {
  Query: {
    feedbackOption: () => ({
      id: 1,
      name: 'FO1',
      category: '1',
      languages: ['es', 'en'],
      texts: ['FO1 Display Text ES', 'FO1 Display Text EN'],
      status: ['1'],
      collectors: [
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGN', name: 'Fotocasa' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Coches.net' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGN', name: 'Fotocasa' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Test' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Test 2' },
      ],
    }),
    feedbackOptions: () => [
      {
        id: 1,
        name: 'FO1',
        category: '1',
        languages: ['es', 'en'],
        texts: ['FO1 Display Text ES', 'FO1 Display Text EN'],
        status: ['1'],
        collectors: [
          { id: '01F8DB3ZVCN2FBTVC5Q25PCSGN', name: 'Fotocasa' },
          { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Coches.net' },
          { id: '01F8DB3ZVCN2FBTVC5Q25PCSGN', name: 'Fotocasa' },
          { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Test' },
          { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Test 2' },
        ],
      },
      {
        id: 2,
        name: 'FO2',
        category: '1',
        languages: ['es', 'ca'],
        texts: ['FO2 Display Text ES', 'FO2 Display Text CA'],
        status: ['1'],
        collectors: [
          { id: '01F8DB3ZVCN2FBTVC5Q25PCSGN', name: 'Fotocasa' },
          { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Coches.net' },
          { id: '01F8DB3ZVCN2FBTVC5Q25PCSGN', name: 'Fotocasa' },
          { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Test' },
          { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Test 2' },
        ],
      },
    ],
  },
};

export default { typeDefs, resolvers };
