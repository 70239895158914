const Decimal = require('decimal.js');

export class CurrencyHelper {
    
  static toCents(amount: number): number {

    if (amount === undefined || amount === null || isNaN(amount) || amount === 0) {
      return 0;
    }

    const value = new Decimal(amount);
    return value.times(100).toNumber();
  }

  static toDecimal(cents: number): number {
    if (cents === undefined || cents === null || isNaN(cents) || cents === 0) {
      return 0;
    }
    const value = new Decimal(cents);
    return value.dividedBy(100).toNumber();
  }
}
