import { gql } from 'apollo-angular';

export const GET_SEGMENTS_LIST = gql`
  query ($tenantId: ID!) {
    segments(tenantId: $tenantId) {
      id
      tenantId
      portfolioIds
      name
      default
      isInUse
      filters
    }
  }
`;

export const FIND_SEGMENT = gql`
  query ($id: ID!) {
    segment(id: $id) {
      id
      tenantId
      name
      default
      filters
    }
  }
`;

export const CREATE_SEGMENT = gql`
  mutation ($input: CreateSegmentInput!) {
    createSegment(input: $input) {
      id
      tenantId
      name
      filters
    }
  }
`;

export const DELETE_SEGMENT = gql`
  mutation ($id: ID!) {
    deleteSegment(id: $id) {
      id
    }
  }
`;

export const UPDATE_SEGMENT = gql`
  mutation ($input: UpdateSegmentInput!) {
    updateSegment(input: $input) {
      id
      tenantId
      portfolioIds
      name
      filters
    }
  }
`;
