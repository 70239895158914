import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';

// Components
import { CurrencyModalComponent } from './currency-modal/currency-modal.component';
import { DynamicFormInputComponent } from './dynamic-form/dynamic-form-input/dynamic-form-input.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { ErrorHandlerComponent } from './dynamic-form/components/error-handler/error-handler.component';
import { EditSaveActionComponent } from './edit-save-action/edit-save-action.component';
import { GoBackComponent } from './go-back/go-back.component';
import { NewFeatureSoonComponent } from './new-feature-soon/new-feature-soon.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ActivitiesComponent } from './activities/activities.component';
import { FlagsComponent } from './flags/flags.component';
import { NextBackComponent } from './next-back/next-back.component';
import { JointJsComponent } from './joint-js/joint-js.component';
import { CharactersCounterComponent } from './dynamic-form/components/characters-counter/characters-counter.component';
import { LoaderComponent } from './loader/loader.component';
import { NodeInspectorComponent } from './joint-js/components/node-inspector/node-inspector.component';
import { NodeDragDropComponent } from './joint-js/components/node-drag-drop/node-drag-drop.component';

import { NodeOverviewComponent } from './node-overview/node-overview.component';
import { PlaceholdersSelectorComponent } from './dynamic-form/components/placeholders-selector/placeholders-selector.component';
import { SendReminderComponent } from './send-reminder/send-reminder.component';
import { SendReminderModalComponent } from './send-reminder/components/send-reminder-modal/send-reminder-modal.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FiltersComponent } from './dashboard/collector-dropdown/filters.component';
import { GraphControllersComponent } from './joint-js/components/graph-controllers/graph-controllers.component';
import { FieldsetPComponent } from './fieldset-p/fieldset-p.component';
import { SendProposePaymentAgreementComponent } from './send-propose-payment-agreement/send-propose-payment-agreement.component';
import { SendProposePaymentAgreementModalComponent } from './send-propose-payment-agreement/send-propose-payment-agreement-modal/send-propose-payment-agreement-modal.component';
import { SimpleSearchComponent } from './simple-search/simple-search.component';
import { TagComponent } from './tag/tag.component';

// Pipes
import { CurrencyWithCodePipe } from '../pipes/currency-with-code.pipe';
import { StandarDatePaymefyPipe } from '../pipes/standar-paymefy-pipe.pipe';

// Modules
import { PRIMENGModule } from '../prime-ng/prime-ng.module';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TranslationModule } from '@core/services/i18n/translation.module';
import { PipeModule } from '../pipes/pipe.module';

// Services
import { ToastService } from '@core/services/ui/toast.service';
import { CreateNewComponent } from './create-new/create-new.component';
import { DividerModule } from 'primeng/divider';
import { SpeedDialModule } from 'primeng/speeddial';
import { PartialPaymentOfferComponent } from './partial-payment-offer/partial-payment-offer.component';
import { MessageWaitNodesComponent } from './joint-js/components/node-inspector/message-wait-nodes/message-wait-nodes.component';
import { CustomSliderComponent } from './custom-slider/custom-slider.component';
import { SliderModule } from 'primeng/slider';
import { MeterGroupComponent } from './meter-group/meter-group.component';
import { TransformDateTimezonePipe } from '@shared/pipes/transformDateTimezone.pipe';
import { SelectorTimezoneComponent } from './selector-timezone/selector-timezone.component';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';

const c = [
  DynamicFormComponent,
  DynamicFormInputComponent,
  EditSaveActionComponent,
  GoBackComponent,
  CurrencyModalComponent,
  ErrorHandlerComponent,
  NewFeatureSoonComponent,
  SpinnerComponent,
  ActivitiesComponent,
  FlagsComponent,
  NextBackComponent,
  JointJsComponent,
  CharactersCounterComponent,
  LoaderComponent,
  NodeInspectorComponent,
  NodeDragDropComponent,
  NodeOverviewComponent,
  PlaceholdersSelectorComponent,
  SendReminderComponent,
  SendReminderModalComponent,
  DashboardComponent,
  FiltersComponent,
  GraphControllersComponent,
  FieldsetPComponent,
  SendProposePaymentAgreementComponent,
  SendProposePaymentAgreementModalComponent,
  MeterGroupComponent,
  SimpleSearchComponent,
  CreateNewComponent,
  TagComponent,
  PartialPaymentOfferComponent,
];
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PRIMENGModule,
    TranslateModule,
    TranslationModule,
    PipeModule,
    DragDropModule,
    InfiniteScrollModule,
    DividerModule,
    SpeedDialModule,
    SliderModule,
    InputGroupAddonModule,
  ],
  declarations: [
    ...c,
    TagComponent,
    MessageWaitNodesComponent,
    CustomSliderComponent,
    MeterGroupComponent,
    SelectorTimezoneComponent,
  ],
  exports: [...c, CustomSliderComponent],
  providers: [
    ToastService,
    CurrencyWithCodePipe,
    StandarDatePaymefyPipe,
    TransformDateTimezonePipe,
    TranslatePipe,
  ],
})
export class CustomComponentsModule {}
