<p-sidebar
  (onHide)="onHide()"
  [(visible)]="visible"
  position="right"
  [blockScroll]="true"
  [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'"
  styleClass="layout-profile-sidebar w-12 sm:w-12 md:w-6 ">
  <div class="flex flex-column mx-auto md:mx-0">
    <div class="text-700 font-semibold text-2xl mb-3 text-center">
      {{ 'DATA.MODULES.LAYOUT.ACTIVITY.TITLE' | translate }}
    </div>
    <div class="flex flex-alig-items-center justify-content-center w-full mb-6">
      <p-selectButton
        optionLabel="label"
        optionValue="value"
        [options]="filterOptions"
        [(ngModel)]="filterSelected">
      </p-selectButton>
    </div>
    
    <app-activities #activity [filter]="filterSelected"></app-activities>
  </div>
</p-sidebar>
