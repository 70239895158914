import { gql } from 'apollo-angular';

const typeDefs = gql`
  type Collector {
    id: String!
    name: String!
    taxEntityId: String!
    brandId: String!
    serviceIds: [String!]
    channelConfigIds: [String!]
    paymentGatewayIds: [String!]
    eventHookConfigIds: [String!]
    feedbackOptionIds: [String!]
  }

  extend type Query {
    collectors: [Collector]
    collector: Collector
    updateCollector: Collector
    createCollector: Collector
    deleteCollector: Collector
  }
`;

const resolvers = {
  Query: {
    collector: () => ({
      id: '01F64NAP1EZH6R0N4C5NDG3VGT',
      name: 'Collector 1',
      taxEntityId: '01F9V5A8DQPJSFNNVEA7NTDF53',
      brandId: '',
      serviceIds: [],
      channelConfigIds: [],
      paymentGatewayIds: [],
      eventHookConfigIds: [],
      feedbackOptionIds: [],
    }),
    collectors: () => [
      {
        id: '01F64NAP1EZH6R0N4C5NDG3VGT',
        name: 'Collector 1',
        taxEntityId: '01F9V5A8DQPJSFNNVEA7NTDF53',
        brandId: '',
        serviceIds: [],
        channelConfigIds: [],
        paymentGatewayIds: [],
        eventHookConfigIds: [],
        feedbackOptionIds: [],
      },
    ],
  },
};

export default { typeDefs, resolvers };
