import { Component, ElementRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LayoutService } from '../service/app.layout.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './app.sidebar.component.html',
})
export class AppSidebarComponent {
  timeout: any = null;
  theme = environment.theme;
  logo = '/assets/media/logo/paymefy-small.jpg';
  logoStyle = 'width: 20px; height: auto, margin: 15px 0';
  constructor(public layoutService: LayoutService, public el: ElementRef) {}

  onMouseEnter() {
    if (!this.layoutService.state.anchored) {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      this.layoutService.state.revealMenuActive = true;
    }
  }

  onMouseLeave() {
    if (!this.layoutService.state.anchored) {
      if (!this.timeout) {
        this.timeout = setTimeout(
          () => (this.layoutService.state.revealMenuActive = false),
          300
        );
      }
    }
  }

  anchor() {
    this.layoutService.state.anchored = !this.layoutService.state.anchored;
  }
}
