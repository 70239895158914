import tenant from './tenant';
import user from './user';
import paymentMethod from './payment-method';
import feedbackOptions from './feedback-options';
import notificationChannel from './notification-channel';
import service from './service';
import eventHookConfig from './event-hook';
import collector from './collector';
import brand from './brand';
import taxEntities from './tax-entity';
import operation from './operation';

const typeDefs = [
  tenant.typeDefs,
  user.typeDefs,
  paymentMethod.typeDefs,
  feedbackOptions.typeDefs,
  notificationChannel.typeDefs,
  service.typeDefs,
  eventHookConfig.typeDefs,
  collector.typeDefs,
  brand.typeDefs,
  taxEntities.typeDefs,
  operation.typeDefs,
];

const resolvers = [
  tenant.resolvers,
  user.resolvers,
  paymentMethod.resolvers,
  feedbackOptions.resolvers,
  notificationChannel.resolvers,
  service.resolvers,
  eventHookConfig.resolvers,
  collector.resolvers,
  brand.resolvers,
  taxEntities.resolvers,
  operation.resolvers,
];

export { typeDefs, resolvers };
