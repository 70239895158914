import { gql } from 'apollo-angular';

const typeDefs = gql`
  type Service {
    id: String!
    name: String!
    status: String!
    category: String!
    collectors: [String]!
  }

  extend type Query {
    services: [Service]
    service: Service
    updateService: Service
    createService: Service
    deleteService: Service
  }
`;

const resolvers = {
  Service: {
    collectors: () => {
      return [
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGN', name: 'Fotocasa' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Coches.net' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGN', name: 'Fotocasa' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Test' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Test 2' },
      ];
    },
  },
  Query: {
    service: () => ({
      id: '01F64NAP1EZH6R0N4C5NDG3VGT',
      name: 'Service 1',
      status: '1',
      category: '1',
      collectors: [
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGN', name: 'Fotocasa' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Coches.net' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGN', name: 'Fotocasa' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Test' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Test 2' },
      ],
    }),
    services: () => [
      {
        id: '01F64NAP1EZH6R0N4C5NDG3VGT',
        name: 'Service 1',
        status: '1',
        category: '1',
        collectors: [
          { id: '01F8DB3ZVCN2FBTVC5Q25PCSGN', name: 'Fotocasa' },
          { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Coches.net' },
          { id: '01F8DB3ZVCN2FBTVC5Q25PCSGN', name: 'Fotocasa' },
          { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Test' },
          { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Test 2' },
        ],
      },
    ],
  },
};

export default { typeDefs, resolvers };
