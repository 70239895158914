import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { GET_TENANT } from '@modules/customers/customer.service';
import { from, Observable, of, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TenantInvoiceCriteria } from '../models/invoice.model';
import {
  ADD_TENANT,
  DELETE_TENANT,
  EDIT_TENANT,
  EDIT_TENANT_LOGO,
  GENERATE_TENANT_INVOICES,
  GET_TENANT_INVOICES,
  GET_TENANTS,
  UPDATE_CERTIFIED_NOTIFICATIONS_SETTINGS,
} from '../graphql/tenants.graphql';
import { formatUTCDate } from '@shared/helpers/standarFormat';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  constructor(
    private route: ActivatedRoute,
    private apollo: Apollo,
  ) {}
  getActualTenantCollectors(): Observable<any> {
    return this.route.queryParams.pipe(
      switchMap((queryParams) => {
        const tenantId = queryParams?.['tenant'];
        if (!tenantId) {
          return of(null);
        }
        return from(
          this.apollo.query({
            query: GET_TENANT,
            variables: {
              id: tenantId,
            },
          }),
        );
      }),
      switchMap((res: any) => {
        if (res?.errors?.length) {
          return throwError(() => res?.errors?.message);
        }
        const tenant = res?.data?.tenant;
        if (!tenant) {
          return throwError(() => 'Tenant not found');
        }
        return of(tenant.name);
      }),
    );
  }

  getActualTenantId(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apollo
        .query({
          query: GET_TENANT,
          variables: {
            id,
          },
        })
        .subscribe((res: any) => {
          if (res.errors?.length) {
            reject(res);
          } else {
            resolve(res.data.tenant);
          }
        });
    });
  }

  getInvoiceList(criteria: TenantInvoiceCriteria): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apollo
        .query({
          query: GET_TENANT_INVOICES,
          variables: {
            criteria: {
              pagination: criteria.pagination,
              filters: criteria.filters,
            },
          },
        })
        .subscribe((res: any) => {
          if (res.errors?.length) {
            reject(res);
          } else {
            resolve(res.data.invoices);
          }
        });
    });
  }

  getTenants() {
    return this.apollo
      .query({
        query: GET_TENANTS,
        variables: {},
      })
      .pipe(map((res: any) => res.data.tenants));
  }

  generateTenansInvoices(date: Date, tenants: string[]): Observable<any> {
    const formattedDate = this.formatDateToYYYYMMDD(date);
    return this.apollo.mutate({
      mutation: GENERATE_TENANT_INVOICES,
      variables: {
        input: {
          date: formattedDate,
          tenantIds: tenants,
        },
      },
    });
  }

  formatDateToYYYYMMDD(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns month index from 0
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  createTenant(tenant: any): Observable<any> {
    return this.apollo.mutate({
      mutation: ADD_TENANT,
      variables: {
        input: { ...tenant },
      },
      context: {
        useMultipart: true,
      },
    });
  }

  editTenant(tenant: any, id: string): Observable<any> {
    const { logo, ...data } = tenant;

    const tenantData = { ...data };
    return this.apollo.mutate({
      mutation: EDIT_TENANT,
      variables: {
        id,
        input: { ...tenantData },
      },
    });
  }

  editTenantLogo(data: any, id: string): Observable<any> {
    const input = {
      logo: data.logo,
    };

    return this.apollo.mutate({
      mutation: EDIT_TENANT_LOGO,
      variables: {
        id,
        input,
      },
      context: {
        useMultipart: true,
      },
    });
  }

  editCertifiedConfig(id: string, tenantId: string, data: any): Observable<any> {
    if (data.smsLimits) {
      data.smsLimits = data.smsLimits.map((sl: any) => ({ limit: Number(sl) }));
    }
    if (!data.smsLimits) {
      data.smsLimits = [
        {
          limits: 108,
        },
      ];
    }

    return this.apollo.mutate({
      mutation: UPDATE_CERTIFIED_NOTIFICATIONS_SETTINGS,
      variables: {
        id,
        input: { tenantId, ...data },
      },
    });
  }

  deleteTenant(id: string): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_TENANT,
      variables: {
        id,
      },
    });
  }
}
