import { NodeClass } from '@modules/campaigns/Domain/NodeClass';
import { NodeType } from '@modules/campaigns/Domain/Enums/NodeType';
import {
  OfferPartialPaymentConfig,
  OfferPaymentAgreementConfig,
} from '@modules/campaigns/Domain/Interfaces/INodeConfig';
import {
  getValidationErrorMessage,
  ValidationError,
} from '@modules/campaigns/Domain/Enums/ValidationErrors';
import { NodeValidationError } from '@modules/campaigns/Domain/Interfaces/NodeValidationError';

export class OfferPaymentAgreementNode extends NodeClass {
  constructor(id: string, position: { x: number; y: number }, config: OfferPaymentAgreementConfig) {
    super(id, NodeType.OfferPaymentAgreement, position, config);
  }

  isEmptyConfig(): boolean {
    return this.config['expirationDays'] == null || this.config['numberOfInstalments'] == null;
  }

  addChild(node: string): void {
    if (this.childrenNodes.length === 0) this.childrenNodes.push(node);
    else return;
  }

  addParent(node: string): void {
    if (this.parentNodes.length === 0) this.parentNodes.push(node);
    else return;
  }

  initNodeConfig(): void {
    (this.config as OfferPaymentAgreementConfig).expirationDays = 1;
    (this.config as OfferPaymentAgreementConfig).numberOfInstalments = 2;
  }

  validate(): NodeValidationError[] {
    const errors: NodeValidationError[] = [];
    if (this.config['expirationDays'] == null) {
      const errorMessage = getValidationErrorMessage(ValidationError.NO_SEGMENTS, {
        nodoType: 'Acuerdo de Pago',
      });
      errors.push({ nodeId: this.id, errorMessage });
    }

    if (this.config['numberOfInstalments'] == null) {
      const errorMessage = getValidationErrorMessage(ValidationError.NO_MIN_AMOUNT, {
        nodoType: 'Acuerdo de Pago',
      });
      errors.push({ nodeId: this.id, errorMessage });
    }

    if (this.config['numberOfInstalments'] > 11 || this.config['numberOfInstalments'] < 2) {
      const errorMessage = ValidationError.EXCEEDED_INSTALMENTS;
      errors.push({ nodeId: this.id, errorMessage });
    }

    if (this.config['expirationDays'] > 99 || this.config['expirationDays'] < 0) {
      const errorMessage = ValidationError.EXCEEDED_CADUCITY;
      errors.push({ nodeId: this.id, errorMessage });
    }

    return errors;
  }
}
