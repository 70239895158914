import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export interface Locale {
  lang: string;
  data: any;
}

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  constructor(private translate: TranslateService) {
    translate.setDefaultLang('es'); // TODO: GET CONFIG LANGUAGE
  }

  switchLanguage(language: string) {
    this.translate.use(language);
  }
}
