import { gql } from 'apollo-angular';

const sharedFields = `
  id: String!
  tenantId: String!
  type: String!
  enabled: String!
  collectors: [String]!
`;

const typeDefs = gql`
  type EventHookConfig {
    ${sharedFields}
  }

  type EventHookConfigWeb {
    ${sharedFields}
    url: String!
  }

  type EventHookConfigEmail {
    ${sharedFields}
    email: String!
  }

  extend type Query {
    eventHookConfig: EventHookConfig
    eventHookConfigs: [EventHookConfig]

    createEventHookConfigWebInput: EventHookConfigWeb
    updateEventHookConfigWeb: EventHookConfigWeb
    deleteEventHookConfigWeb: EventHookConfigWeb
    
    createEventHookConfigEmailInput: EventHookConfigEmail
    updateEventHookConfigEmail: EventHookConfigEmail
    deleteEventHookConfigEmail: EventHookConfigEmail
  }
`;

const resolvers = {
  WebEventHookConfig: {
    collectors: () => {
      return [
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGN', name: 'Fotocasa' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Coches.net' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGN', name: 'Fotocasa' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Test' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Test 2' },
      ];
    },
  },
  EmailEventHookConfig: {
    collectors: () => {
      return [
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGN', name: 'Fotocasa' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Coches.net' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGN', name: 'Fotocasa' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Test' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Test 2' },
      ];
    },
  },
  Query: {
    eventHookConfig: () => ({
      id: '1',
      tenantId: '239784234',
      type: 'email',
      enabled: true,
      email: 'jonathan.alcazar@bekodo.com',
    }),

    eventHookConfigs: () => [
      {
        id: '1',
        tenantId: '239784234',
        type: 'email',
        enabled: true,
        email: 'jonathan.alcazar@bekodo.com',
      },
      {
        id: '2',
        tenantId: '459674568',
        type: 'web',
        enabled: true,
        url: 'https://www.google.es',
      },
    ],
  },
};

export default { typeDefs, resolvers };
