import { NodeClass } from '@modules/campaigns/Domain/NodeClass';
import { NodeType } from '@modules/campaigns/Domain/Enums/NodeType';
import { NodeConfig } from '@modules/campaigns/Domain/Interfaces/INodeConfig';
import { NodeValidationError } from '@modules/campaigns/Domain/Interfaces/NodeValidationError';

export class EndNode extends NodeClass {
  constructor(id: string, position: { x: number; y: number }) {
    super(id, NodeType.End, position, {} as NodeConfig);
  }

  isEmptyConfig(): boolean {
    return this.config['copyIds'] == '' || this.config['voiceId'] == '';
  }

  addChild(node: string): void {
    throw new Error('End Node cannot have child node');
  }

  addParent(node: string): void {
    if (this.parentNodes.length === 0) this.parentNodes.push(node);
    else return;
  }

  initNodeConfig(): void {
    return;
  }

  validate(): NodeValidationError[] {
    return [];
  }
}
