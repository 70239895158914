
export const activityFilters = {
    FEEDBACK_SENT: 'feedback_sent',
    PAYLINK_NOTIFICATION_SENT: 'paylink_notification_sent',
    PAYMENT_ORDER_CAPTURED: 'payment_order_captured',
} as const;

export type AllowedActivities = typeof activityFilters[keyof typeof activityFilters];


interface PaymentOrder {
  id: string;
  amount: number;
  status: {
    code: string;
    description: string;
  };
  details: {
    [key: string]: string;
  };
  gatewayType: string;
}

export interface customerActivity {
  name: string;
  category: string;
  parameters: {
    paymentOrder?: PaymentOrder;
    copy?: {
      id: string;
      name: string;
      version: number;
    };
    causation?: {
      name: string;
      type: string;
    };
    channelConfig?: {
      id: string;
      channel: string;
    };
    paylinkNotification?: {
      id: string;
      expirationAt: null;
    };
    feedback?: {
      id: string;
      comment?: string;
    };
    customerCase?: {
      id: string;
    };
    operationIds?: string[];
    feedbackOption?: {
      id: string;
      title: string;
    };
  };
  dateTime: string;
}
