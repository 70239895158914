<div
  class="layout-sidebar"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()">
  <div
    class="theme-identifier"
    style="height: 15px; width: 100%"
    [ngStyle]="{ background: theme }"></div>

  <div
    class="relative"
    *ngIf="!this.layoutService.state.revealMenuActive"
    style="height: 80px; width: 100%">
    <img
      [src]="'/assets/media/logo/paymefy-small.jpg'"
      style="
        width: 30px;
        height: auto;
        position: absolute;
        right: 0.3rem;
        top: 2rem;
      " />
  </div>
  <a
    [routerLink]="['/']"
    [queryParamsHandling]="'preserve'"
    class="app-logo"
    *ngIf="this.layoutService.state.revealMenuActive">
    <img
      class="app-logo-normal"
      [src]="'/assets/media/logo/paymefy-logo-dark.png'"
      style="width: 150px" />
  </a>
  <div class="layout-menu-container">
    <app-menu></app-menu>
  </div>
</div>
