import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyHelper } from '@shared/helpers/decimals.helper';

@Pipe({
  name: 'currencyWithCode',
})
export class CurrencyWithCodePipe implements PipeTransform {
  transform(value: number, currencyCode: string): string {
    const formattedValue = new Intl.NumberFormat('es', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true,
    }).format(CurrencyHelper.toDecimal(value));
    const code = currencyCode.slice(0, 3).toUpperCase();
    return `${formattedValue} ${code}`;
  }
}
