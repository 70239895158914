import moment from 'moment-timezone';

export function convertTimezones(countries: any) {
  const results: any[] = [];

  Object.keys(countries).forEach((code) => {
    const country = countries[code];
    const timezones = country.timezones;

    Object.keys(timezones).forEach((tz) => {
      const timezone = timezones[tz];

      const currentOffset = moment.tz(tz).utcOffset();
      const gmtOffset = currentOffset / 60;
      const sign = gmtOffset >= 0 ? '+' : '-';
      const gmtString = `GMT${sign}${Math.abs(gmtOffset)}`;
      results.push({ value: tz, label: `${timezone.name} (${gmtString})` });
    });
  });

  return results;
}
