import { gql } from 'apollo-angular';

const typeDefs = gql`
  type User {
    id: String!
    name: String!
    surname: String!
    email: String!
    role: String!
    collectors: [String!]
  }

  extend type Query {
    users: [User]
    user: User
    updateUser: User
    createUser: User
    deleteUser: User
  }
`;

const resolvers = {
  Query: {
    user: () => ({
      id: 1,
      name: 'Yeray',
      surname: 'Romero',
      email: 'yeray.romero@bekodo.com',
      role: 'admin',
      collectors: ['1'],
    }),

    users: () => [
      {
        id: 1,
        name: 'Yeray',
        surname: 'Romero',
        email: 'yeray.romero@bekodo.com',
        role: 'admin',
        collectors: ['1', '2'],
      },
      {
        id: 2,
        name: 'Jonathan',
        surname: 'Alcázar',
        email: 'jonathan.alcazar@bekodo.com',
        role: 'admin',
        collectors: ['3', '4'],
      },
    ],
  },
};

export default { typeDefs, resolvers };
