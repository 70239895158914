<div class="grid">
  <div class="col-12 lg:col-12">
    <div class="flex justify-content-center align-items-center" *ngIf="loading">
      <app-spinner />
    </div>

    <div *ngIf="activity.length && !loading">
      <!-- TIMELINE START -->
      <p-timeline *ngIf="activity.length" [value]="activity">
        <!-- CENTER - MARKER -->
        <ng-template pTemplate="marker" let-event>
          <span
            class="flex z-1 w-3rem h-3rem align-items-center justify-content-center text-white border-circle shadow-2"
            [style.backgroundColor]="getColor(event.category, event.name)">
            <i
              [style]="
                'font-size: 1.4rem; color: ' +
                getIconColor(event.category, event.name)
              "
              [ngClass]="getIcon(event.category, event.name)"></i>
          </span>
        </ng-template>
        <!-- / CENTER - MARKER -->

        <!-- CONTENT - TYPES EVENT -->
        <ng-template pTemplate="content" let-event>
          <br />

          <ng-container *ngIf="event.category === 'paylink_notification'">
            <p class="text-base" *ngIf="event.parameters?.causation">
              {{ handleNotificationEvent(event) }}
            </p>
          </ng-container>

          <ng-container *ngIf="event.category === 'feedback'">
            <p class="text-base">
              {{ handleFeedbackEvent(event) }}
            </p>
          </ng-container>

          <ng-container *ngIf="event.category === 'payment_order'">
            <p class="text-base">
              {{ handlePaymentEvent(event) }}
            </p>
          </ng-container>
          <br />
        </ng-template>
        <!-- CONTENT - TYPES EVENT -->

        <!-- OPPOSITE DATA AND HOUR -->
        <ng-template pTemplate="opposite" let-event>
          <p class="p-text-secondary mt-2 text-base">
            {{ event.dateTime | timezoneDate : true }}
          </p>
        </ng-template>
      </p-timeline>
      <!-- /OPPOSITE DATA AND HOUR -->

      <!-- TIMELINE END -->

      <!-- NO DATA MESSAGE  -->

      <!-- /NO DATA MESSAGE  -->
    </div>
    <div *ngIf="!activity.length && !loading">
      <h4 class="mb-5 text-500 font-light text-base text-center">
        No hay datos de actividad que mostrar
      </h4>
    </div>
  </div>

  <div class="flex  justify-content-center align-items-center w-full mt-5">
    <p-button *ngIf="!loading && !noMoreResults " [label]="'cargar más'" (click)="increase()"></p-button>
  </div>
  <!-- /END  -->
</div>
