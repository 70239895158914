import * as joint from 'jointjs';
import { NodeModel } from '../model/node.model';
import { ulid } from 'ulid';

const daysOfWeekTranslations: { [key: string]: string } = {
  SUNDAY: 'DOMINGO',
  MONDAY: 'LUNES',
  TUESDAY: 'MARTES',
  WEDNESDAY: 'MIÉRCOLES',
  THURSDAY: 'JUEVES',
  FRIDAY: 'VIERNES',
  SATURDAY: 'SÁBADO',
};

export function translateDay(day: string): string {
  return daysOfWeekTranslations[day.toUpperCase()] || day;
}

export interface draggableMenu {
  events: any[];
  actions: any[];
  actionPlus: any[];
  conditions: any[];
  flexibility: any[];
  calls: any[];
  assign: any[];
  end: any[];
}

export const PAPER_CONFIG = {
  background: {
    color: '#F9F9F9',
  },
  width: '100%',
  height: '100vh',
  interactive: true,
  gridSize: 10,
  drawGrid: true,
  snapLinks: true,
  fitToContent: true,
  linkPinning: false,
  markAvailable: true,
  defaultRouter: { name: 'manhattan' },
  defaultLink: () =>
    new joint.shapes.standard.Link({
      attrs: {
        line: {
          stroke: '#808080',
          strokeWidth: 1,
        },
      },
    }),
  defaultConnectionPoint: { name: 'boundary' },
};

export const ZOOM_CONFIG = (paper: any) => {
  return {
    viewportSelector: paper!.layers,
    zoomEnabled: false,
    mouseWheelZoomEnabled: false,
    panEnabled: false,
    controlIconsEnabled: true,
    fit: true,
    contain: true,
    center: true,
    refreshRate: 'auto' as 'auto' | number,
    minZoom: 0.1,
    onUpdatedCTM: (() => {
      let currentMatrix = paper!.matrix();
      return (matrix: any) => {
        if (paper) {
          const { a, d, e, f } = matrix;
          const { a: ca, d: cd, e: ce, f: cf } = currentMatrix;
          const translateChanged = e !== ce || f !== cf;
          if (translateChanged) {
            paper.trigger('translate', e - ce, f - cf)!;
          }
          const scaleChanged = a !== ca || d !== cd;
          if (scaleChanged) {
            paper.trigger('scale', a, d, e, f);
          }
          currentMatrix = matrix;
        }
      };
    })(),
  };
};

export const draggables: draggableMenu = {
  events: [
    {
      name: 'Esperar Días',
      id: 'waitDays',
      class: 'draggable-node event',
      isActive: true,
    },
    {
      name: 'Esperar Días Semana',
      id: 'waitDayOfWeek',
      class: 'draggable-node event',
      isActive: true,
    },
    {
      name: 'Esperar Vencimiento',
      id: 'waitDaysForDueDate',
      class: 'draggable-node event',
      isActive: true,
    },
    {
      name: 'Esperar Día Mes',
      id: 'waitDayOfMonth',
      class: 'draggable-node event',
      isActive: true,
    } /*
    {
      name: 'Multi conditional',
      id: 'multiconditional',
      class: 'draggable-node multi-conditional',
      isActive: true,
    },*/,
  ],
  actions: [
    {
      name: 'SMS',
      id: 'sendSMS',
      class: 'draggable-node action sms',
      isActive: true,
    },
    {
      name: 'SMS Certificado',
      id: 'sendCertifiedSMS',
      class: 'draggable-node action sms',
      isActive: true,
    },

    {
      name: 'Email',
      id: 'sendEmail',
      class: 'draggable-node action email',
      isActive: true,
    },
    {
      name: 'Email Certificado',
      id: 'sendCertifiedEmail',
      type: 'email-certified',
      class: 'draggable-node action email',
      isActive: true,
    },
  ],

  actionPlus: [
    {
      name: 'SMS +',
      id: 'superSendSMS',
      class: 'draggable-node action-plus sms',
      isActive: true,
    },
    {
      name: 'SMS Certificado +',
      id: 'superSendCertifiedSMS',
      class: 'draggable-node action-plus sms',
      isActive: true,
    },
    {
      name: 'Email +',
      id: 'superSendEmail',
      class: 'draggable-node action-plus email',
      isActive: true,
    },
    {
      name: 'Email Certificado +',
      id: 'superSendCertifiedEmail',
      class: 'draggable-node action-plus email',
      isActive: true,
    },
  ],

  conditions: [
    {
      name: 'Sí (notification)',
      id: 'paylinkNotificationStatus',
      class: 'draggable-node condition',
      isActive: true,
    },
    {
      name: 'Sí (segmento)',
      id: 'customerCaseSegments',
      class: 'draggable-node condition',
      isActive: true,
    },
  ],

  flexibility: [
    {
      name: 'Pago parcial',
      id: 'offerPartialPayment',
      class: 'draggable-node condition',
      isActive: true,
    },
    {
      name: 'Acuerdo de pago',
      id: 'offerPaymentAgreement',
      class: 'draggable-node condition',
      isActive: true,
    },
  ],

  assign: [
    {
      name: 'Asignar a campaña',
      id: 'assignCampaign',
      class: 'draggable-node action assign',
      isActive: true,
    },
  ],

  calls: [
    {
      name: 'Llamada saliente',
      id: 'makeOutboundCall',
      class: 'draggable-node call-out',
      isActive: true,
    },
  ],

  end: [
    {
      name: 'Final',
      id: 'end',
      class: 'draggable-node end',
      isActive: true,
    },
  ],
};

export const DEFAULT_NODES = (): NodeModel[] => {
  const startNode = ulid();
  const endNode = ulid();
  return [
    {
      nodeId: startNode,
      type: 'event',
      diagram: { coordinates: { x: 200, y: 0 } },
      parameters: {
        name: 'start',
      },
      childrenIds: [endNode],
    },
    {
      nodeId: endNode,
      type: 'event',
      diagram: { coordinates: { x: 200, y: 200 } },
      parameters: {
        name: 'end',
      },
      childrenIds: [],
    },
  ];
};

/**
 *
 *  NODES TYPES ALLOWEDS
 *  @description
 *  This object is used to validate the nodes properties
 *  It is used in the nodes validator service
 *
 **/
export const NODES_TYPES_ALLOWEDS = {
  //EVENTS
  waitDays: ['name', 'waitDays', 'partDay'],
  waitDaysForDueDate: ['name', 'waitDays', 'partDay'],
  waitDayOfWeek: ['name', 'dayOfWeek', 'partDay'],
  waitDayOfMonth: ['name', 'dayOfMonth', 'partDay'],

  //ASSIGN
  assignCampaign: ['name', 'targetCampaignId'],
  //CONDITIONAL
  conditional: ['name', 'targetNodeId', 'statuses'],
  paylinkNotificationStatus: ['name', 'targetNodeId', 'statuses', 'cases'],

  //FLEXIBILITY
  offerPartialPayment: ['name', 'expirationDays', 'minimumAmount', 'minimumPercentageAmount'],
  offerPaymentAgreement: ['name', 'expirationDays', 'numberOfInstalments'],

  //CALLS
  makeOutboundCall: ['name', 'voiceId', 'copyId'],

  //SEND NODES
  sendCertifiedSMS: ['name', 'copyIds'],
  sendCertifiedEmail: ['name', 'copyIds'],
  sendSMS: ['name', 'copyIds'],
  sendEmail: ['name', 'copyIds'],

  //SUPER NODES
  superSendCertifiedSMS: ['name', 'copyIds'],
  superSendCertifiedEmail: ['name', 'copyIds'],
  superSendSMS: ['name', 'copyIds'],
  superSendEmail: ['name', 'copyIds'],
};

export const mapColorsNode: Record<string, string> = {
  start: '#81c784',
  waitDays: '#f1c40f',
  waitDaysForDueDate: '#f1c40f',
  waitDayOfWeek: '#f1c40f',
  waitDayOfMonth: '#f1c40f',
  sendCertifiedSMS: '#0041A8',
  sendCertifiedEmail: '#0095FF',
  sendSMS: '#0041A8',
  sendEmail: '#0095FF',
  superSendCertifiedSMS: '#0041A8',
  superSendCertifiedEmail: '#0095FF',
  superSendSMS: '#0041A8',
  superSendEmail: '#0095FF',
  assignCampaign: '#6449b4',
  conditional: '#ff851b',
  offerPartialPayment: '#9683EC',
  offerPaymentAgreement: '#4B0082',
  paylinkNotificationStatus: '#ff851b',
  customerCaseSegments: '#a67b5b',
  makeOutboundCall: '#00c0ac',
  end: '#e57373',
};
