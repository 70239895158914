import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule, Header, Footer, MessageService } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { Dialog, DialogModule } from 'primeng/dialog';
import { ConfirmDialog, ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { MenubarModule } from 'primeng/menubar';
import { ButtonModule } from 'primeng/button';
import { ListboxModule } from 'primeng/listbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { PanelModule } from 'primeng/panel';
import { CalendarModule } from 'primeng/calendar';
import { AccordionModule } from 'primeng/accordion';
import { TabViewModule } from 'primeng/tabview';
import { FocusTrapModule } from 'primeng/focustrap';
import { CheckboxModule } from 'primeng/checkbox';
import { TreeTableModule } from 'primeng/treetable';
import { TreeModule } from 'primeng/tree';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ScrollTopModule } from 'primeng/scrolltop';
import { SidebarModule } from 'primeng/sidebar';
import { AvatarModule } from 'primeng/avatar';
import { SkeletonModule } from 'primeng/skeleton';
import { TooltipModule } from 'primeng/tooltip';
import { ChipsModule } from 'primeng/chips';
import { ToastModule } from 'primeng/toast';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { RippleModule } from 'primeng/ripple';
import { BadgeModule } from 'primeng/badge';
import { DialogService } from 'primeng/dynamicdialog';
import { KnobModule } from 'primeng/knob';
import { ChartModule } from 'primeng/chart';
import { ChipModule } from 'primeng/chip';
import { TagModule } from 'primeng/tag';
import { TimelineModule } from 'primeng/timeline';
import { SplitButtonModule } from 'primeng/splitbutton';
import { MessagesModule } from 'primeng/messages';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SelectButtonModule } from 'primeng/selectbutton';
import { FileUploadModule } from 'primeng/fileupload';
import { ConfirmationService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular';
import { TabMenuModule } from 'primeng/tabmenu';
import { FieldsetModule } from 'primeng/fieldset';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { StepsModule } from 'primeng/steps';
import { TreeSelectModule } from 'primeng/treeselect';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SplitterModule } from 'primeng/splitter';
import { ScrollerModule } from 'primeng/scroller';
import { ColorPickerModule } from 'primeng/colorpicker';

export const APP_PRIMENG_MODULE = [
  SplitterModule,
  SharedModule,
  TableModule,
  DialogModule,
  ConfirmDialogModule,
  DropdownModule,
  MenubarModule,
  ButtonModule,
  ListboxModule,
  RadioButtonModule,
  PanelModule,
  AccordionModule,
  CalendarModule,
  TabViewModule,
  FocusTrapModule,
  CheckboxModule,
  TreeTableModule,
  TreeModule,
  PanelMenuModule,
  SidebarModule,
  ScrollTopModule,
  AvatarModule,
  SkeletonModule,
  ConfirmDialogModule,
  ToastModule,
  InputTextModule,
  CheckboxModule,
  FormsModule,
  TooltipModule,
  ChipsModule,
  InputNumberModule,
  InputTextareaModule,
  MultiSelectModule,
  ConfirmPopupModule,
  OverlayPanelModule,
  DynamicDialogModule,
  RippleModule,
  BadgeModule,
  KnobModule,
  ChartModule,
  ChipModule,
  TagModule,
  TimelineModule,
  SplitButtonModule,
  MessagesModule,
  InputSwitchModule,
  SelectButtonModule,
  FileUploadModule,
  FullCalendarModule,
  TabMenuModule,
  FieldsetModule,
  ToggleButtonModule,
  StepsModule,
  TreeSelectModule,
  CascadeSelectModule,
  ProgressSpinnerModule,
  ScrollerModule,
  FileUploadModule,
  ColorPickerModule,
];
export const APP_PRIMENG_COMPONENTS = [Dialog, ConfirmDialog, Header, Footer];

export const APP_PRIMENG_PROVIDERS = [
  ConfirmationService,
  MessageService,
  ConfirmationService,
  DialogService,
];

@NgModule({
  declarations: [],
  imports: [CommonModule, APP_PRIMENG_MODULE],
  exports: [APP_PRIMENG_MODULE],
  providers: [APP_PRIMENG_PROVIDERS],
})
export class PRIMENGModule {}
