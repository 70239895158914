<div class="p-3">
   <div *ngIf="selectedNode?.type === NodeType.WaitDaysForDueDate && selectedNode">
      <p-messages severity="info" [closable]="true">
         <ng-template pTemplate>
            <div class="p-message flex flex-row gap-2">
               <span class="pi pi-info-circle p-1"></span>
               <span>
                  El nodo indica que las notificaciones configuradas a continuación se enviarán por
                  <b> {{ getPartDay() }} </b>, <b> {{ getWaitDays() }} </b> días
                  <b> {{ selectedNode?.config?.['antesODespues'] | translate }} </b>
                  del vencimiento de la factura. Si el cliente tiene más de una factura se prioriza la fecha del
                  vencimiento de la factura más antigua.
               </span>
            </div>
         </ng-template>
      </p-messages>
   </div>

   <div *ngIf="selectedNode?.type === NodeType.WaitDays && selectedNode">
      <p-messages severity="info" [closable]="true">
         <ng-template pTemplate>
            <div class="p-message flex flex-row gap-2">
               <span class="pi pi-info-circle p-1"></span>
               <span>
                  El nodo indica que las notificaciones configuradas a continuación se enviarán por
                  <b> {{ getPartDay() }} </b>, dentro de <b> {{ getWaitDays() }} </b> días.
               </span>
            </div>
         </ng-template>
      </p-messages>
   </div>

   <div *ngIf="selectedNode?.type === NodeType.WaitDayOfWeek && selectedNode">
      <p-messages severity="info" [closable]="true">
         <ng-template pTemplate>
            <div class="p-message flex flex-row gap-2">
               <span class="pi pi-info-circle p-1"></span>
               <span>
                  El nodo indica que las notificaciones configuradas a continuación se enviarán por
                  <b> {{ getPartDay() }} </b>, el <b> {{ getDayOfWeek() }} </b>.
               </span>
            </div>
         </ng-template>
      </p-messages>
   </div>

   <div *ngIf="selectedNode?.type === NodeType.WaitDayOfMonth && selectedNode">
      <p-messages severity="info" [closable]="true">
         <ng-template pTemplate>
            <div class="p-message flex flex-row gap-2">
               <span class="pi pi-info-circle p-1"></span>
               <span>
                  El nodo indica que las notificaciones configuradas a continuación se enviarán por
                  <b> {{ getPartDay() }} </b>, el día <b> {{ getDayOfMonth() }} </b> de este mes.
               </span>
            </div>
         </ng-template>
      </p-messages>
   </div>
</div>
