import { IWorkflowValidator } from '@modules/campaigns/Domain/Interfaces/IWorkflowValidator';
import { ValidationError } from '@modules/campaigns/Domain/Enums/ValidationErrors';
import { IWorkflow } from '@modules/campaigns/Domain/Interfaces/IWorkflow';
import { NodeType } from '@modules/campaigns/Domain/Enums/NodeType';
import { WorkflowType } from '@modules/campaigns/Domain/Enums/WorkflowType';

export class NodesHaveParentValidator implements IWorkflowValidator {
  validate(workflow: IWorkflow): string[] {
    const errors: string[] = [];

    workflow.nodes.forEach((node) => {
      if (node.type !== NodeType.Start && node.parentNodes.length === 0) {
        errors.push(ValidationError.NO_PARENT_NODE);
      }
    });

    return errors;
  }
  shouldValidate(workflow: IWorkflow): boolean {
    return workflow.type === WorkflowType.DRIP || workflow.type === WorkflowType.PILL;
  }
}
