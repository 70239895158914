import { FormDropdown } from '@shared/components/dynamic-form/models/form-dropdown';
const countOptions = () => {
  const options = [];
  for (let i = 2; i <= 12; i++) {
    options.push({ label: i.toString(), value: i });
  }
  return options;
};
export const propostalForm = [
  new FormDropdown({
    key: 'channel',
    type: 'dropdown',
    required: true,
    label: 'DATA.MODULES.SHARED.COMPONENTS.SEND_PAYMENT_AGREEMENT.FORM.CHANNEL.LABEL',
    options: [],
    multi: false,
    filter: true,
    readonly: false,
  }),

  new FormDropdown({
    key: 'instalments',
    type: 'dropdown',
    required: true,
    label: 'DATA.MODULES.SHARED.COMPONENTS.SEND_PAYMENT_AGREEMENT.FORM.CHANNEL.INSTALMENTS',
    options: countOptions(),
    value: 1,
    multi: false,
    filter: true,
    readonly: false,
  }),
];
