import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CampaignModel } from '../models/campaign.model';
import { WorkflowClass } from '@modules/campaigns/Domain/WorkflowClass';

@Injectable({
  providedIn: 'root',
})
export class StepperCampaignService {
  private INITAL_CONFIG = {
    info: {
      id: '',
      name: '',
      purpose: '',
      success: '',
      collectors: [],
      type: '',
    },
    segmentation: {
      includedSegmentIds: [],
    },
    strategy: {
      nodes: [],
    },
    workflow: {
      workflow: '',
    },
  };
  private originalCampaignData = new BehaviorSubject<CampaignModel>({} as CampaignModel);
  private stepperDataSubject = new BehaviorSubject<any>(this.INITAL_CONFIG);
  private triggerData = new BehaviorSubject<any>({});

  public stepperData$ = this.stepperDataSubject.asObservable();
  public campaingId$ = this.originalCampaignData.asObservable();

  updateCampaignData(data: any) {
    const currentData = this.stepperDataSubject.value;
    const updatedData = { ...currentData, info: data };
    this.stepperDataSubject.next(updatedData);
  }
  updateCampaignId(id: string) {
    const currentData = this.stepperDataSubject.value;
    const updatedData = {
      ...currentData,
      info: { ...currentData.info, id: id },
    };
    this.stepperDataSubject.next(updatedData);
  }

  setTriggers(triggers: { label: string; value: string }[]) {
    this.triggerData.next(triggers);
  }

  getTriggers() {
    return this.triggerData.value;
  }

  updateSegmentation(includedSegmentIds: string[]) {
    const currentData = this.stepperDataSubject.value;
    const updatedData = {
      ...currentData,
      segmentation: {
        includedSegmentIds,
      },
    };
    this.stepperDataSubject.next(updatedData);
  }

  updateCheckData(data: any) {
    const currentData = this.stepperDataSubject.value;
    const updatedData = { ...currentData, data };
    this.stepperDataSubject.next(updatedData);
  }

  getAllData() {
    return this.stepperDataSubject.value;
  }

  getSegmentationData() {
    return this.stepperDataSubject.value.segmentation;
  }

  getNodes() {
    return this.stepperDataSubject.value.strategy.nodes;
  }

  getWorkflow() {
    return this.stepperDataSubject.value.workflow.workflow;
  }

  // setNodes
  setNodes(nodes: any) {
    const currentData = this.stepperDataSubject.value;
    const updatedData = { ...currentData, strategy: { ...currentData.strategy, nodes } };
    this.stepperDataSubject.next(updatedData);
  }

  setWorkflow(workflow: WorkflowClass) {
    const currentData = this.stepperDataSubject.value;
    const updatedData = { ...currentData, workflow: { ...currentData.workflow, workflow } };
    this.stepperDataSubject.next(updatedData);
  }

  getGeneralData() {
    return this.stepperDataSubject.value.info;
  }

  getOriginalCampaignData() {
    return this.originalCampaignData.value;
  }

  setOriginalCampaignData(campaign: CampaignModel) {
    this.originalCampaignData.next(campaign);
  }

  isEditMode(): boolean {
    const campaignData = this.getOriginalCampaignData();
    return !campaignData.id || campaignData.status === 'draft';
  }

  resetStepper() {
    this.setOriginalCampaignData({} as CampaignModel);
    this.stepperDataSubject.next(structuredClone(this.INITAL_CONFIG));
  }
}
