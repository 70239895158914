import { gql } from 'apollo-angular';

const typeDefs = gql`
  extend type TaxEntities {
    collectors: [String]!
  }
`;

const resolvers = {
  TaxEntity: {
    collectors: () => {
      return [
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGN', name: 'Fotocasa' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Coches.net' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGN', name: 'Fotocasa' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Test' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Test 2' },
      ];
    },
  },
};

export default { typeDefs, resolvers };
