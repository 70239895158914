import { Injectable, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DashboardV2Response, ReportTypes } from '../model/report.model';
import { DASHBOARD_V2 } from '../graphql/report.graphql';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, map } from 'rxjs';
import { ApolloQueryResult } from '@apollo/client';
import { addMonths, startOfDay, endOfDay, format } from 'date-fns';
import { setStartOfDay } from '@shared/helpers/date-utils';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  isLoading$ = new BehaviorSubject<boolean>(false);
  private route = inject(ActivatedRoute);
  private apollo = inject(Apollo);

  getReportV2(reportType: ReportTypes, filters?: any): any {
    const today = new Date();
    const oneMonthAgo = addMonths(today, -1);
    const startDate = startOfDay(oneMonthAgo);
    const dateFormat = 'yyyy-MM-dd HH:mm:ss';

    let endDate: Date;

    if (filters && filters['date']) {
      endDate = endOfDay(new Date(filters.date[1]));
    } else {
      endDate = endOfDay(today);
    }

    const minimumData = {
      tenantId: this.route.snapshot.queryParams['tenant'],
      types: [reportType],
      startDate: format(startDate, dateFormat),
      months: 6,
      endDate: format(endDate, dateFormat),
      expiresAt: format(addMonths(today, 1), dateFormat),
    };

    let criteria = { ...minimumData };

    if (filters && typeof filters === 'object') {
      Object.keys(filters).forEach(
        (key) => (filters[key] == null || filters[key] === '') && delete filters[key],
      );

      if (filters['date']) {
        const startDate = startOfDay(new Date(filters.date[0]));
        const endDate = endOfDay(new Date(filters.date[1]));

        filters = {
          ...filters,
          startDate: format(startDate, dateFormat),
          endDate: format(endDate, dateFormat),
        };
        delete filters['date'];
      } else if (filters['dateMonth']) {
        const dateMonth = startOfDay(new Date(filters.dateMonth));
        criteria.startDate = format(dateMonth, dateFormat);
        delete filters['dateMonth'];
      } else if (filters['dateMonthNotification']) {
        filters = {
          ...filters,
          startDate: setStartOfDay(new Date(filters.dateMonthNotification)),
          endDate: setStartOfDay(addMonths(new Date(filters.dateMonthNotification), 1)),
        };
        delete filters['dateMonthNotification'];
      }
      if (filters['campaignIdNoRequired']) {
        filters = {
          ...filters,
          campaignId: filters.campaignIdNoRequired.value,
        };
        delete filters['campaignIdNoRequired'];
      }
      criteria = { ...criteria, ...filters };
    }

    return this.apollo
      .query<DashboardV2Response>({
        query: DASHBOARD_V2,
        variables: {
          criteria,
        },
      })
      .pipe(
        map((res: ApolloQueryResult<DashboardV2Response>) => {
          return res.data.dashboardUrlsV2[0].url;
        }),
      );
  }
}
