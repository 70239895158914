import { NodeClass } from '@modules/campaigns/Domain/NodeClass';
import { NodeType } from '@modules/campaigns/Domain/Enums/NodeType';
import { SMSConfig } from '@modules/campaigns/Domain/Interfaces/INodeConfig';
import { NodeFactory } from '@modules/campaigns/Domain/Factories/NodeFactory';
import { NodeValidationError } from '@modules/campaigns/Domain/Interfaces/NodeValidationError';
import {
  getValidationErrorMessage,
  ValidationError,
} from '@modules/campaigns/Domain/Enums/ValidationErrors';

export class SMSNode extends NodeClass {
  constructor(id: string, position: { x: number; y: number }, config: SMSConfig) {
    super(id, NodeType.SendSMS, position, config);
  }

  isEmptyConfig(): boolean {
    return this.config['copyIds'].length == 0;
  }

  addChild(node: string): void {
    if (this.childrenNodes.length === 0) this.childrenNodes.push(node);
    else return;
  }

  addParent(node: string): void {
    if (this.parentNodes.length === 0) this.parentNodes.push(node);
    else return;
  }

  initNodeConfig() {
    (this.config as SMSConfig).copyIds = [];
    (this.config as SMSConfig).certified = false;
    (this.config as SMSConfig).super = false;
    (this.config as SMSConfig).sendToAllContacts = false;
  }

  validate(): NodeValidationError[] {
    const errors: NodeValidationError[] = [];

    if (this.config['copyIds'] == null) {
      const errorMessage = getValidationErrorMessage(ValidationError.NO_COPY_IDS, {
        nodoType: 'SMS',
      });
      errors.push({ nodeId: this.id, errorMessage });
    }

    return errors;
  }
}
