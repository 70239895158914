import { Injectable, inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { CurrentUserService } from '../services/currentUser.service';
import { UserModel } from '@admin/modules/users/models/user.model';

@Injectable({ providedIn: 'root' })
export class PermissionGuard  {
  user: UserModel = {} as UserModel;
  public router = inject(Router);
  private currentUserService = inject(CurrentUserService);

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    this.user = this.currentUserService.currentUser;
    try {
      if (this.user) {
        if (
          !this.currentUserService.isPermissionAuthorized(
            route.data['permissions']
          )
        ) {
          await this.router.navigate(['/'], {
            queryParamsHandling: 'preserve',
          });
          return false;
        }
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }
}
