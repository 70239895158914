<div class="ml-3">
    <app-dynamic-form
        #form
        *ngIf="filterInputs"
        [formFields]="filterInputs"
        [formCols]="3"
        [reset]="false"
        [submit]="false"
        (formValuesChanged)="sendValues($event)"
    ></app-dynamic-form>
</div>
