import { NodeType } from '@modules/campaigns/Domain/Enums/NodeType';
import { NodeClass } from '@modules/campaigns/Domain/NodeClass';
import { WaitDaysOfWeekConfig } from '@modules/campaigns/Domain/Interfaces/INodeConfig';
import { NodeValidationError } from '@modules/campaigns/Domain/Interfaces/NodeValidationError';
import {
  getValidationErrorMessage,
  ValidationError,
} from '@modules/campaigns/Domain/Enums/ValidationErrors';

export class WaitDaysOFWeekNode extends NodeClass {
  constructor(id: string, position: { x: number; y: number }, config: WaitDaysOfWeekConfig) {
    super(id, NodeType.WaitDayOfWeek, position, config);
  }

  isEmptyConfig(): boolean {
    return this.config['partDay'] == null || this.config['dayOfWeek'] == null;
  }

  addChild(node: string): void {
    if (this.childrenNodes.length === 0) this.childrenNodes.push(node);
    else return;
  }

  addParent(node: string): void {
    if (this.parentNodes.length === 0) this.parentNodes.push(node);
    else return;
  }

  initNodeConfig() {
    (this.config as WaitDaysOfWeekConfig).partDay = null;
    (this.config as WaitDaysOfWeekConfig).dayOfWeek = null;
  }

  validate(): NodeValidationError[] {
    const errors: NodeValidationError[] = [];

    if (this.config['partDay'] == null) {
      const errorMessage = getValidationErrorMessage(ValidationError.NO_PART_DAY, {
        nodoType: 'Esperar Días Semana',
      });
      errors.push({ nodeId: this.id, errorMessage });
    }

    if (this.config['dayOfWeek'] == null) {
      const errorMessage = getValidationErrorMessage(ValidationError.NO_DAY_OF_WEEK, {
        nodoType: 'Esperar Días Semana',
      });
      errors.push({ nodeId: this.id, errorMessage });
    }

    return errors;
  }
}
