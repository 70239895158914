import {
  AgeFilter,
  CollectorFilter,
  Filter,
  FilterValue,
  InputData,
  PayableOperationsFilter,
  PendingAmountFilter,
  PortfolioFilter,
  VariableFilter,
} from '@models/segments/segments.model';
import { CurrencyHelper } from '@shared/helpers/decimals.helper';

const createFilterEntry = (type: string, value: FilterValue) => {
  return {
    type: type,
    value: value,
  };
};

export const transformtoFilter = (inputData: any): { filters: Filter[] } => {
  const filters: Filter[] = [];

  inputData.collector_ids &&
    filters.push(
      createFilterEntry('collector_ids', {
        collectorIds: inputData.collector_ids,
      }),
    );

  inputData.portfolio_ids &&
    filters.push(
      createFilterEntry('portfolio_ids', {
        portfolioIds: inputData.portfolio_ids,
      }),
    );

  if (inputData.min_customer_case_pending_amount !== undefined) {
    let min_amount = CurrencyHelper.toCents(parseInt(inputData.min_customer_case_pending_amount));
    let max_amount: any =
      inputData.max_customer_case_pending_amount !== undefined
        ? CurrencyHelper.toCents(parseInt(inputData.max_customer_case_pending_amount))
        : null;

    filters.push(
      createFilterEntry('customer_case_pending_amount', {
        min: {
          amount: min_amount,
          currency: 'EUR',
        },
        max: {
          amount: max_amount ? max_amount : null,
          currency: 'EUR',
        },
      }),
    );
  }

  if (inputData.min_customer_case_overdue_days) {
    filters.push(
      createFilterEntry('customer_case_overdue_days', {
        min: inputData.min_customer_case_overdue_days,
        max: inputData.max_customer_case_overdue_days || null,
      }),
    );
  }

  if (inputData.min_customer_case_number_of_payable_operations) {
    filters.push(
      createFilterEntry('customer_case_number_of_payable_operations', {
        min: inputData.min_customer_case_number_of_payable_operations,
        max: inputData.max_customer_case_number_of_payable_operations || null,
      }),
    );
  }

  if (inputData.min_customer_age) {
    filters.push(
      createFilterEntry('customer_age', {
        min: inputData.min_customer_age ? parseInt(inputData.min_customer_age) : 0,
        max: inputData.max_customer_age || null,
      }),
    );
  }

  if (inputData.customer_is_company) {
    filters.push(createFilterEntry('customer_is_company', String(inputData.customer_is_company)));
  }

  if (inputData.customer_gender) {
    filters.push(createFilterEntry('customer_gender', inputData.customer_gender));
  }

  return { filters };
};

export const transformFiltersToInput = (filters: Filter[]): any => {
  const inputData: Partial<InputData> = {};
  for (const filter of filters) {
    switch (filter.type) {
      case 'custom_field':
        const variableFilter = filter.value as VariableFilter;
        inputData.field = variableFilter.field;
        inputData.value = variableFilter.value;
        break;
      case 'collector_ids':
        inputData.collector_ids = (filter.value as CollectorFilter).collectorIds;
        break;
      case 'portfolio_ids':
        inputData.portfolio_ids = (filter.value as PortfolioFilter).portfolioIds;
        break;
      case 'customer_case_pending_amount':
        const amountFilter = filter.value as PendingAmountFilter;
        inputData.min_customer_case_pending_amount = CurrencyHelper.toDecimal(
          amountFilter.min?.amount!,
        );
        inputData.max_customer_case_pending_amount = CurrencyHelper.toDecimal(
          amountFilter.max?.amount!,
        );
        break;
      case 'customer_case_overdue_days':
      case 'customer_age':
        const ageFilter = filter.value as AgeFilter;
        if (filter.type === 'customer_case_overdue_days') {
          inputData.min_customer_case_overdue_days = ageFilter.min;
          inputData.max_customer_case_overdue_days = ageFilter.max;
          break;
        }
        inputData.min_customer_age = ageFilter.min;
        inputData.max_customer_age = ageFilter.max;
        break;
      case 'customer_is_company':
        inputData.customer_is_company = String(filter.value);
        break;
      case 'customer_case_number_of_payable_operations':
        const payableOperationsFilter = filter.value as PayableOperationsFilter;
        inputData.min_customer_case_number_of_payable_operations = payableOperationsFilter.min;
        inputData.max_customer_case_number_of_payable_operations = payableOperationsFilter.max;
        break;
      case 'customer_gender':
        inputData.customer_gender = filter.value as string[];
        break;
    }
  }
  return inputData as InputData;
};

export const cleanTypename = (obj: any): any => {
  if (typeof obj === 'object' && obj !== null) {
    delete obj.__typename;
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        obj[key] = cleanTypename(obj[key]);
      }
    }
  } else if (Array.isArray(obj)) {
    obj = obj.map((item) => cleanTypename(item));
  }
  return obj;
};

export const getCollectorsNames = (collectors: any[], ids: string[]): string => {
  if (!Array.isArray(ids)) {
    return '';
  }

  let jointNames = '';

  ids.forEach((id, index) => {
    const collector = collectors.find((collector) => collector.id === id);
    if (collector) {
      jointNames += collector.name;
      if (index !== ids.length - 1) {
        jointNames += ', ';
      }
    }
  });

  return jointNames;
};

export const getPortfoliosNames = (portfolios: any[], ids: string[]) => {
  let jointNames = '';

  ids.forEach((id, index) => {
    const collector = portfolios.find((collector) => collector.id === id);
    if (collector) {
      jointNames += collector.name;
      if (index !== ids.length - 1) {
        jointNames += ', ';
      }
    }
  });
  return jointNames;
};

export const getGenderNames = (gender: string) => {
  const genderMap: Record<string, string> = {
    male: 'Hombre',
    female: 'Mujer',
    non_binary: 'No binario',
    not_known: 'Otro',
  };

  return genderMap[gender] || 'Desconocido';
};

export const transformfiltersToBackend = (inputData: any) => {
  return inputData.map(({ type, value }: { type: string; value: any }) => {
    let newValue;

    switch (type) {
      case 'customer_is_company':
        newValue = { isCompany: value };
        break;
      case 'collector_ids':
        newValue = value;
        break;
      case 'portfolio_ids':
        newValue = value;
        break;
      case 'customer_gender':
        newValue = { genders: value };
        break;
      case 'customer_age':
      case 'customer_case_number_of_payable_operations':
      case 'customer_case_overdue_days':
        newValue = {
          min: value.min,
          max: value.max,
        };
        break;
      case 'customer_case_pending_amount':
        newValue = {
          minMoney: value.min,
          maxMoney: value.max,
        };
        break;
      default:
        newValue = value;
    }

    return {
      type,
      value: newValue,
    };
  });
};
