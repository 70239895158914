import { NodeClass } from '@modules/campaigns/Domain/NodeClass';
import { NodeType } from '@modules/campaigns/Domain/Enums/NodeType';
import { ConditionalConfig } from '@modules/campaigns/Domain/Interfaces/INodeConfig';
import {
  getValidationErrorMessage,
  ValidationError,
} from '@modules/campaigns/Domain/Enums/ValidationErrors';
import { NodeValidationError } from '@modules/campaigns/Domain/Interfaces/NodeValidationError';

export class ConditionalNode extends NodeClass {
  constructor(id: string, position: { x: number; y: number }, config: ConditionalConfig) {
    super(id, NodeType.Conditional, position, config);
  }

  isEmptyConfig(): boolean {
    return this.config['targetNodeId'] == null || this.config['statuses'] == null;
  }

  addChild(node: string): void {
    if (this.childrenNodes.length === 0 || 1) this.childrenNodes.push(node);
    else console.warn('TENEMOS CHILD');
  }

  addParent(node: string): void {
    if (this.parentNodes.length === 0) this.parentNodes.push(node);
    else console.warn('TENEMOS PARENT');
  }

  initNodeConfig() {
    (this.config as ConditionalConfig).targetNodeId = null;
    (this.config as ConditionalConfig).statuses = null;
    (this.config as ConditionalConfig).cases = { false: null, true: null };
  }

  validate(): NodeValidationError[] {
    const errors: NodeValidationError[] = [];
    const options = ['received', 'read', 'error'];
    let errorStatus = false;

    if (this.config['targetNodeId'] == null) {
      const errorMessage = getValidationErrorMessage(ValidationError.NO_TARGET_NODE, {
        nodoType: 'Condicional de Notificación',
      });
      errors.push({ nodeId: this.id, errorMessage });
    }

    if (this.config['statuses'] == null || errorStatus) {
      const errorMessage = getValidationErrorMessage(ValidationError.NO_STATUS, {
        nodoType: 'Condicional de Notificación',
      });
      errors.push({ nodeId: this.id, errorMessage });
    } else {
      this.config['statuses'].forEach((status: string) => {
        if (!options.includes(status)) {
          const errorMessage = getValidationErrorMessage(ValidationError.NO_STATUS, {
            nodoType: 'Condicional de Notificación',
          });
          errors.push({ nodeId: this.id, errorMessage });
        }
      });
    }

    if (this.config['cases'].false == null || this.config['cases'].true == null) {
      const errorMessage = getValidationErrorMessage(ValidationError.NO_CASE_CONDITIONAL, {
        nodoType: 'Condicional de Notificación',
      });
      errors.push({ nodeId: this.id, errorMessage });
    }

    if (this.config['cases'].false == null || this.config['cases'].true == null) {
      const errorMessage = getValidationErrorMessage(ValidationError.NO_CASE_CONDITIONAL, {
        nodoType: 'Condicional de Notificación',
      });
    }

    return errors;
  }
}
