export const locale = {
  lang: 'es',
  EVENING: 'NOCHE',
  FULL_DAY: 'TODO EL DÍA',
  MORNING: 'MAÑANA',
  beforeDueDate: 'ANTES',
  afterDueDate: 'DESPUÉS',
  DATA: {
    COMMON: {
      GENERIC: {
        NOT_FOUND: 'No encontrado',
      },
      NAMES: {
        CAMPAIGN: 'campaña',
        PILL: 'píldora',
        COLLECTOR: 'perfil de cobro',
      },
      ACTIONS: {
        PUBLISH: 'Publicar ',
        DRAFT: 'Archivar ',
        PAUSED: 'Pausar ',
        DRAFT_INFO: 'No hay cambios de estado disponibles',
      },
      SEARCH: {
        SEARCH_BUTTON: 'Buscar',
        RESET_BUTTON: 'Limpiar',
      },
      FORM_BUTTONS: {
        SAVE: 'Guardar',
        CANCEL: 'Cancelar',
        SEND: 'Enviar',
        CONFIRM: 'Confirmar',
        CLONE: 'Clonar',
      },
      TOAST: {
        SUCCESS: 'Èxito',
        ERROR: 'Error',
        SUCCESS_EMAIL: 'Recordatorio enviado correctamente a {{email}}',
        SUCCESS_SMS: 'Recordatorio enviado correctamente a {{sms}}',
        NO_NOTIFICATED: 'No se envío la notificación',
        SCENARIOS: {
          LIST: {
            ERROR: 'Error al listar los elementos',
          },
        },
      },
      TIME: {
        TODAY: 'Hoy',
        YESTERDAY: 'Ayer',
        LAST_WEEK: 'Última semana',
        LAST_MONTH: 'Último mes',
        LAST_YEAR: 'Último año',
      },
      GENDERS: {
        MALE: 'Hombre',
        FEMALE: 'Mujer',
        NOT_KNOW: 'Prefiero no decirlo',
        NOT_BINARY: 'No binario',
      },
      LANGUAGES: {
        ES: 'Español',
        EN: 'Inglés',
        CA: 'Catalán',
        FR: 'Francés',
        CV: 'Valenciano',
      },
      PAYMENT_METHODS: {
        CASH: 'Efectivo',
        BANK_TRANSFER: 'Transferencia bancaria',
        OTHER: 'Otro',
        DIRECT_DEBIT: 'Domiciliación bancaria',
        CREDIT_CARD: 'Tarjeta de crédito',
      },
      STATUS: {
        on_hold: 'En espera',
        pending: 'Pendiente',
        in_allegations: 'Alegaciones',
        charged: 'Cobrado',
        agreement: 'Acuerdo de pago',
        discarded: 'Descartado',
        opened: 'Abierto',
        open: 'Abierto',
        closed: 'Cerrado',
        created: 'Creado',
        close: 'Cerrado',
        worked: 'Trabajado',
        silenced: 'Silenciado',
        queued: 'En cola',
        generating: 'Generando',
        generated: 'Generado',
        failed: 'Fallo',
        archived: 'Archivado',
        draft: 'Borrador',
        committed: 'Carga finalizada',
        processing: 'Procesando',
        committing: 'Cargando',
        invalid_file: 'Archivo inválido',
        valid: 'Válido',
        not_valid: 'No válido',
        paused: 'Pausado',
        published: 'Publicado',
        unpublished: 'Archivado',
        running: 'Activo',
        expired: 'Expirado',
        proposal: 'Propuesta',
        signed: 'Firmado',
        fulfilled: 'Completado',
        broken: 'Roto',
        started: 'Iniciado',
      },
      CHARACTERS: 'Carácteres',
      FORM: {
        ERROR: {
          REQUIRED: 'Este campo es obligatorio',
          EMAIL: 'Introduce una dirección válida',
          MIN_LENGTH: 'Introduce como mínimo',
          MAX_LENGTH: 'Introduce como máximo',
          PATTERN: 'Formato no válido',
          MIN: 'Introduce un valor superior a',
        },
      },
    },
    WEEK_DAYS: {
      MONDAY: 'Lunes',
      TUESDAY: 'Martes',
      WEDNESDAY: 'Miércoles',
      THURSDAY: 'Jueves',
      FRIDAY: 'Viernes',
      SATURDAY: 'Sábado',
      SUNDAY: 'Domingo',
    },
    TRANSLATOR: {
      SELECT: 'Elige tu idioma',
    },
    MENU: {
      SIDE: {
        DASHBOARD: 'panel',
        ANALYTICS: 'Analytics',
        CRM: {
          CRM: 'CRM',
          IMPORT_LINES: 'Listado de importaciones',
          EXPEDIENTS: 'Operaciones',
          CUSTOMERS: 'Clientes',
          PAYMENT_AGREEMENT: 'Acuerdos de pago',
          SEGMENTS: 'Segmentos',
          PORTFOLIOS: 'Carteras',
        },
        CAMPAIGN_MANAGER: {
          CAMPAIGN_MANAGER: 'Campaign Manager',
          CAMPAIGNS: 'Campañas',
          COLLECTION_CYCLE: 'Ciclos de cobro',
          WORKFLOW: 'Flujo automático',
          TEMPLATES: 'Plantillas',
        },
        ADMINISTRATION: {
          ADMINISTRATION: 'Administración',
          INVOICES: 'Facturas',
          USERS: 'Usuarios',
          COLLECTORS: 'Collectors',
          REGIONAL_CONFIG: 'Configuración regional',
          PAYMENT_SOLUTION: 'Soluciones de pago',
          BRANDS: 'Marcas',
          TAX_ENTITIES: 'Entidades fiscales',
          PAYMENT_METHODS: 'Métodos de pago',
          NOTIFICATION_CHANNELS: 'Canales de notificación',
          FEEDBACK_OPTIONS: 'Opciones de Feedback',
          SERVICES: 'Servicios',
          EVENT_HOOK: 'Métodos de seguimiento',
        },
      },
    },
    ADMIN: {
      TITLE: 'Administración',
      MODULES: {
        BRAND: {
          TITLE: 'Marcas',
          PAGES: {
            ADD: {
              TITLE: 'Crear marca',
            },
            EDIT: {
              TITLE: 'Editar marca',
            },
          },
          LIST: {
            TABLE: {
              HEADERS: {
                LOGO: 'LOGO',
                NAME: 'NOMBRE',
                COLLECTORS: 'PERFILES DE COBRO',
                ACTIONS: 'ACCIONES',
              },
              ACTIONS: {
                EDIT: 'Editar',
                DELETE: 'Eliminar',
              },
              MORE: {
                NO_RESULTS: 'No hay marcas que mostrar',
              },
            },
          },
        },
        USERS: {
          TITLE: 'Usuarios',
          PAGES: {
            ADD: {
              TITLE: 'Crear usuario',
            },
            EDIT: {
              TITLE: 'Editar usuario',
            },
          },
          LIST: {
            TABLE: {
              HEADERS: {
                NAME: 'NOMBRE',
                SURNAME: 'APELLIDOS',
                ROLE: 'ROL',
                EMAIL: 'EMAIL',
                COLLECTORS: 'PERFILES DE COBRO',
                ACTIONS: 'ACCIONES',
              },
              ACTIONS: {
                EDIT: 'Editar',
                DELETE: 'Eliminar',
              },
              MORE: {
                NO_RESULTS: 'No hay usuarios que mostrar',
              },
            },
          },
        },
        PORTFOLIOS: {
          TITLE: 'Carteras',
          PAGES: {
            ADD: {
              TITLE: 'Crear cartera',
            },
            EDIT: {
              TITLE: 'Editar cartera',
            },
          },
          LIST: {
            TABLE: {
              HEADERS: {
                NAME: 'NOMBRE',
                ALIAS: 'ALIAS',
                ACTIONS: 'ACCIONES',
              },
              ACTIONS: {
                EDIT: 'Editar',
                DELETE: 'Eliminar',
              },
              MORE: {
                NO_RESULTS: 'No hay carteras que mostrar',
              },
            },
          },
        },
        TAX_ENTITY: {
          TITLE: 'Entidades fiscales',
          PAGES: {
            ADD: {
              TITLE: 'Crear Entidades fiscal',
            },
            EDIT: {
              TITLE: 'Editar Entidades fiscal',
            },
          },
          LIST: {
            TABLE: {
              HEADERS: {
                SOCIETY: 'RAZÓN SOCIAL',
                NIF: 'NIF',
                COLLECTORS: 'PERFILES DE COBRO ASOCIADOS',
                ACTIONS: 'ACCIONES',
              },
              ACTIONS: {
                EDIT: 'Editar',
                DELETE: 'Eliminar',
              },
              MORE: {
                NO_RESULTS: 'No hay Entidades fiscales que mostrar',
              },
            },
          },
        },
        SERVICE: {
          TITLE: 'Servicos',
          CATEGORY: {
            advertising: 'Publicidad',
            energy: 'Energía',
            financial_services: 'Servicios financieros',
            insurance: 'Seguros',
            lawyers: 'Abogados',
            others: 'Otros',
            real_estate_services: 'Servicios inmobiliarios',
            telecommunications: 'Telecomunicaciones',
            training: 'Formación',
          },
          PAGES: {
            ADD: {
              TITLE: 'Crear Servico',
            },
            EDIT: {
              TITLE: 'Editar Servico',
            },
          },
          LIST: {
            TABLE: {
              HEADERS: {
                NAME: 'NOMBRE',
                CATEGORY: 'CATEGORÍA',
                COLLECTORS: 'PERFILES DE COBRO ASOCIADOS',
                ACTIONS: 'ACCIONES',
              },
              ACTIONS: {
                EDIT: 'Editar',
                DELETE: 'Eliminar',
              },
              MORE: {
                NO_RESULTS: 'No hay Servicos que mostrar',
              },
            },
          },
        },
        PARTIAL_PAYMENT_CONFIGS: {
          TITLE: 'Soluciones de pago',
          PAGES: {
            ADD: {
              TITLE: 'Crear configuración regional',
            },
            EDIT: {
              TITLE: 'Editar configuración regional',
            },
          },
          LIST: {
            TABLE: {
              HEADERS: {
                NAME: 'NOMBRE',
                COLLECTORS: 'PERFILES DE COBRO ASOCIADOS',
                ACTIONS: 'ACCIONES',
              },
              ACTIONS: {
                EDIT: 'Editar',
                DELETE: 'Eliminar',
              },
              MORE: {
                NO_COLLECTORS: 'No hay perfiles de cobro asociados',
                NO_RESULTS: 'No hay Configuración de pagos parciales que mostrar',
              },
            },
          },
        },
        EVENT_HOOKS: {
          TITLE: 'Compartir Datos',
          PAGES: {
            ADD: {
              TITLE: 'Crear método de seguimiento',
            },
            EDIT: {
              TITLE: 'Editar método de seguimiento',
            },
          },
          LIST: {
            TABLE: {
              HEADERS: {
                TYPE: 'TIPO',
                URL: 'SEGUIMIENTO',
                COLLECTORS: 'PERFILES DE COBRO ASOCIADOS',
                ACTIONS: 'ACCIONES',
                CONTEXT: 'CONTEXTOS',
              },
              ACTIONS: {
                EDIT: 'Editar',
                DELETE: 'Eliminar',
              },
              MORE: {
                NO_COLLECTORS: 'No hay perfiles de cobro asociados',
                NO_RESULTS: 'No hay métodos de seguimiento que mostrar',
              },
            },
          },
        },
        REGIONAL_CONFIGS: {
          TITLE: 'Configuración regional',
          PAGES: {
            ADD: {
              TITLE: 'Crear',
            },
            EDIT: {
              TITLE: 'Editar',
            },
          },
          LIST: {
            TABLE: {
              HEADERS: {
                NAME: 'NOMBRE',
                COLLECTORS: 'PERFILES DE COBRO ASOCIADOS',
                ACTIONS: 'ACCIONES',
              },
              ACTIONS: {
                CREATE: 'Configuracion regional',
                EDIT: 'Editar',
                DELETE: 'Eliminar',
              },
              MORE: {
                NO_COLLECTORS: 'No hay perfiles de cobro asociados',
                NO_RESULTS: 'No hay configuraciones regionales que mostrar',
              },
            },
          },
        },
        PAYMENT_METHODS: {
          TITLE: 'Métodos de pago',
          TYPES: {
            RedsysPaymentGateway: 'Redsys',
            PaycometPaymentGateway: 'Paycomet',
            IngenicoPaymentGateway: 'Ingenico',
            MymoidPaymentGateway: 'MyMoid',
            MoneticoPaymentGateway: 'Monetico',
            RedsysBizumPaymentGateway: 'Bizum (Redsys)',
            PaycometMbwayPaymentGateway: 'MBWAY (Paycomet)',
            PaycometBizumPaymentGateway: 'Bizum (Paycomet)',
            PaycometMultibancoSIBSPaymentGateway: 'Paycomet (Multibanco)',
            InespayPaymentGateway: 'Inespay',
            MoneiBizumPaymentGateway: 'Bizum (Monei)',
            StripePaymentGateway: 'Stripe',
            GreenPayPaymentGateway: 'GreenPay',
          },
          PAGES: {
            ADD: {
              TITLE: 'Crear',
            },
            EDIT: {
              TITLE: 'Editar',
            },
          },
          LIST: {
            TABLE: {
              HEADERS: {
                NAME: 'NOMBRE',
                TYPE: 'TIPO',
                DESCRIPTION: 'DESCRIPCIÓN',
                COLLECTORS: 'PERFILES DE COBRO ASOCIADOS',
                ACTIONS: 'ACCIONES',
              },
              ACTIONS: {
                CREATE: 'Añadir',
                EDIT: 'Editar',
                DELETE: 'Eliminar',
              },
              MORE: {
                NO_COLLECTORS: 'No hay perfiles de cobro asociados',
                NO_RESULTS: 'No hay métodos de pago que mostrar',
              },
            },
          },
        },
        COLLECTORS: {
          TITLE: 'Perfiles de cobro',
          PAGES: {},
          LIST: {
            TABLE: {
              HEADERS: {
                NAME: 'NOMBRE',
                ALIAS: 'ALIAS',
                ACTIONS: 'ACCIONES',
              },
              ACTIONS: {
                EDIT: 'Editar',
                DELETE: 'Eliminar',
                CREATE: 'Perfil de cobro',
              },
              MORE: {
                NO_RESULTS: 'No hay perfiles de cobro que mostrar',
              },
            },
          },
        },
        CHANNELS: {
          TITLE: 'Canales de notificación',
          ELEMENTS: {
            EmailChannelConfig: 'Email',
            SmsChannelConfig: 'SMS',
            CertifiedSmsChannelConfig: 'SMS certificado',
            CertifiedEmailChannelConfig: 'Email certificado',
          },
          PAGES: {
            ADD: {
              TITLE: 'Crear',
            },
            EDIT: {
              TITLE: 'Editar',
            },
          },
          LIST: {
            TABLE: {
              HEADERS: {
                NAME: 'NOMBRE',
                TYPE: 'TIPO',
                COLLECTORS: 'PERFILES DE COBRO ASOCIADOS',
                ACTIONS: 'ACCIONES',
              },
              ACTIONS: {
                EDIT: 'Editar',
                DELETE: 'Eliminar',
                CREATE: 'Perfil de cobro',
              },
              MORE: {
                NO_RESULTS: 'No hay canales de notificación que mostrar',
              },
            },
          },
        },
        FEEDBACK: {
          TITLE: 'Opciones de feedback',
          ELEMENTS: {},
          PAGES: {
            ADD: {
              TITLE: 'Crear',
            },
            EDIT: {
              TITLE: 'Editar',
            },
          },
          LIST: {
            TABLE: {
              HEADERS: {
                NAME: 'NOMBRE',
                CATEGORY: 'CATEGORÍA',
                TRANSLATE: 'TRADUCCIONES',
                COLLECTORS: 'PERFILES DE COBRO ASOCIADOS',
                ACTIONS: 'ACCIONES',
              },
              ACTIONS: {
                EDIT: 'Editar',
                DELETE: 'Eliminar',
                CREATE: 'Perfil de cobro',
              },
              MORE: {
                NO_RESULTS: 'No hay opciones de feedback que mostrar',
              },
            },
          },
        },
      },
    },
    MODULES: {
      LAYOUT: {
        ACTIVITY: {
          TITLE: 'Resumen de actividades',
          OPTIONS: {
            PAYLINK_NOTIFICATION_SENT: 'Notificaciones',
            PAYMENT_ORDER_CAPTURED: 'Pagos',
            FEEDBACK_SENT: 'Feedbacks',
          },
        },
      },
      SHARED: {
        COMPONENTS: {
          SEND_REMINDER: {
            TITLE: 'Enviar recordatorio',
            DESCRIPTION: 'Enviar recordatorio',
            PHONE_SEND_MSG: 'Enviarás un recordatorio al teléfono: ',
            EMAIL_SEND_MSG: 'Enviarás un recordatorio al email: ',
            FORM: {
              ACTIONS: {
                SEND: 'Enviar',
                CONFIRM: 'Confirmar',
                CONFIRM_MESSAGE: '¿Quieres enviar el recordatorio?',
              },
              CHANNEL: {
                LABEL: 'Seleccionar canales',
              },
            },
          },
          SEND_PAYMENT_AGREEMENT: {
            TITLE: 'Enviar acuerdo de pago',
            DESCRIPTION: 'Enviar acuerdo de pago',
            PHONE_SEND_MSG: 'Enviarás un acuerdo de pago al teléfono: ',
            EMAIL_SEND_MSG: 'Enviarás un acuerdo de pago al email: ',
            SUCCESS: 'Acuerdo de pago enviado correctamente',
            ERROR: 'Error al enviar el acuerdo de pago',
            HEADER_SUCCESS: 'Acción realizada',
            HEADER_ERROR: 'Error',
            FORM: {
              ACTIONS: {
                SEND: 'Enviar',
                CONFIRM: 'Confirmar',
                CONFIRM_MESSAGE: '¿Quieres enviar el acuerdo de pago?',
              },
              CHANNEL: {
                LABEL: 'Seleccionar canales',
                INSTALMENTS: 'Número de cuotas',
              },
            },
          },
        },
      },
      PROFILE: {
        TITLE: 'Información Personal',
        DESCRIPTION: 'Actualiza tus datos personales',
        FORM: {
          NAME: 'NOMBRE',
          SURNAME: 'APELLIDOS',
          EMAIL: 'EMAIL',
          ROLE: 'ROL',
        },
        TOAST: {
          SUCCESS: 'Usuario Actualizado',
          ERROR: 'Error al actualizar el usuario',
          ERROR_HEADER: 'Error',
          SUCCESS_HEADER: 'Acción realizada',
        },
      },
      LISTING: {
        TYPES: {
          ABANDONED_PAYMENTS: 'Abandonos_pasarela_pago_',
          NOTIFICATION: 'Notificaciones_',
          CONTACT_ERRORS: 'Errores_contactabilidad_',
          PAYMENTS: 'Pagos_',
        },
        LABELS: {
          ABANDONED_PAYMENT: 'Abandonos pasarela de pago',
          NOTIFICATION: 'Notificaciones',
          CONTACT_ERROR: 'Errores de contactabilidad',
          PAYMENTS: 'Pagos',
        },
        TITLE: 'Listados',
        GENERATE: {
          TITLE: 'Generar un listado',
          GENERATE: 'Generar listado',
          FORM: {
            COLLECTOR: 'PERFIL DE COBRO',
            TIME_RANGE: 'RANGO DE TIEMPO',
          },
        },
        TYPE: {
          ABANDONED_PAYMENT: {
            TITLE: 'Listados generados',
            LIST: {
              TABLE: {
                NO_RESULTS: 'No hay abandonos pasarela de pago que mostrar',
                HEADERS: {
                  ID: 'ID',
                  NAME: 'NOMBRE LISTADO',
                  DATE_CREATED: 'FECHA DE CREACIÓN',
                  TIME_RANGE: 'RANGO DE TIEMPO',
                  N_RESULTS: 'RESULTADOS',
                  STATUS: 'ESTADO',
                  COLLECTORS: 'PERFILES DE COBRO',
                  ACTIONS: 'ACCIONES',
                },
              },
            },
          },
          NOTIFICATION_LIST: {
            TITLE: 'Listados generados',
            LIST: {
              TABLE: {
                NO_RESULTS: 'No hay notificaciones que mostrar',
                HEADERS: {
                  ID: 'ID',
                  NAME: 'NOMBRE LISTADO',
                  DATE_CREATED: 'FECHA DE CREACIÓN',
                  TIME_RANGE: 'RANGO DE TIEMPO',
                  N_RESULTS: 'RESULTADOS',
                  STATUS: 'ESTADO',
                  COLLECTORS: 'PERFILES DE COBRO',
                  ACTIONS: 'ACCIONES',
                },
              },
            },
          },
          CONTACT_ERROR: {
            TITLE: 'Listados generados',
            LIST: {
              TABLE: {
                NO_RESULTS: 'No hay errores de contactabilidad que mostrar',
                HEADERS: {
                  ID: 'ID',
                  NAME: 'NOMBRE LISTADO',
                  DATE_CREATED: 'FECHA DE CREACIÓN',
                  TIME_RANGE: 'RANGO DE TIEMPO',
                  N_RESULTS: 'RESULTADOS',
                  STATUS: 'ESTADO',
                  COLLECTORS: 'PERFILES DE COBRO',
                  ACTIONS: 'ACCIONES',
                },
              },
            },
          },
        },
      },
      INSTANCES: {
        TITLE: 'Expedientes',
        ELEMENTS: {
          STATUS: {
            ACTIVE: 'Activo',
          },
        },
        LIST: {
          FORM: {
            SEARCH_TOOLTIP: `Busca por External ID de operación.

            También puedes buscar varios elementos separados por coma.

            Ejemplo: Op1234, Op1235, Op1236`,
          },
          TITLE: 'Listado de expedientes',
          TABLE: {
            NO_RESULTS: 'No hay expedientes que mostrar',
            NO_OPERATIONS: 'No hay operaciones que mostrar',
            HEADERS: {
              STATUS: 'ESTADO',
              CUSTOMER_NAME: 'NOMBRE CLIENTE',
              CUSTOMER: 'ID CLIENTE',
              COLLECTOR: 'PERFIL DE COBRO',
              PORTFOLIO: 'CARTERA',
              CAMPAIGN: 'CAMPAÑA',
              N_OPERATIONS: 'Nº DE OPERACIONES',
              TOTAL_AMOUNT: 'IMPORTE TOTAL',
              PENDING_AMOUNT: 'IMPORTE PENDIENTE',
              DATE: 'FECHA CREACIÓN',
              ACTIONS: 'ACCIONES',
              SUBHEADERS: {
                STATUS: 'ESTADO',
                ID_OPERATION: 'ID OPERACIÓN',
                TITLE: 'TÍTULO',
                AMOUNT: 'IMPORTE',
                DUE_DATE: 'FECHA DE VENCIMIENTO',
              },
            },
          },
        },
      },
      CAMPAINGS: {
        TITLE: 'Campañas',
        CAMPAING: 'Campaña',
        CAMPAING_NAME: 'Nombre de la campaña',
        CAMPAING_TYPE: 'Tipo de campaña',
        TYPE: {
          PILL: 'Píldora',
          DRIP: 'Continua',
        },
        STATUS: {
          ACTIVE: 'activa',
          PAUSED: 'pausada',
          DRAFT: 'borrador',
          PUBLISHED: 'publicada',
          UNPUBLISHED: 'archivada',
        },
        NEW: 'Nueva campaña',
        CLONE: {
          ACTIONS: {
            CLONE: 'Clonar',
          },
          FORM: {
            NAME: 'NUEVO NOMBRE',
          },
        },
        ELEMENTS: {
          NODES: {
            ACTIVE_CASES: {
              N_CASES: 'Nº de expedientes activos: {{count}}',
              NO_CASES: 'Sin expedientes activos',
            },
            TYPES: {
              EVENT: 'Evento',
              ACTIVITY: 'Actividad',
              CONDITION: 'Condición',
            },
            PARAMETERS: {
              PART_DAY: {
                OF: 'Envío durante: ',
                MORNING: 'Mañana',
                EVENING: 'Noche',
                FULL_DAY: 'Todo el día',
              },
              KEYS: {
                name: 'Nombre',
                waitDays: 'Días de espera',
                partDay: 'Parte del día',
                dayOfWeek: 'Día de la semana',
                dayOfMonth: 'Día del mes',
                daysForDueDate: 'Días para la fecha de vencimiento',
                copyIds: 'Copiar IDs',
                copyId: 'Copiar ID',
                targetNodeId: 'ID del nodo objetivo',
                statuses: 'Estados',
              },
              NAME: {
                start: 'Inicio',
                end: 'Fin',
                waitDays: 'Días de espera: ',
                waitDayOfWeek: 'Día de la semana: ',
                waitDayOfMonth: 'Día del mes: ',
                waitDaysForDueDate: 'Días para la fecha de vencimiento: ',
                sendSMS: 'Enviar SMS',
                sendEmail: 'Enviar Email',
                sendCertifiedSMS: 'Enviar SMS certificado',
                sendCertifiedEmail: 'Enviar Email certificado',
                superSendSMS: 'Enviar super SMS',
                superSendEmail: 'Enviar super Email',
                superSendCertifiedSMS: 'Enviar super SMS certificado',
                superSendCertifiedEmail: 'Enviar super Email certificado',
                paylinkNotificationStatus: 'Estado de la notificación',
                assignCampaign: 'Asignar campaña',
              },
            },
          },
        },
        LIST: {
          TITLE: 'Listado de campañas',
          NEW: 'Nueva campaña',
          EDIT: 'Editar campaña',
          TABLE: {
            TYPES: {
              ACTIVE: 'Activas',
              DRAFT: 'Borrador',
              UNPUBLISHED: 'Archivadas',
              PILLS: 'Píldoras',
            },
            TOOLTIPS: {
              SHOW_COLLECTORS: 'Ver perfiles de cobro',
            },
            HEADERS_PILLS: {
              NAME: 'NOMBRE DE LA PÍLDORA',
              PILLS_SEND: 'PÍLDORAS ENVIADAS',
              COLLECTORS: 'PERFIL DE COBRO',
              ACTIVE_CASES: 'CASOS ACTIVOS',
              CUSTOMER_CASES_COUNT: 'EXPEDIENTES ACTIVOS',
              ACTIONS: 'ACCIONES',
              SUBHEADERS: {
                CUSTOMER_CASES_NOTIFIED: 'EXPEDIENTES NOTIFICADOS',
                TOOLTIPS: {
                  CREATED:
                    'Las instancias están siendo creandas, este proceso puede tardar unos minutos.',
                },
                STATUS: 'ESTADO',
                TIME: 'FECHA Y HORA',
                CLIENT_NAME: 'NOMBRE DEL CLIENTE',
                N_INSTANCES: 'Nº DE EXPEDIENTES',
              },
            },
            HEADERS: {
              PRIORITY: 'PRIORIDAD',
              CAMPAIGN_NAME: 'NOMBRE DE LA CAMPAÑA',
              COLLECTORS: 'PERFIL DE COBRO',
              SEGMENTS: 'SEGMENTOS',
              ACTIVE_CASES: 'EXPEDIENTES ACTIVOS',
              STATUS: 'ESTADO',
              CUSTOMER_CASES_COUNT: 'EXPEDIENTES ACTIVOS',
              ACTIONS: 'ACCIONES',
            },
            MORE: {
              PRIORITY_TOOLTIP:
                'En el caso de que varias campañas continuas compartan segmentos la prioridad decidirá a qué campaña se asignan los nuevos expedientes. Si las campañas no comparten segmentos la prioridad no tiene efecto.',
              NO_RESULTS: 'No hay campañas que mostrar',
              NO_PILLS_RESULTS: 'No hay píldoras que mostrar',
              NO_PILLS_SUBTABLE_RESULTS: 'No hay instancias que mostrar',
            },
          },
        },
        WIZARD: {
          TITLE: 'Asistente de campañas',
          STEP_1: 'Paso 1',
          STEP_2: 'Paso 2',
          //...
        },
        MONITORING: {
          TITLE: 'Compartir Datos ',
          //...
        },
        EDIT: {
          TITLE: 'Editar campaña',
          //...
        },
      },
      COPIES: {
        TITLE: 'Plantillas',
        ELEMENTS: {},
        ADD: {
          TITLE: 'Crear plantilla',
          //...
        },
        EDIT: {
          TITLE: 'Editar plantilla',
          //...
        },
      },
      CUSTOMERS: {
        TITLE: 'Clientes',
        ELEMENTS: {},
        PAGES: {
          LIST: {
            TITLE: 'Listado de clientes',
          },
          CUSTOMER_LIST: {
            TITLE: 'Listado de clientes',
            NEW: 'Nuevo cliente',
            EDIT: 'Editar cliente',
            FORM: {
              NIF: 'DNI/NIE/NIF',
              EXTERNAL_ID: 'ID CLIENTE',
              COLLECTOR: 'PERFIL DE COBRO',
              SEARCH: 'BÚSQUEDA',
              SEARCH_TOOLTIP: `Busca entre ID Cliente, Nombre completo, DNI y datos de contacto (teléfono y correo electrónico) resultados parecidos.

              También puedes buscar varios elementos separados por coma.

              Ejemplo: María Garcia, Antonio Rodriguez, Carmen Gonzalez`,
            },
            TABLE: {
              HEADERS: {
                NAME: 'NOMBRE',
                DNI: 'DNI/NIE/NIF',
                EXTERNAL_ID: 'ID CLIENTE',
                COLLECTOR: 'PERFIL DE COBRO',
                TOTAL_AMOUNT: 'IMPORTE TOTAL',
                PENDING_AMOUNT: 'IMPORTE PENDIENTE',
                DATE: 'FECHA DE ACTUALIZACIÓN',
                ACTIONS: 'ACCIONES',
              },
              MORE: {
                NO_RESULTS: 'No hay clientes que mostrar',
              },
            },
          },
          CUSTOMER_FILE: {
            TITLE: 'Detalle cliente',
            SECTIONS: {
              SUPERIOR_INFO: {
                TOTAL_AMOUNT: 'Importe total',
                TOTAL_AMOUNT_TOOLTIP:
                  'Suma de todos los importes de las operaciones asociadas al cliente.',
                PENDING_AMOUNT: 'Importe pendiente',
                PENDING_AMOUNT_TOOLTIP:
                  'Suma de todos los importes pendientes de las operaciones asociadas al cliente.',
                AVERAGE_PAYMENT_TIME: 'Tiempo medio de pago',
                AVERAGE_PAYMENT_TIME_TOOLTIP:
                  'Tiempo medio entre la fecha de vencimiento y la fecha de pago de las operaciones asociadas al cliente.',
                CONTACTABILITY: 'Contactabilidad',
                NO_PAYMENTS: 'No hay pagos',
              },
              CONTACT: {
                NOTIFICABLE_PHONE: 'Este teléfono está siendo notificado',
                NOTIFICABLE_EMAIL: 'Este email está siendo notificado',
              },
              CASES: {
                TITLE: 'Expedientes',
                NO_CASES: 'No hay expedientes relacionados que mostrar',
                NO_OPERATIONS: 'No hay operaciones que mostrar',
                TABLE: {
                  HEADERS: {
                    STATUS: 'Estado',
                    PORTFOLIO: 'Cartera',
                    COLLECTOR: 'Perfil de cobro',
                    TOTAL_AMOUNT: 'Importe total',
                    PENDING_AMOUNT: 'Importe pendiente',
                  },
                  SUBHEADERS: {
                    STATUS: 'Estado',
                    ID_OPERATION: 'ID operación',
                    TITLE: 'Título',
                    AMOUNT: 'Importe',
                    DUE_DATE: 'Fecha de vencimiento',
                    COLLECTOR: 'Perfil de cobro',
                    ACTIONS: 'Acciones',
                  },
                },
              },
            },
          },
        },
      },
      OPERATIONS: {
        TITLE: 'Operaciones',
        ELEMENTS: {},
        COMPONENTS: {
          STATUS_CHANGE: {
            FORM: {
              LABEL: 'Cambiar estado',
            },
          },
        },
        PAGES: {
          LIST: {
            TITLE: 'Listado de operaciones',
          },
          OPERATION_LIST: {
            TITLE: 'Listado de operaciones',
            NEW: 'Nuevo operaciones',
            EDIT: 'Editar operaciones',
            FORM: {},
            TABLE: {
              HEADERS: {
                STATUS: 'ESTADO',
                COLLECTOR: 'PERFIL DE COBRO',
                OPERATION_ID: 'ID OPERACIÓN',
                TITLE: 'TÍTULO',
                DUE_DATE: 'VENCIMIENTO',
                CUSTOMER_ID: 'ID CLIENTE',
                CUSTOMER_NAME: 'NOMBRE CLIENTE',
                TOTAL_AMOUNT: 'IMP. TOTAL',
                PENDING_AMOUNT: 'IMP. PENDIENTE',
                PAYMENT_DATE: 'FECHA DE PAGO',
                PAYMENT_METHOD: 'MÉTODO DE PAGO',
                CREATION_DATE: 'FECHA CREACIÓN',
                UPDATE_DATE: 'FECHA ACTUALIZACIÓN',
                ACTIONS: 'ACCIONES',
              },
              MORE: {
                NO_RESULTS: 'No hay operaciones que mostrar',
              },
            },
          },

          OPERATIONS_FILE: {
            TITLE: 'Detalles operación',
          },
        },
      },
      IMPORT: {
        TITLE: 'Listado de importaciones',
        ELEMENTS: {},
        PAGES: {
          UPLOAD: {
            TITLE: 'Importar fichero',
          },
          LIST: {
            TITLE: 'Listado de importaciones',
          },
          IMPORT_LIST: {
            TITLE: 'Listado de importaciones',
            NEW: 'Nueva importación',
            EDIT: 'Editar importación',
            ELEMENTS: {
              IMPORT: 'Importar',
            },
            TABLE: {
              HEADERS: {
                CHARGE_DATE: 'FECHA DE CARGA',
                STATUS: 'ESTADO',
                TOTAL_AMOUNT: 'IMPORTE TOTAL',
                CHARGED_AMOUNT: 'IMPORTE CARGADO',
                OP_NUMBER: 'Nº DE OPERACIONES',
                CHARGED_OP_NUMBER: 'Nº DE OPERACIONES CARGADAS',
                FILE_NAME: 'NOMBRE DE ARCHIVO',
                ACTIONS: 'ACCIONES',
                ERRORS: 'ERRORES',
              },
              VALUES: {
                ERRORS_STRING: '<b>"{{errorMessage}}"</b> en la columna <b>"{{errorColumn}}"</b>',
                NO_ERRORS: 'Todos los registros son correctos',
                NO_ERROR: 'No hay errores',
              },
              MORE: {
                NO_RESULTS: 'No hay importaciones que mostrar',
              },
            },
          },
          EDIT: {
            TITLE: 'Revisa el formato',
            FILTER: {
              TITLE: 'Filtrar formato',
              ALL: 'Todos',
              VALID: 'Correctos',
              INVALID: 'Incorrectos',
            },
            TABLE: {
              HEADERS: {
                STATUS: 'FORMATO',
                NOMBRE_CORTO: 'NOMBRE CORTO',
                NOMBRE_COMPLETO: 'NOMBRE COMPLETO',
                MOVIL: 'MÓVIL',
                EMAIL: 'CORREO',
                ES_EMPRESA: 'ES EMPRESA',
                NIF: 'NIF',
                CODIGO_CLIENTE: 'CÓDIGO CLIENTE',
                CODIGO_POSTAL: 'CÓDIGO POSTAL',
                TITULO: 'TÍTULO',
                CONCEPTO: 'CONCEPTO',
                IMPORTE: 'IMPORTE',
                CODIGO_OPERACION: 'CÓDIGO OPERACIÓN',
                CODIGO_TRANSACCION: 'CÓDIGO TRANSACCIÓN',
                FECHA_LIMITE: 'FECHA LÍMITE',
                CODIGO_IMPAGO_DEVOLUCION_SEPA: 'CÓDIGO IMPAGO',
                IDIOMA: 'IDIOMA',
                DIRECCION: 'DIRECCIÓN',
                CIUDAD: 'CIUDAD',
                PAIS: 'PAÍS',
                ALIAS: 'ALIAS',
                URL_FACTURA: 'URL FACTURA',
                ESTADO: 'ESTADO',
              },
              CELL: {
                STATUS: {
                  VALID: 'Correcto',
                  INVALID: 'Error',
                },
              },
            },
            MORE: {
              NO_RESULTS: 'No hay líneas que mostrar',
              TIME_LIMIT:
                'Las líneas importadas tendrán una vigencia de 5 días, tras los cuales la información será eliminada.',
              LOADING_RESULTS: 'Cargando líneas...',
            },
          },
          END: {
            TITLE: 'Finalizar importación',
            DESCRIPTION: {
              TITLE: 'Resumen',
              OPERATIONS_NUMBER_TITLE: 'Número de operaciones a importar:',
              OPERATIONS_NUMBER: '{{operations}} de {{operations_total}}',
              TOTAL_IMPORT: 'Importe total: ',
            },
          },
        },
      },
      SEGMENTS: {
        TITLE: 'Segmentos',
        ELEMENTS: {},
        PAGES: {
          SEGMENT_LIST: {
            TITLE: 'Listado de segmentos',
            NEW: 'Nuevo segmento',
            EDIT: 'Editar segmento',
            FORM: {
              NAME: 'NOMBRE',
            },
            TABLE: {
              HEADERS: {
                NAME: 'NOMBRE',
                ACTIONS: 'ACCIONES',
              },
              ACTIONS: {
                EDIT: 'Editar',
                DELETE: 'Eliminar',
              },
              MORE: {
                NO_RESULTS: 'No hay segmentos que mostrar',
              },
            },
          },
        },
      },
      PAYMENT_AGREEMENT: {
        TITLE: 'Acuerdos de pago',
        ELEMENTS: {
          DOWNLOAD: {
            LABEL: 'Descargar',
          },
        },
        PAGES: {
          LIST: {
            TABLE: {
              HEADERS: {
                COLLECTOR: 'PERFIL DE COBRO',
                CHARGE_DATE: 'FECHA DE INICIO',
                STATUS: 'ESTADO',
                TOTAL_AMOUNT: 'IMPORTE TOTAL',
                CHARGED_AMOUNT: 'IMPORTE COBRADO',
                OP_NUMBER: 'NÚMERO DE OPERACIONES',
                CHARGED_OP_NUMBER: 'CUOTAS PAGADAS',
                FILE_NAME: 'COLLECTOR',
                EXTERNAL_CLIENT_CODE: 'CÓDIGO EXTERNO CLIENTE',
                CLIENT: 'CLIENTE',
                TITLE: 'TÍTULO',
              },

              MORE: {
                NO_RESULTS: 'No hay acuerdos de pago que mostrar',
              },
            },
          },
        },
      },
      REPORTS: {
        TITLE: 'Reportes',
        BC: {
          PANEL: 'Panel',
          CLIENTS: 'Clientes',
          CAMPAIGNS: 'Campañas',
          COLLECTION: 'Recobro por periodo',
          COLLECTORS: 'Perfiles de cobro',
          WORKFLOWS: 'Recobro por mes',
          PORTFOLIOS: 'Cartera global',
        },
        FILTERS: {
          CAMPAIGNS: 'CAMPAÑAS',
          COLLECTORS: 'PERFIL DE COBRO',
          PORTFOLIOS: 'CARTERAS',
          DATE: 'FECHA',
          DATE_COLLECTION_CREATED: 'MES CREACIÓN DEUDA',
          PERIOD: 'PERIODO DE RECOBRO (EN MESES)',
          CAMPAIGN_PAYMENTS: 'RECUPERACIÓN POR CAMPAÑA',
          STATUS_OPERATION_IGNORE: 'IGNORAR ESTADO OPERACIÓN',
        },
        PAGES: {
          ROOT: {
            DESCRIPTIONS: {
              PANEL: 'Informes de Tenant',
              CLIENTS: 'Informes de clientes',
              CAMPAIGNS: 'Informes de campañas',
              COLLECTORS: 'Informes de perfiles de cobro',
              COLLECTION: 'Informes de recobro por periodo',
              WORKFLOWS: 'Informes de recobro por mes',
              PORTFOLIOS: 'Informes de cartera global',
            },
          },
        },
      },
      PAYMENT_METHOD: {
        FORM: {
          INPUT: {
            PAYMENT_METHOD: 'Método de pago',
          },
        },
        ADD: {
          TITLE: 'Nuevo método de pago',
          BUTTON: {
            BACK: 'Atrás',
          },
        },
        EDIT: {
          TITLE: 'Editar método de pago',
          ENABLED: 'Habilitado',
          BUTTON: {
            BACK: 'Atrás',
          },
        },
        LIST: {
          TITLE: 'Métodos de pago',
          BUTTON: {
            ADD: 'Nuevo método de pago',
          },
          INPUT: {
            SEARCH: 'Buscar',
          },
          TABLE: {
            HEADER: {
              NAME: 'Nombre',
              DESCRIPTION: 'Descripción',
              TYPE: 'Tipo',
              STATUS: 'Estado',
              ACTIONS: 'Acciones',
              COLLECTORS: 'Collectors asociados',
            },
            VALUES: {
              ENABLED: 'Activo',
              DISABLED: 'Inactivo',
            },
            BUTTON: {
              EDIT: 'Editar',
              DELETE: 'Eliminar',
            },
          },
        },
        REDSYS: {
          FORM: {
            INPUT: {
              NAME: 'Nombre',
              DESCRIPTION: 'Descripción',
              TYPE: 'Tipo',
              STATUS: 'Estado',
              ACTIONS: 'Acciones',
              MERCHANT_CODE: 'Merchant code',
              MERCHANT_TERMINAL_ID: 'Merchant terminal id',
              MERCHANT_SECRET: 'Merchant secret',
              CUSTOM_CONCEPT: 'Concepto personalizado',
              TEXT: {
                MESSAGE:
                  'Utiliza estas etiquetas en el "Texto a mostrar" para personalizar tus mensajes:',
                LABELS: {
                  NAME: 'Nombre largo',
                  CLIENT_ID: 'ID del cliente',
                  NIF: 'NIF',
                  TITLE: 'Título',
                  OPERATION_ID: 'ID operación',
                  PERSONALIZADO: '$PERSONALIZADO$',
                },
                INFO: `
                <section>
                <p>Detalles Adicionales sobre Conceptos Personalizados de Pago</p>
                <p>Al configurar tus conceptos personalizados de pago, ten en cuenta lo siguiente:</p>
                <ul>
                    <li>Si utilizas la variable opcional <code>$PERSONALIZADO$</code>, debes asegurarte de incluir esta información en Paymefy. De lo contrario, la información no se imprimirá en el concepto.</li>
                    <li>En caso de multifactura, todos los códigos aparecerán separados por comas (<code>,</code>).</li>
                    <li>Concepto por defecto: <strong>título</strong>.</li>
                    <li>Límite de caracteres: <strong>125</strong>. Si se supera, el concepto será recortado.</li>
                    <li>Caracteres permitidos:
                        <ul>
                            <li>Caracteres de la <code>a</code> a la <code>z</code> (asegúrate de no incluir caracteres especiales o mayúsculas).</li>
                            <li>Números del <code>0</code> al <code>9</code>.</li>
                        </ul>
                    </li>
                </ul>
            </section>`,
                EXTRA: {
                  BIZUM: 'Bizum (Redsys)',
                },
              },
            },
            BUTTON: {
              CANCEL: 'Cancelar',
              SAVE: 'Guardar',
            },
          },
        },
        INESPAY: {
          FORM: {
            INPUT: {
              NAME: 'Nombre',
              DESCRIPTION: 'Descripción',
              COMPANY_TYPE: 'Tipo',
              ACTIONS: 'Acciones',
              IBAN: 'IBAN',
              API_KEY: 'API KEY',
              API_TOKEN: 'API TOKEN',
              CUSTOM_CONCEPT: 'Concepto personalizado',
              TEXT: {
                MESSAGE:
                  'Utiliza estas etiquetas en el "Texto a mostrar" para personalizar tus mensajes:',
                LABELS: {
                  NAME: 'Nombre largo',
                  CLIENT_ID: 'ID del cliente',
                  NIF: 'NIF',
                  TITLE: 'Título',
                  OPERATION_ID: 'ID operación',
                  PERSONALIZADO: '$PERSONALIZADO$',
                },
                INFO: `
                <section>
        <p>Información Actualizada sobre Conceptos Personalizados de Pago</p>
        <p>Considera las siguientes directrices al configurar tus conceptos personalizados de pago en Paymefy:</p>
        <ul>
            <li>Si utilizas <strong>variables opcionales</strong>, debes asegurarte de incluir esta información en Paymefy. De lo contrario, la información no se imprimirá en el concepto.</li>
            <li>Concepto por defecto: <em>iban de cuenta origen, external_id, nombre largo</em>.</li>
            <li>En caso de multifactura, todos los códigos aparecen separados por comas (<code>,</code>).</li>
            <li>Límite de caracteres: <strong>100</strong>. Si se supera, el concepto será recortado.</li>
            <li>Caracteres permitidos:
                <ul>
                    <li>Caracteres de la <code>a</code> a la <code>z</code>.</li>
                    <li>Números del <code>0</code> al <code>9</code>.</li>
                    <li>Otros caracteres permitidos: <code>+</code>, <code>?</code>, <code>/</code>, <code>-</code>, <code>:</code>, <code>(</code>, <code>)</code>, <code>.</code>, <code>,</code>, <code>'</code>.</li>
                </ul>
            </li>
        </ul>
    </section>
                `,
              },
            },
            BUTTON: {
              CANCEL: 'Cancelar',
              SAVE: 'Guardar',
            },
          },
        },
        STRIPE: {
          FORM: {
            INPUT: {
              NAME: 'Nombre',
              DESCRIPTION: 'Descripción',
              COMPANY_TYPE: 'Tipo',
              ACTIONS: 'Acciones',
              SECRET_KEY: 'Secret key',
              PUBLISHABLE_KEY: 'Publishable key',
              WEB_HOOK_SECRET: 'Web hook secret',
              CUSTOM_CONCEPT: 'Concepto personalizado',
              TEXT: {
                MESSAGE:
                  'Utiliza estas etiquetas en el "Texto a mostrar" para personalizar tus mensajes:',
                LABELS: {
                  NAME: 'Nombre largo',
                  CLIENT_ID: 'ID del cliente',
                  NIF: 'NIF',
                  TITLE: 'Título',
                  OPERATION_ID: 'ID operación',
                  PERSONALIZADO: '$PERSONALIZADO$',
                },
                INFO: `<section>
                <p>Información sobre Conceptos Personalizados de Pago</p>
                <p>Los conceptos personalizados de pago deben cumplir con las siguientes normativas:</p>
                <ul>
                    <li>Deben contener <strong>solo caracteres latinos</strong>.</li>
                    <li>La longitud debe ser de <strong>entre 5 y 22 caracteres</strong>, incluyendo ambos extremos.</li>
                    <li>Deben contener <strong>al menos una letra</strong>. Si se usa un prefijo y un sufijo, ambos deben contener al menos una letra.</li>
                    <li><strong>No deben contener</strong> ninguno de los siguientes caracteres especiales: <code>&lt;</code>, <code>&gt;</code>, <code>V</code>, <code>*</code>, <code>"</code>.</li>
                </ul>
            </section>`,
              },
            },
            BUTTON: {
              CANCEL: 'Cancelar',
              SAVE: 'Guardar',
            },
          },
        },
        PAYCOMET: {
          FORM: {
            INPUT: {
              NAME: 'Nombre',
              DESCRIPTION: 'Descripción',
              COMPANY_TYPE: 'Tipo',
              ACTIONS: 'Acciones',
              IBAN: 'IBAN',
              SWIFT_CODE: 'Código SWIFT',
              CUSTOM_CONCEPT: 'Concepto personalizado',
              TEXT: {
                MESSAGE:
                  'Utiliza estas etiquetas en el "Texto a mostrar" para personalizar tus mensajes:',
                LABELS: {
                  NAME: 'Nombre largo',
                  CLIENT_ID: 'ID del cliente',
                  NIF: 'NIF',
                  TITLE: 'Título',
                  OPERATION_ID: 'ID operación',
                  PERSONALIZADO: '$PERSONALIZADO$',
                },
                INFO: `<section>
                <p>Instrucciones para Conceptos Personalizados de Pago</p>
                <p>Al configurar conceptos personalizados de pago en Paymefy, ten en cuenta las siguientes directrices:</p>
                <ul>
                    <li>Para el uso de <strong>variables opcionales</strong>, es necesario incluir esta información en Paymefy. Si no se incluye, la información correspondiente no se mostrará en el concepto de pago.</li>
                    <li>El <strong>concepto por defecto</strong> se compone de: iban de cuenta origen, external_id, nombre largo.</li>
                    <li>En situaciones de <strong>multifactura</strong>, todos los códigos se listarán separados por comas (<code>,</code>).</li>
                    <li>Existe un <strong>límite de caracteres de 100</strong>. Si el concepto excede este límite, será necesario recortarlo.</li>
                    <li>Los <strong>caracteres permitidos</strong> incluyen:
                        <ul>
                            <li>Caracteres alfabéticos de la <code>a</code> a la <code>z</code>.</li>
                            <li>Números del <code>0</code> al <code>9</code>.</li>
                            <li>Además, se permiten los siguientes caracteres especiales: <code>+</code>, <code>?</code>, <code>/</code>, <code>-</code>, <code>:</code>, <code>(</code>, <code>)</code>, <code>.</code>, <code>,</code>, <code>'</code>.</li>
                        </ul>
                    </li>
                </ul>
            </section>`,
              },
            },
            BUTTON: {
              CANCEL: 'Cancelar',
              SAVE: 'Guardar',
            },
          },
        },
        INGENICO: {
          FORM: {
            INPUT: {
              NAME: 'Nombre',
              DESCRIPTION: 'Descripción',
              PSPID: 'Pspid',
              INTEGRATOR: 'Integrator',
              API_KEY: 'Api key',
              API_SECRET: 'Api secret',
              WEBHOOKS_KEY: 'Webhooks key',
              WEBHOOKS_KEY_SECRET: 'Webhooks key secret',
              CUSTOM_CONCEPT: 'Concepto personalizado',
              TEXT: {
                MESSAGE:
                  'Utiliza estas etiquetas en el "Texto a mostrar" para personalizar tus mensajes:',
                LABELS: {
                  NAME: 'Nombre largo',
                  CLIENT_ID: 'ID del cliente',
                  NIF: 'NIF',
                  TITLE: 'Título',
                  OPERATION_ID: 'ID operación',
                  PERSONALIZADO: '$PERSONALIZADO$',
                },
                INFO: ``,
              },
            },
            BUTTON: {
              CANCEL: 'Cancelar',
              SAVE: 'Guardar',
            },
          },
        },
        MYMOID: {
          FORM: {
            INPUT: {
              NAME: 'Nombre',
              DESCRIPTION: 'Descripción',
              APP_ID: 'App id',
              APP_SECRET: 'App secret',
              CUSTOM_CONCEPT: 'Concepto personalizado',
              TEXT: {
                MESSAGE:
                  'Utiliza estas etiquetas en el "Texto a mostrar" para personalizar tus mensajes:',
                LABELS: {
                  NAME: 'Nombre largo',
                  CLIENT_ID: 'ID del cliente',
                  NIF: 'NIF',
                  TITLE: 'Título',
                  OPERATION_ID: 'ID operación',
                  PERSONALIZADO: '$PERSONALIZADO$',
                },
                INFO: `
                <section>
                <p>Configuración de Conceptos Personalizados de Pago</p>
                <p>Al definir conceptos personalizados de pago para su uso en Paymefy, considera las siguientes directrices:</p>
                <ul>
                    <li>Al usar la variable opcional <code>$PERSONALIZADO$</code>, es crucial asegurarse de que esta información se haya ingresado en Paymefy; de lo contrario, no aparecerá en el concepto.</li>
                    <li>Para casos de <strong>multifactura</strong>, todos los códigos se mostrarán separados por comas (<code>,</code>).</li>
                    <li><strong>Concepto por defecto:</strong> No existe un concepto por defecto.</li>
                    <li>El <strong>límite de caracteres</strong> es de <strong>150</strong>. Si se supera este límite, el concepto será recortado.</li>
                    <li><strong>Mymoid</strong> no impone restricciones sobre los caracteres utilizados en los conceptos de pago.</li>
                </ul>
            </section>
        </body>`,
              },
            },
            BUTTON: {
              CANCEL: 'Cancelar',
              SAVE: 'Guardar',
            },
          },
        },
        MONETICO: {
          FORM: {
            INPUT: {
              NAME: 'Nombre',
              DESCRIPTION: 'Descripción',
              SOCIETE: 'Nombre empresa',
              TPE: 'Nº TPE',
              KEY: 'KEY',
              DEFAULT_ADDRESS: 'Dirección',
              DEFAULT_CITY: 'Ciudad',
              DEFAULT_POSTAL_CODE: 'Código postal',
              DEFAULT_COUNTRY: 'Código de país',
              TEXT: {
                MESSAGE:
                  'Utiliza estas etiquetas en el "Texto a mostrar" para personalizar tus mensajes:',
                LABELS: {
                  NAME: 'Nombre largo',
                  CLIENT_ID: 'ID del cliente',
                  NIF: 'NIF',
                  TITLE: 'Título',
                  OPERATION_ID: 'ID operación',
                  PERSONALIZADO: '$PERSONALIZADO$',
                },
                INFO: '',
              },
            },
            BUTTON: {
              CANCEL: 'Cancelar',
              SAVE: 'Guardar',
            },
          },
        },
        MONEI: {
          FORM: {
            INPUT: {
              NAME: 'Nombre',
              DESCRIPTION: 'Descripción',
              TYPE: 'Tipo',
              STATUS: 'Estado',
              ACTIONS: 'Acciones',
              MERCHANT_CODE: 'Merchant code',
              MERCHANT_TERMINAL_ID: 'Merchant terminal id',
              MERCHANT_SECRET: 'Merchant secret',
              CUSTOM_CONCEPT: 'Concepto personalizado',
              TEXT: {
                MESSAGE:
                  'Utiliza estas etiquetas en el "Texto a mostrar" para personalizar tus mensajes:',
                LABELS: {
                  NAME: 'Nombre largo',
                  CLIENT_ID: 'ID del cliente',
                  NIF: 'NIF',
                  TITLE: 'Título',
                  OPERATION_ID: 'ID operación',
                  PERSONALIZADO: '$PERSONALIZADO$',
                },
              },
            },
            BUTTON: {
              CANCEL: 'Cancelar',
              SAVE: 'Guardar',
            },
          },
        },
        GREENPAY: {
          FORM: {
            INPUT: {
              NAME: 'Nombre',
              DESCRIPTION: 'Descripción',
              API_KEY: 'API KEY',
              ACCOUNT_ID: 'ACCOUNT ID',
              CUSTOM_CONCEPT: 'Concepto personalizado',
              TEXT: {
                MESSAGE:
                  'Utiliza estas etiquetas en el "Texto a mostrar" para personalizar tus mensajes:',
                LABELS: {
                  NAME: 'Nombre largo',
                  CLIENT_ID: 'ID del cliente',
                  NIF: 'NIF',
                  TITLE: 'Título',
                  OPERATION_ID: 'ID operación',
                  PERSONALIZADO: '$PERSONALIZADO$',
                },
                INFO: '',
              },
            },
            BUTTON: {
              CANCEL: 'Cancelar',
              SAVE: 'Guardar',
            },
          },
        },
      },
    },
  },
};
