<div class="button-container">
    <p-button
        (onClick)="changeZoomManual()"
        [pTooltip]="isZoomable ? 'Bloquear zoom' : 'Desbloquear zoom'"
        [tooltipPosition]="'bottom'"
        [iconPos]="'left'"
        [icon]="isZoomable ? 'pi pi-lock-open' : 'pi pi-lock'"
    ></p-button>
    <p-button
        (onClick)="center()"
        [pTooltip]="'Centrar Nodos'"
        [tooltipPosition]="'bottom'"
        [iconPos]="'left'"
        [icon]="'pi pi-window-maximize'"
    ></p-button>
    <p-button
        (onClick)="download()"
        [pTooltip]="'Descargar imagen'"
        [tooltipPosition]="'bottom'"
        [iconPos]="'left'"
        [icon]="'pi pi-download'"
    ></p-button>
</div>
