import { Component, Input, OnInit } from '@angular/core';
import { ToastService } from '@core/services/ui';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import { SendProposePaymentAgreementModalComponent } from './send-propose-payment-agreement-modal/send-propose-payment-agreement-modal.component';
import { PaymentAgreementService } from '@modules/payment-agreement/services/payment-agreement.service';
import { CustomerCase } from '@models/customer.model';
import { CustomerService } from '@modules/customers/customer.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-send-propose-payment-agreement',
  templateUrl: './send-propose-payment-agreement.component.html',
})
export class SendProposePaymentAgreementComponent implements OnInit {
  @Input() customerId: string = '';
  @Input() case: CustomerCase = {} as CustomerCase;

  isButtonDisabled: boolean = false;

  constructor(
    private dialogService: DialogService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private paymentAgreementService: PaymentAgreementService,
  ) {}

  ngOnInit() {
    this.isButtonDisabled = !this.check();
  }

  openSendProposePaymentAgreementModal() {
    const ref = this.dialogService.open(SendProposePaymentAgreementModalComponent, {
      data: this.case,
      width: '50vw',
      height: '70vh',
      closeOnEscape: true,
      dismissableMask: true,
      draggable: true,
      header: this.translateService.instant(
        'DATA.MODULES.SHARED.COMPONENTS.SEND_PAYMENT_AGREEMENT.TITLE',
      ),
      resizable: false,
    });

    ref.onClose.subscribe((data) => {
      if (data) {
        this.handleNotificationActions(data);
      }
    });
  }

  check() {
    return (
      !this.case?.validation || this.case?.status === 'closed' || this.case?.status === 'agreement'
    );
  }

  handleNotificationActions(data: any): void {
    this.paymentAgreementService
      .sendProposePaymentAgreement(data?.customerCase?.id || data?.customerCase?.customerCaseId, {
        channel: data?.channel,
        nMonths: data.nMonths,
      })
      .subscribe((res) => {
        if (res.data) {
          this.toastService.showSuccess(
            this.translateService.instant(
              'DATA.MODULES.SHARED.COMPONENTS.SEND_PAYMENT_AGREEMENT.HEADER_SUCCESS',
            ),
            this.translateService.instant(
              'DATA.MODULES.SHARED.COMPONENTS.SEND_PAYMENT_AGREEMENT.SUCCESS',
            ),
          );
        } else {
          this.toastService.showError(
            this.translateService.instant(
              'DATA.MODULES.SHARED.COMPONENTS.SEND_PAYMENT_AGREEMENT.HEADER_ERROR',
            ),
            this.translateService.instant(
              'DATA.MODULES.SHARED.COMPONENTS.SEND_PAYMENT_AGREEMENT.ERROR',
            ) + `: ${res.errors[0].message}`,
          );
        }
      });
  }
}
