import { FormDropdown } from '../../dynamic-form/models/form-dropdown';

export const reminderChannelForm = [
  new FormDropdown({
    key: 'channel',
    type: 'dropdown',
    required: true,
    label: 'DATA.MODULES.SHARED.COMPONENTS.SEND_REMINDER.FORM.CHANNEL.LABEL',
    options: [],
    multi: true,
    readonly: false,
  }),
];
