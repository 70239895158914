import { gql } from 'apollo-angular';

export const GET_CUSTOMER = gql`
  query ($id: ID!) {
    customer(id: $id) {
      id
      externalId
      tenantId
      name
      longName
      emailAddresses
      phones
      language
      location {
        country
        city
        zipCode
        address
      }
      gender
      isCompany
      nif
      birthDate
    }
  }
`;

export const GET_CUSTOMER_INFO = gql`
  query GetCustomerInfo($customerId: ID!) {
    customer(id: $customerId) {
      totalAmount
      pendingAmount
      averagePaymentTime
      tags
      contactabilityScoring
    }
  }
`;

export const GET_CUSTOMER_BASIC_INFO = gql`
  query GetBasicInfo($customerId: ID!) {
    customer(id: $customerId) {
      id
      shortName
      fullName
      dniNieCif
      ownership
      birthDate
      gender
      contactLanguage
    }
  }
`;

export const SEND_EMAIL_USAGE = gql`
  mutation ($input: SendEmailPaylinkNotificationUsageInput!) {
    sendEmailPaylinkNotificationUsage(input: $input) {
      status
    }
  }
`;

export const SEND_SMS_USAGE = gql`
  mutation ($input: SendSmsPaylinkNotificationUsageInput!) {
    sendSmsPaylinkNotificationUsage(input: $input) {
      status
    }
  }
`;

export const UPDATE_CUSTOMER_BASIC_INFO = gql`
  mutation ($id: ID!, $input: UpdateCustomerInput!) {
    updateCustomer(id: $id, input: $input) {
      id
      externalId
      tenantId
      name
      longName
      language
      gender
      isCompany
      nif
      birthDate
    }
  }
`;

export const GET_CUSTOMER_LOCATION_INFO = gql`
  query GetLocationInfo($customerId: ID!) {
    customer(id: $customerId) {
      id
      address
      postalCode
      city
      country
      tenantCountry
    }
  }
`;

export const UPDATE_CUSTOMER_LOCATION_INFO = gql`
  mutation UpdateLocationInfo($customerId: ID!, $input: LocationInfoInput!) {
    updateLocationInfo(customerId: $customerId, input: $input) {
      success
    }
  }
`;

export const GET_CUSTOMERS = gql`
  query ($criteria: SearchInCustomerListCriteria!) {
    searchInCustomerList(criteria: $criteria) {
      totalResults
      results {
        id
        externalId
        longName
        nif
        phones
        emailAddresses
        pendingAmount {
          amount
          currency
          formattedAmount
        }
        totalAmount {
          amount
          currency
          formattedAmount
        }
        inAllegationsAmount {
          amount
          currency
          formattedAmount
        }
        collectors {
          id
          name
          pendingAmount {
            amount
            currency
            formattedAmount
          }
          totalAmount {
            amount
            currency
            formattedAmount
          }
          inAllegationsAmount {
            amount
            currency
            formattedAmount
          }
        }
        updatedAt
      }
    }
  }
`;

export const GET_CUSTOMER_KPIS = gql`
  query ($customerId: ID!) {
    customerKPIs(customerId: $customerId) {
      customerId
      totalAmount
      pendingAmount
      contactabilityScore
      averagePaymentTime
    }
  }
`;

export const GET_CUSTOMER_CASES = gql`
  query ($tenantId: ID!, $customerId: ID!) {
    customerCases(tenantId: $tenantId, customerId: $customerId) {
      id
      name
      customerId
      status
      collector {
        id
        name
      }
      operations {
        id
        title
        amount {
          amount
          currency
          formattedAmount
        }
        status
        dueDate
        externalId
      }
      totalAmount {
        amount
        currency
        formattedAmount
      }
      pendingAmount {
        amount
        currency
        formattedAmount
      }
      inAllegationsAmount {
        amount
        currency
        formattedAmount
      }
    }
  }
`;

export const DELETE_CUSTOMERS = gql`
  mutation ($ids: [ID!]!) {
    deleteCustomers(ids: $ids) {
      success
      failed
    }
  }
`;

export const DELETE_CUSTOMERS_BY_FILTER = gql`
  mutation ($input: DeleteCustomersByFilterInput!) {
    deleteCustomersByFilter(input: $input) {
      success
      failed
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation ($id: ID!, $input: UpdateCustomerInput!) {
    updateCustomer(id: $id, input: $input) {
      id
      externalId
      tenantId
      name
      longName
      emailAddresses
      phones
      language
      location {
        country
        city
        zipCode
        address
      }
      gender
      isCompany
      nif
      birthDate
    }
  }
`;

export const VALIDATE_DEPENDENCIES = gql`
  query ($customerCaseId: ID!) {
    validatePaymentAgreementsDependencies(customerCaseId: $customerCaseId)
  }
`;

export const CALCULATE_INSTALMENTS = gql`
  query ($customerCaseId: ID!, $numberOfInstalments: Int!) {
    calculatePaymentAgreementInstalments(
      customerCaseId: $customerCaseId
      numberOfInstalments: $numberOfInstalments
    ) {
      amount {
        amount
        currency
      }
    }
  }
`;

export const GET_CUSTOMER_PAYMENTS = gql`
  query ($criteria: CustomerPaymentsCriteria!) {
    customerPayments(criteria: $criteria) {
      id
      customerId
      amount {
        amount
        currency
        formattedAmount
      }
      method
      paymentDate
      operations {
        id
        attributedAmount {
          amount
          currency
          formattedAmount
        }
      }
    }
  }
`;

export const GET_OPERATIONS_BY_CUSTOMER = gql`
  query ($criteria: CustomerOperationsCriteria!) {
    customerOperations(criteria: $criteria) {
      id
      externalId
      collectorId
      title
      status
      amount {
        amount
        currency
        formattedAmount
      }
      pendingAmount {
        amount
        currency
        formattedAmount
      }
      dueDate
      comment
      description
      items {
        concept
        amount {
          amount
          currency
          formattedAmount
        }
      }
      attachmentUrl
      paymentIds
    }
  }
`;

export const GET_CUSTOMER_ACTIVITY = gql`
  query ($criteria: SearchCustomerInteractionsCriteria!) {
    searchCustomerInteractions(criteria: $criteria) {
      name
      category
      parameters
      dateTime
    }
  }
`;

export const SEND_PROPOSE_PAYMENT_AGREEMENT = gql`
  mutation ($input: ProposePaymentAgreementInput!) {
    proposePaymentAgreement(input: $input) {
      id
      tenantId
      customerCaseId
      status
      amount {
        amount
        currency
      }
      paymentGatewayId
      signatureDate
      nextPaymentDate
      fulfilmentDate
      breakDate
      breakReason
      instalments {
        amount {
          amount
          currency
        }
        attempts
        status
      }
    }
  }
`;

export const CREATE_PARTIAL_PAYMENT_OFFER = gql`
  mutation ($input: CreatePartialPaymentOfferInput!) {
    createPartialPaymentOffer(input: $input)
  }
`;
