<p-sidebar
   [(visible)]="visible"
   position="right"
   [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'"
   styleClass="layout-profile-sidebar w-full sm:w-25rem"
>
   <div class="flex flex-column mx-auto md:mx-0">
      <app-custom-tag [value]="_user.role" class="mb-3"></app-custom-tag>
      <div class="flex flex-column">
         <span class="font-semibold">Hola,</span>
         <span class="text-color-secondary font-medium">{{ _user.name }}</span>
      </div>
      <div class="flex flex-column gap-2 mb-3"></div>

      <ul class="list-none m-0 p-0">
         <li (click)="navigate('/profile')">
            <a
               class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150"
            >
               <span>
                  <i class="pi pi-user text-xl text-primary"></i>
               </span>
               <div class="ml-3">
                  <span class="mb-2 font-semibold">Perfil</span>
               </div>
            </a>
         </li>

         <li (click)="showDialogTimezone()">
            <a
               class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150"
            >
               <span>
                  <i class="pi pi-hourglass text-xl text-primary"></i>
               </span>
               <div class="ml-3">
                  <span class="mb-2 font-semibold">{{ oldSelectedTimezone }}</span>
               </div>
            </a>
         </li>


         <li (click)="signOut()">
            <a
               class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150"
            >
               <span>
                  <i class="pi pi-power-off text-xl text-primary"></i>
               </span>
               <div class="ml-3">
                  <span class="mb-2 font-semibold">Cerrar sesión</span>
               </div>
            </a>
         </li>
      </ul>
   </div>
</p-sidebar>
