import { NODES_TYPES_ALLOWEDS, mapColorsNode } from '../config/constants';
import { WorkflowClass } from '@modules/campaigns/Domain/WorkflowClass';
export class NodesValidator {
  private checkListProperties: Record<string, string[]> = NODES_TYPES_ALLOWEDS;

  isValidValue(value: any): boolean {
    if (value === undefined) return false;
    if (value === null || typeof value === 'undefined') return false;
    if (typeof value === 'boolean') return true;
    if (value === '') return false;
    if (Array.isArray(value) && value.length === 0) return false;
    return true;
  }

  arePropertiesValid(node: any): boolean {
    const attributes = node.attributes;

    if (attributes && this.checkListProperties.hasOwnProperty(attributes['data'].name)) {
      return this.checkListProperties[attributes['data'].name].every((property) =>
        this.isValidValue(attributes['data'][property]),
      );
    }
    return false;
  }

  isNodeConditionalValid(node: any): boolean {
    if (!node || !node.parameters || !node.parameters.name) return false;

    const nodeName = node.parameters.name;
    if (nodeName === 'end') {
      return node.childrenIds.length === 0;
    } else if (nodeName === 'start') {
      return node.childrenIds.length > 0;
    } else {
      return true;
    }
  }

  areNodesValid(nodes: any[]): boolean {
    let endFound = false;
    let startFound = false;

    for (let node of nodes) {
      if (!this.arePropertiesValid(node.parameters)) return false;
      if (!this.isNodeConditionalValid(node)) return false;

      endFound = endFound || node.parameters.name === 'end';
      startFound = startFound || node.parameters.name === 'start';
    }

    return endFound && startFound;
  }

  showError(node: any): void {
    node.attr('rect/stroke', 'red');
    node.attr('rect/strokeWidth', 5);
    node.attr({
      body: {
        strokeDasharray: '0',
        stroke: 'red',
        strokeWidth: 5,
      },
    });
  }

  hideError(node: any, workflow: WorkflowClass): void {
    const nodeName = (workflow as WorkflowClass).findNode(node.attributes['data'].original)?.type;
    node.attr({
      body: {
        strokeDasharray: '0',
        stroke: mapColorsNode[nodeName!],
        strokeWidth: 5,
      },
      rect: {
        stroke: mapColorsNode[nodeName!],
        strokeWidth: 5,
      },
    });
  }
}
