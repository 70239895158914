import { GraphQLError } from 'graphql';

export enum ReportTypes {
  BASE = 'BASE',
  PANEL = 'PANEL',
  CAMPAIGNS = 'CAMPAIGNS',
  CLIENTS = 'CLIENTS',
  COLLECTION = 'COLLECTION',
  PORTFOLIOS = 'PORTFOLIOS',
  COLLECTORS = 'COLLECTORS',
  COLLECTION_BY_PERIOD = 'COLLECTION_BY_PERIOD',
  CAMPAIGNS_BY_MONTH = 'CAMPAIGNS_BY_MONTH',
}

export interface DashboardUrl {
  tenantId: string;
  type: string;
  url: string;
  expiresAt: string;
  __typename: string;
}

export interface DashboardUrlsResponse {
  dashboardUrls: DashboardUrl[];
  errors?: GraphQLError[];
}

export interface DashboardV2Response {
  dashboardUrlsV2: DashboardUrl[];
  errors?: GraphQLError[];
}

export interface GraphQLReportResponse {
  data: DashboardUrlsResponse;
}
