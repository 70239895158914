import { gql } from 'apollo-angular';

export const GET_TENANTS = gql`
  query {
    tenants {
      id
      operationType
      name
      timezone
      language
      logoUrl
    }
  }
`;

export const GET_TENANT_INVOICES = gql`
  query ($criteria: SearchInInvoicesCriteria!) {
    invoices(criteria: $criteria) {
      totalResults
      results {
        id
        tenantId
        status
        date
        createdAt
        fileName
      }
    }
  }
`;

export const GENERATE_TENANT_INVOICES = gql`
  mutation ($input: InvoiceInput!) {
    invoice(input: $input) {
      totalResults
    }
  }
`;

export const GET_TENANT = gql`
  query ($id: ID!) {
    tenant(id: $id) {
      id
      name
      timezone
      language
      logoUrl
      operationType
    }
  }
`;

export const ADD_TENANT = gql`
  mutation ($input: CreateTenantInput!) {
    createTenant(input: $input) {
      id
      name
      language
      logoUrl
      operationType
    }
  }
`;

export const EDIT_TENANT = gql`
  mutation ($id: ID!, $input: UpdateTenantInput!) {
    updateTenant(id: $id, input: $input) {
      id
      name
      language
    }
  }
`;

export const EDIT_TENANT_LOGO = gql`
  mutation ($id: ID!, $input: UpdateTenantLogoInput!) {
    updateTenantLogo(id: $id, input: $input) {
      id
      logoUrl
    }
  }
`;

export const DELETE_TENANT = gql`
  mutation ($id: ID!) {
    deleteTenant(id: $id) {
      id
    }
  }
`;

export const GET_SMS_LIMITS = gql`
  query {
    smsLimits {
      limit
    }
  }
`;

export const GET_CERTIFIED_NOTIFICATIONS_SETTINGS = gql`
  query ($tenantId: ID!) {
    certifiedNotificationsSettings(tenantId: $tenantId) {
      id
      tenantId
      smsActive
      emailActive
      smsLimits {
        limit
      }
    }
  }
`;

export const UPDATE_CERTIFIED_NOTIFICATIONS_SETTINGS = gql`
  mutation ($id: ID!, $input: UpdateCertifiedNotificationsSettingsInput!) {
    updateCertifiedNotificationsSettings(id: $id, input: $input) {
      id
      tenantId
      smsActive
      emailActive
      smsLimits {
        limit
      }
    }
  }
`;
