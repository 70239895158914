import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

export function minElementsInArray(amount: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as any[];
    if (value && value.length < amount) {
      return { minElementsInArray: { amount } };
    }
    return null;
  };
}
