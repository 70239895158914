
import { CurrencyInput } from '../../dynamic-form/models/form-currency';
import { FormTextbox } from '../../dynamic-form/models/form-textbox';

export const currencyInput = [
  new FormTextbox({
    key: 'concept',
    type: 'text',
    required: true,
    label: 'CONCEPTO',
    value: '',
    placeholder: 'Nombre del concepto',
    readonly: false,
    disabled: false,
  }),
  new CurrencyInput({
    key: 'amount',
    type: 'currency',
    required: true,
    label: 'CANTIDAD',
    value: '',
    placeholder: '',
    readonly: false,
  }),
];
