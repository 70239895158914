import { Injectable } from '@angular/core';
import { Observable, map, BehaviorSubject, catchError, throwError, of } from 'rxjs';
import {
  EXPORT_PAYMENT_AGREEMENTS_LIST,
  GET_PAYMENT_AGREEMENTS,
} from '../graphql/payment-agreement.graphql';
import { ActivatedRoute } from '@angular/router';
import { CurrentUserService } from '@core/services/currentUser.service';
import { Apollo } from 'apollo-angular';
import { ulid } from 'ulid';
import {
  CALCULATE_INSTALMENTS,
  CREATE_PARTIAL_PAYMENT_OFFER,
  SEND_PROPOSE_PAYMENT_AGREEMENT,
  VALIDATE_DEPENDENCIES,
} from '@modules/customers/customers.GraphQL';

@Injectable({
  providedIn: 'root',
})
export class PaymentAgreementService {
  isLoading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private apollo: Apollo,
    private route: ActivatedRoute,
    private currentUser: CurrentUserService,
  ) {}

  getPaymentAgreementList(
    params: { offset: number; limit: number },
    filters?: any,
  ): Observable<any> {
    filters = filters
      ? { ...filters, tenantId: this.route.snapshot.queryParamMap.get('tenant') }
      : {
          tenantId: this.route.snapshot.queryParamMap.get('tenant'),
          searchPattern: '',
          status: [],
          collectorIds: this.currentUser.currentUser.collectorIds,
        };

    if (filters.collectorIds.length === 0) {
      filters.collectorIds = this.currentUser.currentUser.collectorIds;
    }

    return this.apollo.query<any>({
      query: GET_PAYMENT_AGREEMENTS,
      variables: {
        criteria: {
          pagination: {
            offset: params.offset,
            limit: params.limit,
          },
          filters,
        },
      },
    });
  }

  exportPaymentAgreementsList(filters?: any): Observable<string> {
    filters = filters
      ? {
          ...filters,
          tenantId: this.route.snapshot.queryParamMap.get('tenant'),
          collectorIds: filters.collectorIds
            ? filters.collectorIds
            : this.currentUser.currentUser.collectorIds,
        }
      : {
          tenantId: this.route.snapshot.queryParamMap.get('tenant'),
          searchPattern: '',
          status: [],
          collectorIds: this.currentUser.currentUser.collectorIds,
        };

    return this.apollo
      .query<any>({
        query: EXPORT_PAYMENT_AGREEMENTS_LIST,
        variables: {
          fileId: ulid(),
          criteria: {
            filters,
          },
        },
      })
      .pipe(
        map((res) => {
          if (res.errors?.length) {
            throw new Error(res.errors[0].message);
          }
          return res.data.exportPaymentAgreementList.url;
        }),
        catchError((error) => throwError(() => new Error(error))),
      );
  }

  calculatePaymentAgreementInstalments(
    customerCaseId: string,
    numberOfInstalments: number,
  ): Observable<any> {
    const queryVariables = {
      customerCaseId,
      numberOfInstalments,
    };
    return this.apollo
      .query<any>({
        query: CALCULATE_INSTALMENTS,
        variables: queryVariables,
      })
      .pipe(
        map((response) => response.data.calculatePaymentAgreementInstalments),
        catchError((error) => {
          return throwError(() => new Error(error));
        }),
      );
  }

  checkvalidateDependencies(customerCaseId: string): Observable<any> {
    return this.apollo
      .query<any>({
        query: VALIDATE_DEPENDENCIES,
        variables: {
          customerCaseId,
        },
      })
      .pipe(
        map((res) => {
          if (res.errors?.length) {
            throw res;
          } else {
            const { validatePaymentAgreementsDependencies: v } = res.data;
            return v;
          }
        }),
        catchError(() => {
          return of([]);
        }),
      );
  }

  sendProposePaymentAgreement(
    customerId: string,
    payment: { channel: string; nMonths: number },
  ): Observable<any> {
    return this.apollo
      .mutate({
        mutation: SEND_PROPOSE_PAYMENT_AGREEMENT,
        variables: {
          input: {
            id: ulid(),
            tenantId: this.route.snapshot.queryParamMap.get('tenant'),
            customerCaseId: customerId,
            numberOfInstalments: payment.nMonths,
            channel: payment.channel,
          },
        },
      })
      .pipe(map((res: any) => res));
  }

  sendPartialPaymentOffer(
    customerId: string,
    selectedCopyId: string,
    expirationAt: Date,
    currency: string,
    minimumAbsoluteAmount: number,
    minimumPercentageAmount: number,
  ): Observable<any> {
    return this.apollo
      .mutate({
        mutation: CREATE_PARTIAL_PAYMENT_OFFER,
        variables: {
          input: {
            id: ulid(),
            tenantId: this.route.snapshot.queryParamMap.get('tenant'),
            copyId: selectedCopyId,
            customerCaseId: customerId,
            currency: currency,
            expirationAt: expirationAt,
            minimumAbsoluteAmount: minimumAbsoluteAmount * 100,
            minimumPercentageAmount: minimumPercentageAmount == 0 ? null : minimumPercentageAmount,
          },
        },
      })
      .pipe(map((res: any) => res.data.createPartialPaymentOffer));
  }
}
