<app-loader></app-loader>

<div class="layout-container" [ngClass]="containerClass">
  <app-sidebar></app-sidebar>
  <div class="layout-content-wrapper">
    <app-topbar></app-topbar>
    <app-breadcrumb class="content-breadcrumb"></app-breadcrumb>
    <div class="layout-content">
      <app-breadcrumb class="topbar-breadcrumb"></app-breadcrumb>
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-profilemenu></app-profilemenu>
  <app-activity-sidebar></app-activity-sidebar>
  <p-scrollTop [threshold]="500"></p-scrollTop>
</div>
