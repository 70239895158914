/**
 * Sets the time of the given date to the start of the day (00:00:00.000).
 * @param date - The date object.
 * @returns A new date object with time set to the start of the day.
 */
export function setStartOfDay(date: Date): Date {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0));
}

/**
 * Sets the time of the given date to the end of the day (23:59:59.999).
 * @param date - The date object.
 * @returns A new date object with time set to the end of the day.
 */
export function setEndOfDay(date: Date): Date {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999));
}
