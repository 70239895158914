<div *ngIf="!hasSegments && currentChar && maxChar">
   <p [style.color]="currentChar > maxChar ? 'red' : ''">{{ currentChar }}/{{ maxChar }}</p>
</div>
<div *ngIf="hasSegments" class="flex gap-2 align-items-center">
   <div *ngFor="let segment of array; let i = index">
      <ng-container *ngIf="i === array.length - 1">
         <p-chip [label]="totalPartial + '/' + maxChar + ' en ' + 'SMS ' + (i + 1)"> </p-chip>
      </ng-container>
      <ng-container *ngIf="i !== array.length - 1">
         <p-chip icon="pi pi-envelope" [label]="'SMS ' + (i + 1)"></p-chip>
      </ng-container>
   </div>
</div>
