import { NodeClass } from '@modules/campaigns/Domain/NodeClass';
import { NodeType } from '@modules/campaigns/Domain/Enums/NodeType';
import { WaitDaysForDueDateConfig } from '@modules/campaigns/Domain/Interfaces/INodeConfig';
import { NodeValidationError } from '@modules/campaigns/Domain/Interfaces/NodeValidationError';
import {
  getValidationErrorMessage,
  ValidationError,
} from '@modules/campaigns/Domain/Enums/ValidationErrors';

export class WaitDaysForDueDateNode extends NodeClass {
  constructor(id: string, position: { x: number; y: number }, config: WaitDaysForDueDateConfig) {
    super(id, NodeType.WaitDaysForDueDate, position, config);
  }

  isEmptyConfig(): boolean {
    return this.config['partDay'] == null || this.config['waitDays'] == null;
  }

  addChild(node: string): void {
    if (this.childrenNodes.length === 0) this.childrenNodes.push(node);
    else return;
  }

  addParent(node: string): void {
    if (this.parentNodes.length === 0) this.parentNodes.push(node);
    else return;
  }

  initNodeConfig() {
    (this.config as WaitDaysForDueDateConfig).partDay = null;
    (this.config as WaitDaysForDueDateConfig).waitDays = null;
    (this.config as WaitDaysForDueDateConfig).antesODespues = null;
  }

  validate(): NodeValidationError[] {
    const errors: NodeValidationError[] = [];

    if (this.config['partDay'] == null) {
      const errorMessage = getValidationErrorMessage(ValidationError.NO_PART_DAY, {
        nodoType: 'Esperar vencimiento',
      });
      errors.push({ nodeId: this.id, errorMessage });
    }

    if (this.config['waitDays'] == null) {
      const errorMessage = getValidationErrorMessage(ValidationError.NO_WAIT_DAYS, {
        nodoType: 'Esperar vencimiento',
      });
      errors.push({ nodeId: this.id, errorMessage });
    }

    if (this.config['antesODespues'] == null) {
      const errorMessage = getValidationErrorMessage(ValidationError.NO_BEFORE_AFTER, {
        nodoType: 'Esperar vencimiento',
      });
      errors.push({ nodeId: this.id, errorMessage });
    }

    if (
      (this.config['antesODespues'] == 'beforeDueDate' && this.config['waitDays'] > 0) ||
      (this.config['antesODespues'] == 'afterDueDate' && this.config['waitDays'] < 0)
    ) {
      const errorMessage = getValidationErrorMessage(ValidationError.ERROR_DAY_BEFORE_AFTER, {
        nodoType: 'Esperar vencimiento',
      });
      errors.push({ nodeId: this.id, errorMessage });
    }

    return errors;
  }
}
