<div class="col-12">
   <div>
      <form class="p-fluid p-formgrid grid" [formGroup]="form">
         <app-dynamic-form-input
            class=" col-12 lg:{{ 'col-' + formCols }}"
            *ngFor="let field of formFields"
            (sendValue)="send()"
            (sendPlaceholders)="setPlaceholders($event)"
            (sendChip)="setChip($event)"
            (emitEnter)="send()"
            (emitSupr)="clean()"
            [isEnter]="enterSendValue"
            [isSupr]="suprDeleteForm"
            [field]="field"
            [form]="form"
         ></app-dynamic-form-input>
      </form>

      <div class="flex flex-row-reverse flex-wrap gap-3 mt-1">
         <p-button
            *ngIf="this.submit"
            [disabled]="this.form.invalid || !isDisabled"
            styleClass="field"
            label="{{ btnLabel }}"
            (onClick)="send()"
         ></p-button>
         <p-button
            *ngIf="this.reset"
            styleClass="p-button-link"
            label="{{ btnLabelReset | translate }}"
            (onClick)="clean()"
         ></p-button>
         <p-button
            *ngIf="confirm"
            [label]="isEditMode ? btnLabelSave : btnLabel3"
            (onClick)="isEditMode ? openConfirmationModal($event) : editMode()"
         ></p-button>
         <p-button
            styleClass="p-button-outlined p-button-secondary"
            *ngIf="this.cancel"
            label="{{ btnCancel }}"
            (onClick)="onCancel()"
         ></p-button>
      </div>
   </div>
</div>

<p-confirmPopup [key]="'save-pop'"></p-confirmPopup>
