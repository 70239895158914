import { gql } from "apollo-angular";

export const GET_ACTIVITY_INFO = gql`
  query ($criteria: SearchActivitiesCriteria!) {
    activities(criteria: $criteria) {
      name
      category
      parameters
      dateTime
    }
  }
`;

