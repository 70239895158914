import { NodeClass } from '@modules/campaigns/Domain/NodeClass';
import { NodeType } from '@modules/campaigns/Domain/Enums/NodeType';
import { SuperEmailConfig } from '@modules/campaigns/Domain/Interfaces/INodeConfig';
import { NodeValidationError } from '@modules/campaigns/Domain/Interfaces/NodeValidationError';
import {
  getValidationErrorMessage,
  ValidationError,
} from '@modules/campaigns/Domain/Enums/ValidationErrors';

export class SuperEmailNode extends NodeClass {
  constructor(id: string, position: { x: number; y: number }, config: SuperEmailConfig) {
    super(id, NodeType.SuperSendEmail, position, config);
  }

  isEmptyConfig(): boolean {
    return this.config['copyIds'] === null || this.config['copyIds'].length <= 1;
  }

  addChild(node: string): void {
    if (this.childrenNodes.length === 0) this.childrenNodes.push(node);
    else return;
  }

  addParent(node: string): void {
    if (this.parentNodes.length === 0) this.parentNodes.push(node);
    else return;
  }

  initNodeConfig() {
    (this.config as SuperEmailConfig).copyIds = [];
    (this.config as SuperEmailConfig).certified = false;
    (this.config as SuperEmailConfig).super = true;
    (this.config as SuperEmailConfig).sendToAllContacts = false;
  }

  validate(): NodeValidationError[] {
    const errors: NodeValidationError[] = [];

    if (this.config['copyIds'] == null) {
      const errorMessage = getValidationErrorMessage(ValidationError.NO_COPY_IDS, {
        nodoType: 'Email IA',
      });
      errors.push({ nodeId: this.id, errorMessage });
    }

    return errors;
  }
}
