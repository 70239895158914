import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { placeholdersList } from '@shared/helpers/variables';

@Component({
  selector: 'app-placeholders-selector',
  templateUrl: './placeholders-selector.component.html',
  styleUrls: ['./placeholders-selector.component.scss'],
})
export class PlaceholdersSelectorComponent implements OnInit {
  @Output() placeholderSelected = new EventEmitter<string>();
  @Input() placeholders?: { label: string; value: string }[];

  ngOnInit() {
    if (!this.placeholders) {
      this.placeholders = placeholdersList as { label: string; value: 'string' }[];
    }
  }

  emit(placeholder: string) {
    this.placeholderSelected.emit(placeholder);
  }
}
