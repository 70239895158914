<div class="px-5 min-h-screen flex justify-content-center align-items-center">
  <div class="z-1 text-center">
    <div class="text-900 font-bold text-8xl mb-4">Próximamente</div>
    <p class="line-height-3 mt-0 mb-5 text-700 text-xl font-medium">
      Estamos trabajando en esta funcionalidad
    </p>
    <p-button
      [routerLink]="['/']"
      styleClass="p-button-defaukt font-medium p-button-raised"
      >Volver al panel de control</p-button
    >
  </div>
</div>
