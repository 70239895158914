import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { MenuChangeEvent } from '@models/ui/menuchangeevent';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private menuSource = new Subject<MenuChangeEvent>();
  private resetSource = new Subject();

  menuSource$ = this.menuSource.asObservable();
  resetSource$ = this.resetSource.asObservable();

  onMenuStateChange(event: MenuChangeEvent) {
    this.menuSource.next(event);
  }

  reset() {
    this.resetSource.next(true);
  }

  removeMenuItems(menuItems: MenuItem[], itemIds: string[]): MenuItem[] {
    return menuItems.filter((item: MenuItem) => {
      if (itemIds.includes(item.label!)) {
        return false;
      } else if (item.items && item.items.length > 0) {
        item.items = this.removeMenuItems(item.items, itemIds);
      }
      return true;
    });
  }
}
