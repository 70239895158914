import { Component } from '@angular/core';
import { PageLoaderService } from '@core/services/ui/pageLoader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  loading: boolean = false;

  constructor(private pageLoader: PageLoaderService) {
    this.pageLoader.isLoading.subscribe(
      (loading: boolean) => (this.loading = loading)
    );
  }
}
