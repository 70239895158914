<div *ngIf="installments.length && customerCase">
   <app-dynamic-form
      [formFields]="propostalForm"
      [formCols]="6"
      [submit]="false"
      [reset]="false"
      btnLabel3="{{ 'DATA.MODULES.SHARED.COMPONENTS.SEND_REMINDER.FORM.ACTIONS.CONFIRM' | translate }}"
      btnLabelSave="{{ 'DATA.MODULES.SHARED.COMPONENTS.SEND_REMINDER.FORM.ACTIONS.SEND' | translate }}"
      confirmMsg="{{ 'DATA.MODULES.SHARED.COMPONENTS.SEND_REMINDER.FORM.ACTIONS.CONFIRM_MESSAGE' | translate }}"
      (formValuesChanged)="onChanges($event)"
   />

   <div class="flex flex-column gap-2 p-3">
      <p>La propuesta de acuerdo de pago caducará en <b>72 horas</b> a partir del momento del envío.</p>

      <p>
         Enviarás un acuerdo de pago preaprobado a <b> {{ customer.name }} </b> por valor total de
         <b> {{ customerCase.totalAmount.amount | currencyWithCode : customerCase.totalAmount.currency || customerCase.totalAmountCurrency}} </b>
      </p>
      <p>
         Se cargarán
         <b>
            {{
               installments[0]?.amount?.amount
                  ? installments[0]?.amount?.amount / 100
                  : (customerCase.totalAmount.amount / nMonths | currencyWithCode: customerCase.totalAmount.currency || customerCase.totalAmountCurrency)
            }}
         </b>
         en el momento del primer pago, las cuotas restantes se cargan automáticamente cada mes durante
         <b> {{ nMonths }} {{ nMonths === 1 ? 'mes' : 'meses' }}, </b> al canal de pago <b> {{ sms }} {{ email }} </b>
      </p>
      <p>El cliente podrá ver las fechas exactas de cada cobro antes del primer pago.</p>
   </div>

   <div *ngIf="installments.length" class="p-3">
      <p-table [value]="installments || []">
         <ng-template pTemplate="header">
            <tr>
               <th>Cuota</th>
               <th>Importe</th>
            </tr>
         </ng-template>
         <ng-template pTemplate="body" let-instance let-rowIndex="rowIndex">
            <tr>
               <td>{{ rowIndex + 1 }}</td>
               <td>{{ instance?.amount?.amount | currencyWithCode: instance?.amount?.currency }}</td>
            </tr>
         </ng-template>
      </p-table>
   </div>

   <div class="flex flex-row-reverse">
      <p-button
         [disabled]="checkDisabled()"
         label="{{ 'DATA.MODULES.SHARED.COMPONENTS.SEND_REMINDER.FORM.ACTIONS.SEND' | translate }}"
         class="p-button-success"
         (onClick)="send()"
      />
   </div>
</div>
