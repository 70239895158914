import { gql } from 'apollo-angular';

export const GET_CURRENT_USER = gql`
  query ($id: ID!) {
    userIdentify(id: $id) {
      id
      email
      name
      surname
      tenantId
      collectorIds
      permissions
      role
    }
  }
`;

export const UPDATE_CURRENT_USER = gql`
  mutation ($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      email
      name
      surname
      tenantId
      collectors {
        id
        name
      }
      role
    }
  }
`;
