import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CustomerService } from '@modules/customers/customer.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ResetService, ToastService } from '@services/ui';
import { FormInputBase } from '@shared/components/dynamic-form/models';
import { partialPaymentOfferForm } from '@shared/components/partial-payment-offer/config/partialPaymentForm';
import { CustomerModel } from '@models/customer.model';
import { PaymentAgreementService } from '@modules/payment-agreement/services/payment-agreement.service';
import { Subject, take } from 'rxjs';
import { mapFilterCopies } from '@shared/components/joint-js/helpers';
import { CopiesService } from '@modules/copies/service/copies.service';
import { CopyModel } from '@models/copies/copies.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { maxImportOnTotalAmount } from '@shared/validators/forms/maxImportOnTotalAmount';
import { maxSafeIntegerValidator } from '@shared/validators/forms/maxSafeInteger';

@Component({
  selector: 'app-partial-payment-offer',
  templateUrl: './partial-payment-offer.component.html',
  styleUrls: ['./partial-payment-offer.component.scss'],
})
export class PartialPaymentOfferComponent implements OnInit, OnDestroy {
  instance: any;
  customer: CustomerModel = {} as CustomerModel;
  selectedCopyId: string = '';
  amountToSend = 1;
  amountToSendPercentage = 1;
  option = 'minimumAbsoluteAmount';
  copies = [];
  copiesList?: CopyModel[] = [] as CopyModel[];
  formGroup: FormGroup | undefined;
  destroy$ = new Subject<void>();

  customerService = inject(CustomerService);
  dModal = inject(DialogService);
  ref = inject(DynamicDialogRef);
  toastService = inject(ToastService);
  resetService = inject(ResetService);

  methods = [
    { value: 'sms', label: 'SMS' },
    { value: 'email', label: 'Email' },
  ];
  loading = false;
  totalAmount = 0;
  amountTypeOptions = [
    { label: 'IMPORTE MÍNIMO', value: 'minimumAbsoluteAmount' },
    { label: 'IMPORTE MÍNIMO EN %', value: 'minimumPercentageAmount' },
  ];

  @ViewChild('form') form!: FormGroup<{
    minimumPercentageAmount: FormControl<number | null>;
    minimumAbsoluteAmount: FormControl<number | null>;
  }>;
  partialPaymentOfferForm: FormInputBase<string | boolean>[] = partialPaymentOfferForm;

  constructor(
    public config: DynamicDialogConfig,
    private paymentAgreementService: PaymentAgreementService,
    private copiesService: CopiesService,
  ) {
    this.instance = this.config.data.instance;
    this.customer = this.config.data.customer;
    this.totalAmount = this.instance.totalAmount.amount
      ? this.instance.totalAmount.amount / 100
      : this.instance.totalAmount / 100;
  }

  ngOnInit(): void {
    this.getCopyList();
    this.formGroup = new FormGroup({
      value: new FormControl('minimumAbsoluteAmount'),
    });

    this.formGroup.get('amountType')?.setValue('minimumAbsoluteAmount');
    this.partialPaymentOfferForm[1].setVisibility(false);
    this.partialPaymentOfferForm[0].setVisibility(true);

    this.partialPaymentOfferForm[0]?.setValidators([
      Validators.required,
      maxSafeIntegerValidator(),
      maxImportOnTotalAmount(this.totalAmount),
    ]);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  confirmation($event: any) {
    this.paymentAgreementService
      .sendPartialPaymentOffer(
        this.instance.customerCaseId ? this.instance.customerCaseId : this.instance.id,
        this.selectedCopyId,
        //TODO - > change method o campaign
        new Date(new Date().getTime() + 72 * 60 * 60 * 1000),
        this.instance.totalAmountCurrency
          ? this.instance.totalAmountCurrency
          : this.instance.totalAmount.currency,
        $event.minimumAbsoluteAmount,
        $event.minimumPercentageAmount,
      )
      .subscribe((res: any) => {
        if (res !== null) {
          this.toastService.showSuccess(
            'Se ha enviado corectamente!',
            'Oferta de pago parcial enviada',
          );
          setTimeout(() => {
            this.ref.close(res);
          });
        } else {
          this.toastService.showError(
            'Error',
            'Error al enviar oferta de pago parcial, puede que esta oferta ya se haya enviado',
          );
        }
      });
  }

  getCopyList() {
    this.loading = true;
    this.copiesService
      .searchCopies()
      .pipe(take(1))
      .subscribe((response: any) => {
        this.copies = response.map((copy: any) => ({
          id: copy.id,
          name: copy.name,
          type: copy.type,
        }));
        this.loading = false;
        this.handleCopiesList(this.partialPaymentOfferForm[2].value);
      });
  }

  updateNodeAttributes(newAttributes: any) {
    this.amountToSend = newAttributes.minimumAbsoluteAmount;
    this.amountToSendPercentage = newAttributes.minimumPercentageAmount;
    if (newAttributes.name) {
      this.handleCopiesList(newAttributes.name);
      this.selectedCopyId = newAttributes.copyIds;
    } else {
      this.selectedCopyId = '';
    }
  }

  handleCopiesList(type: string) {
    if (type) {
      const allowedCopies = this.copies
        .filter((copy: any) => copy.type === mapFilterCopies(type))
        .map((copy: any) => ({
          label: copy.name,
          value: copy.id,
        }));

      this.partialPaymentOfferForm[3]?.setPropertyValue('options', [...allowedCopies]);
    }
  }

  toggleAmountFields(amountType: string) {
    this.option = amountType;

    if (amountType === 'minimumAbsoluteAmount') {
      this.partialPaymentOfferForm[0].setVisibility(true);
      this.partialPaymentOfferForm[1].setVisibility(false);
      this.partialPaymentOfferForm[0].required = false;
      this.partialPaymentOfferForm[1].required = true;
    } else {
      this.partialPaymentOfferForm[0].required = false;
      this.partialPaymentOfferForm[1].required = true;

      this.partialPaymentOfferForm[0].setVisibility(false);
      this.partialPaymentOfferForm[1].setVisibility(true);
    }
  }
}
