import { Component, Input, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-new',
  templateUrl: './create-new.component.html',
})
export class CreateNewComponent {
  private router = inject(Router);
  @Input() path: string = '';
  @Input() label: string = 'Crear nuevo';

  create() {
    this.router.navigate([`${this.path}`], {
      queryParamsHandling: 'preserve',
    });
  }
}
