<app-dynamic-form
    #form
    [formFields]="inputForm"
    [formCols]="12"
    [enterSendValue]="false"
    [resetAfterSubmit]="false"
    [suprDeleteForm]="false"
    [submit]="false"
    [reset]="false"
    (formValuesChanged)="onTimezoneChange($event)"
/>

<p-button (click)="confirmChange()" label="Asignar huso horario"></p-button>
