import { FormDropdown, FormNumber } from '@shared/components/dynamic-form/models';
import { maxSafeIntegerValidator } from '@shared/validators/forms/maxSafeInteger';
import { Validators } from '@angular/forms';
import { nodeSendTypes } from '@shared/helpers/variables';

export const partialPaymentOfferForm = [
  new FormNumber({
    key: 'minimumAbsoluteAmount',
    type: 'number',
    required: true,
    label: 'IMPORTE MÍNIMO',
    value: 1,
    placeholder: '',
    readonly: false,
    inputGroup: true,
    addon: 'EUR',
    validators: [maxSafeIntegerValidator()],
  }),

  new FormNumber({
    key: 'minimumPercentageAmount',
    type: 'number',
    required: true,
    label: 'IMPORTE MÍNIMO EN %',
    value: 1,
    placeholder: '',
    readonly: false,
    inputGroup: true,
    isVisible: false,
    addon: '%',
    validators: [Validators.pattern('^(\\d|[1-9]\\d|99)$'), Validators.min(1)],
  }),

  new FormDropdown({
    key: 'name',
    type: 'dropdown',
    placeholder: 'Selecciona una opción',
    required: false,
    label: 'CANAL',
    value: nodeSendTypes[0].value,
    options: nodeSendTypes,
    multi: false,
    filter: false,
    readonly: false,
    disabled: false,
  }),

  new FormDropdown({
    key: 'copyIds',
    type: 'dropdown',
    placeholder: 'Selecciona una plantilla',
    required: true,
    label: 'PLANTILLAS',
    value: null,
    options: [],
    multi: false,
    selectionLimit: 1000,
    filter: true,
    readonly: false,
    isVisible: false,
  }),
];
