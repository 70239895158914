<!--
    V.1.0
    <p-button
    [disabled]="check()"
    [style]="{ width: '100%' }"
    [label]="'DATA.MODULES.SHARED.COMPONENTS.SEND_PAYMENT_AGREEMENT.TITLE' | translate"
    (onClick)="initSender()"
/> -->

<!-- V.2.0 -->
<p-button
    (onClick)="openSendProposePaymentAgreementModal()"
    [disabled]="!this.isButtonDisabled"
    icon="pi pi-money-bill"
    styleClass="h-2rem w-2rem mr-2"
    tooltipPosition="bottom"
    [pTooltip]="'DATA.MODULES.SHARED.COMPONENTS.SEND_PAYMENT_AGREEMENT.TITLE' | translate"
/>
