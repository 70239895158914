import { IWorkflowValidator } from '@modules/campaigns/Domain/Interfaces/IWorkflowValidator';
import { ValidationError } from '@modules/campaigns/Domain/Enums/ValidationErrors';
import { IWorkflow } from '@modules/campaigns/Domain/Interfaces/IWorkflow';
import { NodeType } from '@modules/campaigns/Domain/Enums/NodeType';
import { WorkflowType } from '@modules/campaigns/Domain/Enums/WorkflowType';

export class StartAndEndNodesValidator implements IWorkflowValidator {
  validate(workflow: IWorkflow): string[] {
    const errors: string[] = [];
    const startNodes = workflow.nodes.filter((node) => node.type === NodeType.Start);
    const endNodes = workflow.nodes.filter((node) => node.type === NodeType.End);
    const assignNodes = workflow.nodes.filter((node) => node.type === NodeType.AssignCampaign);

    if (startNodes.length !== 1) {
      errors.push(ValidationError.INVALID_NUMBER_OF_START_NODES);
    }

    if (endNodes.length < 1 && assignNodes.length < 1) {
      errors.push(ValidationError.NO_END_NODE);
    }

    return errors;
  }

  shouldValidate(workflow: IWorkflow): boolean {
    return workflow.type === WorkflowType.DRIP || workflow.type === WorkflowType.PILL;
  }
}
