import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencyWithCodePipe } from './currency-with-code.pipe';
import { StandarDatePaymefyPipe } from './standar-paymefy-pipe.pipe';
import { EllipsisPipe } from './ellipsis.pipe';
import { ImportLineCellPipe } from './import-cell.pipe';
import { MonthExtractorPipe } from '@shared/pipes/month-extractor.pipe';
import { YearExtractorPipe } from '@shared/pipes/year-extractor.pipe';
import { DayExtractorPipe } from '@shared/pipes/day-extract.pipe';
import { SafeDatePipe } from '@shared/pipes/safe-date.pipe';
import { TimeExtractorPipe } from '@shared/pipes/time-extractor.pipe';
import { TransformDateTimezonePipe } from '@shared/pipes/transformDateTimezone.pipe';

@NgModule({
  declarations: [
    CurrencyWithCodePipe,
    StandarDatePaymefyPipe,
    TransformDateTimezonePipe,
    SafeDatePipe,
    EllipsisPipe,
    ImportLineCellPipe,
    MonthExtractorPipe,
    YearExtractorPipe,
    DayExtractorPipe,
    TimeExtractorPipe,
  ],
  exports: [
    CurrencyWithCodePipe,
    StandarDatePaymefyPipe,
    TransformDateTimezonePipe,
    SafeDatePipe,
    EllipsisPipe,
    ImportLineCellPipe,
    MonthExtractorPipe,
    YearExtractorPipe,
    DayExtractorPipe,
    TimeExtractorPipe,
  ],
  imports: [CommonModule],
})
export class PipeModule {}
