export enum WorkflowType {
  DRIP = 'drip',
  PILL = 'pill',
  // add other enum values here
}

export function getType(value: string): WorkflowType | undefined {
  if (value === undefined) return undefined;
  const enumKeys = Object.keys(WorkflowType) as Array<keyof typeof WorkflowType>;
  for (const key of enumKeys) {
    if (WorkflowType[key] === value) {
      return WorkflowType[key];
    }
  }
  return undefined;
}
