import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { currencyInput } from './config/currencyInput';
import { Validators } from '@angular/forms';
import { CurrencyHelper } from '@shared/helpers/decimals.helper';

@Component({
  selector: 'app-currency-modal',
  templateUrl: './currency-modal.component.html',
  styleUrls: ['./currency-modal.component.scss'],
})
export class CurrencyModalComponent implements OnInit {
  currencyInput = currencyInput;
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    const amount = CurrencyHelper.toDecimal(this.config.data.amount);
    const concept = this.config.data.concept;

    currencyInput[1].setPropertyValue('validators', [Validators.min(0)]);
    currencyInput[0].setPropertyValue('value', concept);
    currencyInput[1].setPropertyValue('value', amount);
  }

  send(data: any) {
    data.amount = CurrencyHelper.toCents(data.amount);
    this.ref.close(data);
  }
}
