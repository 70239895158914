<!-- 
    V.1.0+
    <p-button
    [disabled]="!showReminder()"
    [style]="{'width':'100%'}"
    [label]="'DATA.MODULES.SHARED.COMPONENTS.SEND_REMINDER.TITLE' | translate"
    (onClick)="initSender()"
/> -->

<!-- V.2.0 -->
<p-button
    (onClick)="initSender()"
    [disabled]="!showReminder()"
    icon="pi pi-bell"
    styleClass="h-2rem w-2rem mr-2"
    tooltipPosition="bottom"
    [pTooltip]="'DATA.MODULES.SHARED.COMPONENTS.SEND_REMINDER.TITLE' | translate"
/>
