import { map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CopiesService } from '@modules/copies/service/copies.service';
import { GET_COPIES_LIST } from '@modules/copies/graphql/copies.GraphQl';
import { ActivatedRoute } from '@angular/router';
import { Apollo } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class GetCopyService {
  constructor(
    private copyService: CopiesService,
    private route: ActivatedRoute,
    private apollo: Apollo,
  ) {}

  getCopy(id: string): Observable<any> {
    return this.copyService.getCopy(id);
  }

  searchCopies(): Observable<any> {
    return this.apollo
      .query({
        query: GET_COPIES_LIST,
        variables: {
          tenantId: this.route.snapshot.queryParams['tenant'],
        },
      })
      .pipe(map((res: any) => res.data.copies));
  }
}
