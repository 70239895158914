import { Component, Input } from '@angular/core';
import { NodeClass } from '@modules/campaigns/Domain/NodeClass';
import { NodeType } from '@modules/campaigns/Domain/Enums/NodeType';

@Component({
  selector: 'app-message-wait-nodes',
  templateUrl: './message-wait-nodes.component.html',
  styleUrls: ['./message-wait-nodes.component.scss'],
})
export class MessageWaitNodesComponent {
  @Input() selectedNode?: NodeClass;

  timeOfWeekTranslations: { [key: string]: string } = {
    FULL_DAY: 'EL DÍA',
    EVENING: 'LA TARDE',
    MORNING: 'LA MAÑANA',
  };

  private daysOfWeekTranslations: { [key: string]: string } = {
    MONDAY: 'LUNES',
    TUESDAY: 'MARTES',
    WEDNESDAY: 'MIÉRCOLES',
    THURSDAY: 'JUEVES',
    FRIDAY: 'VIERNES',
    SATURDAY: 'SÁBADO',
    SUNDAY: 'DOMINGO',
  };

  translateTime(day: string): string {
    return this.timeOfWeekTranslations[day] || day;
  }

  translateDay(day: string): string {
    return this.daysOfWeekTranslations[day] || day;
  }

  getPartDay(): string {
    return this.translateTime(this.selectedNode?.config?.['partDay'] || '-');
  }

  getWaitDays(): string {
    return this.selectedNode?.config?.['waitDays'] ?? '0';
  }

  getDayOfWeek(): string {
    return this.translateDay(this.selectedNode?.config?.['dayOfWeek'] || '-');
  }

  getDayOfMonth(): string {
    const dayOfMonth = this.selectedNode?.config?.['dayOfMonth'];
    return dayOfMonth === 'last' ? 'último' : dayOfMonth || '-';
  }

  protected readonly NodeType = NodeType;
}
