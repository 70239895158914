import { NodeClass } from '@modules/campaigns/Domain/NodeClass';
import { NodeType } from '@modules/campaigns/Domain/Enums/NodeType';
import { OfferPartialPaymentConfig } from '@modules/campaigns/Domain/Interfaces/INodeConfig';
import {
  getValidationErrorMessage,
  ValidationError,
} from '@modules/campaigns/Domain/Enums/ValidationErrors';
import { NodeValidationError } from '@modules/campaigns/Domain/Interfaces/NodeValidationError';

export class OfferPartialPaymentNode extends NodeClass {
  constructor(id: string, position: { x: number; y: number }, config: OfferPartialPaymentConfig) {
    super(id, NodeType.OfferPartialPayment, position, config);
  }

  isEmptyConfig(): boolean {
    return (
      this.config['expirationDays'] == null ||
      this.config['minimumAmount'] == null ||
      this.config['minimumAmount'].amount == null
    );
  }

  addChild(node: string): void {
    if (this.childrenNodes.length === 0) this.childrenNodes.push(node);
    else return;
  }

  addParent(node: string): void {
    if (this.parentNodes.length === 0) this.parentNodes.push(node);
    else return;
  }

  initNodeConfig(): void {
    (this.config as OfferPartialPaymentConfig).expirationDays = 1;
    //TODO: probably change
    (this.config as OfferPartialPaymentConfig).minimumAmount = { amount: '1', currency: 'EUR' };
    (this.config as OfferPartialPaymentConfig).minimumPercentageAmount = null;
  }

  validate(): NodeValidationError[] {
    const errors: NodeValidationError[] = [];

    if (this.config['expirationDays'] == null) {
      const errorMessage = getValidationErrorMessage(ValidationError.NO_EXPIRATION, {
        nodoType: 'Pago Parcial',
      });
      errors.push({ nodeId: this.id, errorMessage });
    }

    if (this.config['minimumAmount'].amount == null) {
      const errorMessage = getValidationErrorMessage(ValidationError.NO_MIN_AMOUNT, {
        nodoType: 'Pago Parcial',
      });
      errors.push({ nodeId: this.id, errorMessage });
    }

    if (this.config['minimumPercentageAmount'] > 99 || this.config['minimumPercentageAmount'] < 0) {
      const errorMessage = ValidationError.EXCEEDED_PERCENTAGE;
      errors.push({ nodeId: this.id, errorMessage });
    }

    if (this.config['expirationDays'] > 99 || this.config['expirationDays'] < 0) {
      const errorMessage = ValidationError.EXCEEDED_CADUCITY;
      errors.push({ nodeId: this.id, errorMessage });
    }

    return errors;
  }
}
