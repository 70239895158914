import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-fieldset-p',
  templateUrl: './fieldset-p.component.html',
  styleUrls: ['./fieldset-p.component.scss']
})
export class FieldsetPComponent {
@Input({required: true}) icon!: string;
@Input({required: true}) header!: string;
}
