const hackSuperNodes = (node: any) => {
  try {
    const map: { [key: string]: string } = {
      superSendCertifiedSMS: 'superSendCertifiedSMS',
      superSendCertifiedEmail: 'superSendCertifiedEmail',
      superSendSMS: 'superSendSMS',
      superSendEmail: 'superSendEmail',
    };

    // Check if node.config is defined and has copyIds array
    if (node.config && Array.isArray(node.config.copyIds) && node.config.copyIds.length > 1) {
      const newNodeType = map[node.type];
      if (newNodeType) {
        node.type = newNodeType;
      } else {
        throw new Error(`Mapping not found for node type: ${node.type}`);
      }
    }

    return node;
  } catch (error) {
    console.error('Error in hackSuperNodes:', error);
    throw error;
  }
};

const reverseHackSuperNodes = (node: any) => {
  const map: { [key: string]: string } = {
    superSendCertifiedSMS: 'sendCertifiedSMS',
    superSendCertifiedEmail: 'sendCertifiedEmail',
    superSendSMS: 'sendSMS',
    superSendEmail: 'sendEmail',
  };
  if (
    node.parameters.name === 'superSendSMS' ||
    node.parameters.name === 'superSendEmail' ||
    node.parameters.name === 'superSendCertifiedSMS' ||
    node.parameters.name === 'superSendCertifiedEmail'
  ) {
    node.parameters.name = map[node.parameters.name];
  }

  return node;
};

export { hackSuperNodes, reverseHackSuperNodes };
