import {
  EmailCertifiedConfig,
  EmailConfig,
  NodeConfig,
  SMSCertifiedConfig,
  SMSConfig,
  SuperEmailCertifiedConfig,
  SuperEmailConfig,
  SuperSMSCertifiedConfig,
  SuperSMSConfig,
} from '@modules/campaigns/Domain/Interfaces/INodeConfig';

export function hascopyIds(
  config: NodeConfig,
): config is
  | SMSConfig
  | SuperSMSConfig
  | SuperSMSCertifiedConfig
  | SMSCertifiedConfig
  | EmailConfig
  | SuperEmailConfig
  | EmailCertifiedConfig
  | SuperEmailCertifiedConfig {
  return 'copyIds' in config;
}
