import { Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { filter, map, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CurrentUserService } from '@core/services/currentUser.service';
import { TenantService } from '@modules/tenants/services/tenant.service';
import { LayoutService } from '@layout/service/app.layout.service';
import { MenuService } from '../service/app.menu.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { PermissionType, UserModel } from '@admin/modules/users/models/user.model';
import { TimezoneService } from '@shared/services/timezone.service';
import { SelectorTimezoneComponent } from '@shared/components/selector-timezone/selector-timezone.component';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
})
export class AppTopbarComponent implements OnDestroy, OnInit {
  @ViewChild('menubutton') menuButton!: ElementRef;

  private translate = inject(TranslateService);

  avatar: string = 'assets/media/mock/profile.png';
  user: UserModel = {} as UserModel;
  suscriptions: Subscription[] = [];
  loading: boolean = false;
  tenantName = '';
  PERMISSION_TYPE = PermissionType;
  itemsStart: MenuItem[] = [
    {
      label: 'Informes',
      icon: 'pi pi-chart-pie',
      items: [
        {
          label: this.translate.instant('DATA.MODULES.REPORTS.BC.CLIENTS'),
          routerLink: ['/reports/customers'],
          queryParamsHandling: 'preserve',
        },
        {
          label: this.translate.instant('DATA.MODULES.REPORTS.BC.CAMPAIGNS'),
          routerLink: ['/reports/campaigns'],
          queryParamsHandling: 'preserve',
        },
        {
          label: this.translate.instant('DATA.MODULES.REPORTS.BC.PORTFOLIOS'),
          routerLink: ['/reports/portfolios'],
          queryParamsHandling: 'preserve',
        },
        {
          label: this.translate.instant('DATA.MODULES.REPORTS.BC.COLLECTION'),
          routerLink: ['/reports/collection'],
          queryParamsHandling: 'preserve',
        },
        {
          label: this.translate.instant('DATA.MODULES.REPORTS.BC.WORKFLOWS'),
          routerLink: ['/reports/workflows'],
          queryParamsHandling: 'preserve',
        },
        {
          label: this.translate.instant('DATA.MODULES.REPORTS.BC.COLLECTORS'),
          routerLink: ['/reports/collectors'],
          queryParamsHandling: 'preserve',
        },
      ],
    },
    {
      label: 'Listados',
      icon: 'pi pi-list',
      items: [
        {
          label: 'Notificaciones enviadas',
          routerLink: ['/listings/notifications'],
          queryParamsHandling: 'preserve',
        },
        {
          label: 'Errores contactabilidad',
          routerLink: ['/listings/contact-errors'],
          queryParamsHandling: 'preserve',
        },
        {
          label: 'Abandonos pasarela de pago',
          routerLink: ['/listings/abandoned-payments'],
          queryParamsHandling: 'preserve',
        },
        {
          label: 'Listado de pagos',
          routerLink: ['/listings/payments'],
          queryParamsHandling: 'preserve',
        },
        {
          label: 'Móviles bloqueados y teléfonos no notificables para SMS',
          routerLink: ['/listings/blocked_phones_for_sending_sms'],
          queryParamsHandling: 'preserve',
        },
      ],
    },
    {
      label: 'Importaciones',
      icon: 'pi pi-cloud-upload',
      routerLink: ['/import'],
      queryParamsHandling: 'preserve',
    },
    {
      label: 'Calendario',
      icon: 'pi pi-calendar',
      routerLink: ['/calendar'],
      queryParamsHandling: 'preserve',
    },
    {
      label: 'Conversaciones',
      icon: 'pi pi-comments',
      routerLink: ['/conversations'],
      queryParamsHandling: 'preserve',
    },
    {
      label: 'Business Insights',
      icon: 'pi pi-sitemap',
      routerLink: ['/new-feature/business-insights'],
      queryParamsHandling: 'preserve',
    },
  ];
  selectedTimezone: string = '';
  timezoneSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    public layoutService: LayoutService,
    private menuService: MenuService,
    private currentUser: CurrentUserService,
    private tenant: TenantService,
    public timezoneService: TimezoneService,
    private dModal: DialogService,
  ) {
    this.user = this.currentUser.currentUser;
    this.getTenantName();
    this.allowedItems();
    this.routerUtils();
  }

  ngOnInit() {
    this.timezoneSubscription = this.timezoneService.selectedTimezone$.subscribe((timezone) => {
      this.selectedTimezone = timezone;
    });

    this.selectedTimezone = this.timezoneService.getTimezone();
  }

  routerUtils() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd || event instanceof NavigationStart),
        map((event) => event as NavigationEnd),
      )
      .subscribe((event) => {
        const fullUrl = event.urlAfterRedirects || event.url;
        const urlBeforeQueryParams = fullUrl.split('?')[0];
        this.resetMenuStyles();

        for (const item of this.itemsStart) {
          if (item.routerLink && item.routerLink[0] === urlBeforeQueryParams) {
            item.styleClass = 'active-text';
            return;
          }

          if (item.items) {
            for (const subItem of item.items) {
              if (subItem.routerLink && subItem.routerLink[0] === urlBeforeQueryParams) {
                subItem.styleClass = 'active-text';
                item.styleClass = 'parent-active';
                return;
              }
            }
          }
        }
      });
  }

  resetMenuStyles() {
    for (const item of this.itemsStart) {
      item.styleClass = 'normal-text';
      if (item.items) {
        for (const subItem of item.items) {
          subItem.styleClass = 'normal-text';
        }
      }
    }
  }

  isAllow(permissions: PermissionType[]): boolean {
    return this.currentUser.allowAccessPermission([...permissions]);
  }

  allowedItems() {
    if (this.isAllow([PermissionType.FULL_ACCESS])) {
      return;
    }

    const potentialRestrictedItems = [
      { id: 'Calendario', permission: PermissionType.CAMPAIGN_CALENDAR_QUERIES },
      { id: 'Business Insights', permission: PermissionType.BUSINESS_INSIGHT },
      { id: 'Informes', permission: PermissionType.REPORTS },
    ];

    // Iterar sobre los elementos potencialmente restringidos
    potentialRestrictedItems.forEach((item) => {
      // Verificar si el usuario tiene permiso para este elemento
      if (!this.isAllow([item.permission])) {
        // Si no tiene permiso, eliminar el elemento del menú
        this.itemsStart = this.menuService.removeMenuItems(
          this.itemsStart,
          potentialRestrictedItems.map((i) => i.id),
        );
      }
    });
  }

  getTenantName() {
    this.loading = true;
    this.suscriptions.push(
      this.tenant.getActualTenantCollectors().subscribe((tenant: string) => {
        this.tenantName = tenant;
        this.loading = false;
      }),
    );
  }

  onMenuButtonClick() {
    this.layoutService.onMenuToggle();
  }

  onProfileButtonClick() {
    this.layoutService.showProfileSidebar();
  }
  onActivityButtonClick() {
    this.layoutService.showActivitySidebar();
  }

  letters = (name: string): string =>
    name
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase())
      .join('');

  ngOnDestroy(): void {
    this.suscriptions.forEach((suscription: Subscription) => suscription.unsubscribe());
    if (this.timezoneSubscription) {
      this.timezoneSubscription.unsubscribe();
    }
    this.loading = false;
  }

  showTimezone() {
    const ref = this.dModal.open(SelectorTimezoneComponent, {
      header: 'Seleccionar un huso horario',
      width: '60vw',
      height: 'auto',
      closeOnEscape: true,
    });
    ref.onClose.subscribe();
  }
}
