<img
   *ngIf="countryCode === 'cat' || countryCode === 'ca'"
   [src]="'/assets/media/flags/cat.svg'"
   [pTooltip]="'Catalán'"
   style="width: 20px; margin-right: 8px"
/>

<img
   *ngIf="countryCode === 'ca_ES_VALENCIA'"
   [src]="'/assets/media/flags/cv.svg'"
   [pTooltip]="'Valenciano'"
   style="width: 20px; margin-right: 8px"
/>

<img
   *ngIf="countryCode === 'es_MX'"
   src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
   [class]="'flag flag-mx'"
   [pTooltip]="'Mexicano'"
   style="width: 20px; margin-right: 8px"
/>

<img
   *ngIf="countryCode === 'en'"
   src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
   [class]="'flag flag-gb uk'"
   [pTooltip]="'Inglés'"
   style="width: 20px; margin-right: 8px"
/>

<img
   *ngIf="
      countryCode !== 'cat' &&
      countryCode !== 'en' &&
      countryCode !== 'es_MX' &&
      countryCode !== 'ca' &&
      countryCode !== 'ca_ES_VALENCIA'
   "
   src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
   [class]="'flag flag-' + countryCode"
   style="width: 20px; margin-right: 8px"
/>
