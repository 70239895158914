import { DynamicFormComponent } from '../../../dynamic-form/dynamic-form.component';
import { WorkflowClass } from '@modules/campaigns/Domain/WorkflowClass';
import { NodeClass } from '@modules/campaigns/Domain/NodeClass';
import { ParseInfoFormToClassService } from '@shared/components/joint-js/services/parseInfoFormToClass.service';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ToastService } from '@services/ui';
import { ActivatedRoute } from '@angular/router';
import { mapFilterCopies } from '@shared/components/joint-js/helpers';
import { CampaignModel, campaignStatus } from '@modules/campaigns/models/campaign.model';
import { TranslateService } from '@ngx-translate/core';
import { NodeType } from '@modules/campaigns/Domain/Enums/NodeType';
import { GetCopyService } from '@shared/components/joint-js/services/get-copies';
import { finalize, of, Subject } from 'rxjs';
import { ConditionalConfig } from '@modules/campaigns/Domain/Interfaces/INodeConfig';
import { catchError } from 'rxjs/operators';
import { NodeFormService } from '@modules/campaigns/Application/Services/NodeForm';
import { MenuItem } from 'primeng/api';
import { hascopyIds } from '@modules/campaigns/Domain/Factories/HasNodeConfig';
import { SegmentationService } from '@modules/segments/services/segments.service';

@Component({
  selector: 'app-node-inspector',
  templateUrl: './node-inspector.component.html',
  styleUrls: ['./node-inspector.component.scss'],
})
export class NodeInspectorComponent implements OnChanges {
  @Input() isEditable?: boolean = true;
  @Input() workflow?: WorkflowClass;
  @Input() selectedNode?: NodeClass;
  @Input() selectedGoalNode?: NodeClass;
  @Output() nodeChange = new EventEmitter<{ id: string; newAttributes: any }>();
  @Output() nodeGoalToSelect = new EventEmitter<any>();
  @Output() zoomOnNode = new EventEmitter<{ x: number; y: number }>();
  @Output() deleteGoalNode = new EventEmitter<any>();

  @ViewChild('nodeForm') nodeForm?: DynamicFormComponent;
  router = inject(ActivatedRoute);
  public formType: any;
  public copies = [];
  public allowedCopies: {} = [];
  public campaigns = [] as CampaignModel[];
  public segments: [] = [];
  public campaignsList: {} = [];
  public segmentsList: {} = [];
  public conditionalParentsNodes: {} = [];
  public loading = false;
  items: MenuItem[] | undefined;
  option = 'minimumAbsoluteAmount';

  copiesMessage: {
    header: string;
    text: string;
    asunto: string;
    buttonText: string;
    typeChanel: string;
    preHeader: string;
  }[] = [];

  nameGoalNode: NodeType | string = '';

  private selectedNodeSubject = new Subject<NodeClass>();

  constructor(
    public nodeFormService: NodeFormService,
    private toast: ToastService,
    private translate: TranslateService,
    private copyService: GetCopyService,
    private segmentService: SegmentationService,
  ) {
    this.getData();
    /*this.items = [
      {
        icon: 'pi pi-plus',
        command: () => {
          if (this.selectedNode?.parentNodes && this.selectedNode?.parentNodes.length > 0) {
            this.nodeGoalToSelect.emit();
          } else {
            this.toast.showInfo(
              'Nodo no conectado',
              'El Nodo debe estar conectado para encontrar su nodo objetivo',
            );
          }
        },
      },
      {
        icon: 'pi pi-search',
        command: () => {
          const target = (this.selectedNode?.config as ConditionalConfig).targetNodeId!;
          if (target !== null) {
            const positionTargetNode = this.workflow?.findNode(target)?.position;
            this.zoomOnNode.emit(positionTargetNode);
          } else {
            this.toast.showInfo(
              'No hay nodo objetivo',
              'Debe haber un nodo objetivo para hacer zoom',
            );
          }
        },
      },
      {
        icon: 'pi pi-trash',
        command: () => {
          (this.selectedNode?.config as ConditionalConfig).targetNodeId = null;
          this.nameGoalNode = '';
          this.deleteGoalNode.emit();
        },
      },
    ];
*/
    this.selectedNodeSubject.subscribe((node) => {
      this.getGoalNodeConditional(node);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedGoalNode']) {
      this.selectedNodeSubject.next(this.selectedNode!);
    }

    if (changes['selectedNode'] && this.selectedNode) {
      this.handleInfoNewNode();

      this.selectedNodeSubject.next(changes['selectedNode'].currentValue);

      let waitDays = this.selectedNode.config['waitDays'];
      if (
        this.selectedNode.type == NodeType.WaitDaysForDueDate &&
        Number(this.selectedNode.config['waitDays']) < 0
      ) {
        this.selectedNode.config['waitDays'] = -1 * this.selectedNode.config['waitDays'];
      }

      this.formType = this.nodeFormService.generateFormControls(
        this.selectedNode?.config!,
        this.allowedCopies,
        this.campaignsList,
        this.conditionalParentsNodes,
        this.segmentsList,
      );

      if (this.selectedNode.type == NodeType.WaitDaysForDueDate && waitDays && waitDays < 0) {
        this.formType[2].value = 'beforeDueDate';
      }
    }
  }

  handleInfoNewNode(): void {
    this.handleCopiesList({
      type: this.selectedNode?.type!,
      copyIds: this.copies,
    });
    this.handleCampaignsList();
    this.handleParentsConditionalNode();
    this.handleSegmentsList();
  }

  getData() {
    this.router.data.subscribe(
      (data) => {
        this.copies = data['copiesListResolver'];
        this.campaigns = data['campaignListResolver'];
        this.segments = data['segments'];
      },
      (error) => {
        this.toast.showError('Error getting data', error.message);
      },
    );

    if (!this.segments) {
      this.getSegments();
    }
  }

  getSegments() {
    this.segmentService.getSegments().subscribe((segments) => {
      this.segments = segments;
    });
  }

  addGoalNode() {
    if (this.selectedNode?.parentNodes && this.selectedNode?.parentNodes.length > 0) {
      this.nodeGoalToSelect.emit();
    } else {
      this.toast.showInfo(
        'Nodo no conectado',
        'El Nodo debe estar conectado para encontrar su nodo objetivo',
      );
    }
  }

  updateNodeAttributes(newAttributes: any) {
    try {
      if (this.selectedNode) {
        const currentCopyIds = this.selectedNode.config?.['copyIds'] || [];
        const newCopyIds = newAttributes?.copyIds || [];
        const copyIdsChanged = this.haveCopyIdsChanged(currentCopyIds, newCopyIds);

        ParseInfoFormToClassService.ParseInfoFormToClass(
          newAttributes,
          this.workflow!,
          this.selectedNode,
        );

        if (copyIdsChanged) {
          this.getPlantillas();
        }
      }
    } catch (error) {
      this.toast.showError('Error mapping info node', (error as Error).message);
    }
  }

  haveCopyIdsChanged(currentCopyIds: string[], newCopyIds: string[]): boolean {
    if (currentCopyIds.length !== newCopyIds.length) {
      return true;
    }
    return currentCopyIds.some((id, index) => id !== newCopyIds[index]);
  }

  handleCopiesList(values: { type: string; copyIds: string[] }) {
    this.allowedCopies = this.copies
      .filter((copy: any) => copy.type === mapFilterCopies(values.type))
      .map((copy: any) => ({
        label: copy.name,
        value: copy.id,
      }));

    this.getPlantillas();
  }

  getPlantillas(): void {
    if (this.allowedCopies && this.selectedNode) {
      const config = this.selectedNode.config;
      if (hascopyIds(config) && config.copyIds !== null) {
        this.getCopyMessage(config.copyIds, this.selectedNode.type);
      } else {
        this.copiesMessage = [];
      }
    }
  }

  handleCampaignsList() {
    this.campaignsList = this.campaigns
      .filter((c) => c.status === campaignStatus.PUBLISHED || c.status === campaignStatus.PAUSED)
      .map((campaign: any) => ({
        label: campaign.name,
        value: campaign.id,
      }));
  }

  handleSegmentsList() {
    if (this.segments && this.segments.length > 0) {
      this.segmentsList = this.segments.map((campaign: any) => ({
        label: campaign.name,
        value: campaign.id,
      }));
    }
  }

  handleParentsConditionalNode() {
    if (!this.selectedNode || this.selectedNode.type !== NodeType.Conditional) {
      return;
    }
    const ancestors = this.workflow?.getParentNodes(this.selectedNode) || [];
    if (ancestors.length > 0) {
      this.conditionalParentsNodes = ancestors
        .filter((node: any) => {
          return (
            node.type === NodeType.SendCertifiedEmail ||
            node.type === NodeType.SendCertifiedSMS ||
            node.type === NodeType.SendSMS ||
            node.type === NodeType.SendEmail ||
            node.type === NodeType.SuperSendCertifiedSMS ||
            node.type === NodeType.SuperSendCertifiedEmail
          );
        })
        .map((node: any, index: number) => {
          return {
            label: `${index + 1} - ${this.translate.instant(
              'DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.PARAMETERS.NAME.' + node.type,
            )}`,
            value: node.id,
          };
        });
    }
  }

  truncateText(text: string): string {
    const maxLength = 30;
    if (text.length > maxLength) {
      return text.substr(0, maxLength - 3) + '...';
    } else {
      return text;
    }
  }

  copyText(text: string) {
    navigator.clipboard.writeText(text).then(() => {
      this.toast.showInfo('Copiado', 'Mensaje copiado en el portapapeles');
    });
  }

  getCopyMessage(ids: string[], type: string) {
    this.loading = true;
    this.copiesMessage = [];

    if (ids && ids.length > 0) {
      ids.forEach((id, index) => {
        this.copyService
          .getCopy(id)
          .pipe(
            finalize(() => {
              if (index === ids.length - 1) {
                this.loading = false;
              }
            }),
            catchError((error) => {
              this.toast.showError(
                'Error',
                `Fallo al cargar plantilla de mensaje: ${error.message}`,
              );
              return of(null);
            }),
          )
          .subscribe((data: any) => {
            if (data && data.errors) {
              this.toast.showError('Error', `${data.errors[0].message}`);
              return;
            }
            let text: string = '';
            let header: string = '';
            let asunto: string = '';
            let buttonText: string = '';
            let typeChanel: string = '';
            let preHeader: string = '';

            if (type.includes('SMS') || type.includes('sms')) {
              text = data.data.copy.blocks[0].translations[0].text || '';
              asunto = data.data.copy.name;
              typeChanel = 'SMS';
            } else {
              asunto = data.data.copy.blocks[0].translations[0].text || '';
              text = data.data.copy.blocks[2].translations[0].text || '';
              preHeader = data.data.copy.blocks[1].translations[0].text || '';
              buttonText = data.data.copy.blocks[3].translations[0].text || '';
              typeChanel = 'EMAIL';
            }
            header = data.data.copy.name || '';

            const existingIndex = this.copiesMessage.findIndex((entry) => entry.header === header);
            if (existingIndex !== -1) {
              this.copiesMessage.splice(existingIndex, 1);
            }
            this.copiesMessage.push({ header, text, asunto, buttonText, typeChanel, preHeader });
          });
      });
    }
  }

  getGoalNodeConditional(node: NodeClass) {
    if (node && (node?.config as ConditionalConfig).targetNodeId) {
      const nodeFinded = this.workflow?.findNode((node?.config as ConditionalConfig).targetNodeId!);

      this.nameGoalNode = nodeFinded ? nodeFinded.type : 'No se ha encontrado nodo';
    } else if (
      node &&
      ((node?.config as ConditionalConfig).targetNodeId === null ||
        (node?.config as ConditionalConfig).targetNodeId === undefined)
    ) {
      this.nameGoalNode = '';
    }
  }

  protected readonly NodeType = NodeType;
  protected readonly hascopyIds = hascopyIds;
}
