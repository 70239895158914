import { gql } from 'apollo-angular';

const sharedFields = `
  id: String!
  name: String!
  type: String!
  status: String!
  collectors: [string]!
`;

const typeDefs = gql`
  type NotificationChannel {
    ${sharedFields}
  }

  type NotificationChannelSms {
    ${sharedFields}
    senderDisplayName: String!
  }

  type NotificationChannelEmail {
    ${sharedFields}
    senderDisplayName: String!
    senderEmailAddress: String!
    replyToEmailAddresses: [String!]
    sendgrid_template: String!
  }

  extend type Query {
    notificationChannel: NotificationChannel
    notificationChannels: [NotificationChannel]

    createNotificationChannelSmsInput: NotificationChannelSms
    updateNotificationChannelSms: NotificationChannelSms
    deleteNotificationChannelSms: NotificationChannelSms
    
    createNotificationChannelEmailInput: NotificationChannelEmail
    updateNotificationChannelEmail: NotificationChannelEmail
    deleteNotificationChannelEmail: NotificationChannelEmail
  }
`;

const resolvers = {
  EmailChannelConfig: {
    collectors: () => {
      return [
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGN', name: 'Fotocasa' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Coches.net' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGN', name: 'Fotocasa' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Test' },
        { id: '01F8DB3ZVCN2FBTVC5Q25PCSGE', name: 'Test 2' },
      ];
    },
  },
  Query: {
    notificationChannel: () => ({
      id: 1,
      name: 'NC1',
      type: 'email',
      status: '1',
      senderDisplayName: 'Jonathan',
      senderEmailAddress: 'jonathan.alcazar@bekodo.com',
      replyToEmailAddresses: ['test1@test.com, test2@test.com'],
      sendgrid_template: 'H6R0N4C5NDG3VGF',
    }),
    notificationChannels: () => [
      {
        id: 1,
        name: 'NC1',
        type: 'email',
        status: '1',
        senderDisplayName: 'Jonathan',
      },
      {
        id: 2,
        name: 'NC2',
        type: 'sms',
        status: '1',
        senderDisplayName: 'Jonathan',
        senderEmailAddress: 'jonathan.alcazar@bekodo.com',
        replyToEmailAddresses: ['test1@test.com, test2@test.com'],
        sendgrid_template: 'H6R0N4C5NDG3VGF',
      },
    ],
  },
};

export default { typeDefs, resolvers };
