import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

export function maxSafeIntegerValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const maxSafeInteger = Math.pow(2, 31) - 1;
    if (value && value > maxSafeInteger) {
      return { maxSafeInteger: true };
    }
    return null;
  };
}
